import { ReactNode } from 'react';

import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { GOOGLE_MAPS_API_KEY, PlaceType } from '@scorenco/core';
import usePlacesAutocomplete from 'use-places-autocomplete';

import { GoogleMapsInputOption } from './GoogleMapsInputOption';

type GoogleMapsInputProps = Omit<
  AutocompleteProps<PlaceType, false, false, true>,
  'options' | 'renderInput'
> & {
  label?: string;
  error?: boolean;
  helperText?: ReactNode;
  value?: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onValidate?: (value: string | PlaceType | null) => void;
};

const load = () => {
  if (!document.getElementById('google-maps-api')) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY.get()}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    script.id = 'google-maps-api';
    document.head.appendChild(script);
  }
};

export const GoogleMapsInput = ({
  label,
  error,
  helperText,
  value: defaultValue,
  onChange,
  autoFocus,
  onValidate = () => {},
  sx,
  ...rest
}: GoogleMapsInputProps) => {
  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    defaultValue,
    requestOptions: {
      componentRestrictions: {
        country: process.env.NEXT_PUBLIC_GOOGLE_COUNTRY_RESTRICTION || null,
      },
    },
    cacheKey: 'region-restricted',
    callbackName: 'initMap',
  });

  return (
    <Autocomplete
      options={data}
      value={value}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      onChange={(event, newValue) => {
        setValue(
          newValue
            ? typeof newValue === 'string'
              ? newValue
              : newValue.description
            : ''
        );
        onValidate(newValue);
      }}
      filterOptions={(options) => options}
      onFocus={() => {
        load();
      }}
      onInputChange={(event, newInputValue) => {
        if (value !== newInputValue) {
          setValue(newInputValue);
          onChange(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          autoFocus={autoFocus}
        />
      )}
      renderOption={(props, option) => (
        <GoogleMapsInputOption option={option} {...props} />
      )}
      sx={{ width: '100%', ...sx }}
      {...rest}
    />
  );
};
