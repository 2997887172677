import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import { Types } from '../../../../types';
import {
  UpdateGroupDocument,
  UpdateGroupMutationVariables,
} from '../../Community.mutation.generated';

export const useGroupUpdate = () => {
  const [runMutation, status] = useMutation(UpdateGroupDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: UpdateGroupMutationVariables) => {
      return runMutation({
        variables,
        update: (cache, res) => {
          const members_group = cache.readFragment<Types.Members_Group>({
            id: `members_group:${variables.id}`,
            fragment: gql`
              fragment GetGroups on members_group {
                id
                name
                emoji
                color
              }
            `,
          });

          cache.writeFragment({
            id: `members_group:${variables.id}`,
            fragment: gql`
              fragment GetGroups on members_group {
                id
                name
                emoji
                color
              }
            `,
            data: {
              ...members_group,
              ...res?.data?.update_members_group_by_pk,
            },
          });
        },
      });
    },
    [runMutation]
  );

  return { execute, status };
};
