import { css } from '@emotion/react';
import { DragIndicator } from '@mui/icons-material';
import {
  Skeleton,
  ListItemText,
  Tooltip,
  Box,
  Typography,
  ListItemButton,
  Chip,
} from '@mui/material';
import { Types, useReorderTeamsMutation, useTranslation } from '@scorenco/core';
import { DragControls } from 'framer-motion';
import NextLink from 'next/link';

import { useCurrentClubContext } from '@/layouts';
import { T_KEYS } from '@/translations';

type TeamCardProps = {
  team?: Types.Teams_Team_Detail;
  items?: Types.Teams_Team_Detail[];
  dragControls?: DragControls;
  member?: Types.Teams_Team_In_Season_Has_Member;
};
export const TeamCard = ({
  team,
  dragControls,
  items,
  member,
  ...rest
}: TeamCardProps) => {
  const { currentClub } = useCurrentClubContext();
  const { t } = useTranslation();
  const reorderTeamsMutation = useReorderTeamsMutation();

  return (
    <NextLink legacyBehavior href={`/${currentClub.id}/teams/${team?.id}/`}>
      <ListItemButton divider {...rest}>
        <ListItemText>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'initial',
                  textTransform: 'initial',
                  fontWeight: 'bold',
                }}
                variant="body1"
              >
                {team?.name_in_club || <Skeleton />}
                {team?.is_group_of_teams && (
                  <Tooltip title="Équipe en entente">
                    <Box
                      display="inline"
                      sx={{
                        ml: 1,
                        color: 'white',
                        padding: ' 0.25rem 0.5rem',
                        borderRadius: '4px',
                        fontSize: '0.8rem',
                        backgroundColor: (theme) =>
                          theme.vars.palette.primary.main,
                      }}
                    >
                      {team?.club?.[0]?.name}
                    </Box>
                  </Tooltip>
                )}
                {!!team?.team?.nb_fans && (
                  <Box
                    display="inline"
                    component="span"
                    sx={{
                      color: 'secondary.main',
                      ml: 1,
                      fontSize: '0.8rem',
                    }}
                  >
                    {t(T_KEYS.TEAMS_TEAM_FAN, {
                      count: team.team.nb_fans,
                    })}
                  </Box>
                )}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'initial',
                  textTransform: 'initial',
                  fontSize: '0.8rem',
                }}
              >
                {team?.team_in_season?.last_competition_with_ranking?.[0]
                  ?.name || <Skeleton />}
              </Typography>
            </Box>
            {(member?.is_staff || member?.role) && (
              <Box
                css={(theme) => css`
                  font-size: 0.7rem;
                  padding: 0.25rem 0.5rem;
                  border-radius: 999px;
                  border: 1px solid;
                  border-color: ${theme.vars.palette.primary.main};
                  display: inline-block;
                  width: fit-content;
                  margin-left: 1rem;
                  height: fit-content;
                `}
              >
                {member?.is_staff && (
                  <Chip
                    label="Staff"
                    size="small"
                    color="primary"
                    sx={{ mr: 1 }}
                  />
                )}
                {member.role}
              </Box>
            )}
            {!!member?.is_burned && (
              <Box
                css={(theme) => css`
                  font-size: 0.7rem;
                  padding: 0.25rem 0.5rem;
                  border-radius: 999px;
                  border: 1px solid;
                  border-color: ${theme.vars.palette.primary.main};
                  display: inline-block;
                  width: fit-content;
                  margin-left: 1rem;
                  height: fit-content;
                `}
              >
                {t(T_KEYS.TEAMS_PLAYER_IS_BURNED)} 🔥
              </Box>
            )}
          </Box>
        </ListItemText>
        {dragControls && (
          <Box
            onPointerDown={(event) => dragControls.start(event)}
            onClick={(event) => {
              event.preventDefault();
            }}
            onPointerUp={async () =>
              await reorderTeamsMutation.execute({
                club_id: currentClub.id,
                team_ids: items?.map((item) => item.team_id) || [],
              })
            }
            sx={{ padding: '0 2rem', cursor: 'grab' }}
          >
            <DragIndicator />
          </Box>
        )}
      </ListItemButton>
    </NextLink>
  );
};
