import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Verified, ArrowForwardIos } from '@mui/icons-material';
import { Typography, Button, Box, Paper } from '@mui/material';
import {
  ClubLogo,
  Image,
  SlideshowInfinite,
  useItems,
} from '@scorenco/components';
import { CrispService, useTranslation } from '@scorenco/core';
import { useCurrentClubContext } from 'layouts';
import { T_KEYS } from 'translations';

import { GetClubsTournamentDocument } from './_internal/GetClubs.query.generated';

export const PremiumFeatureTournaments = () => {
  const { t } = useTranslation();

  const { currentClub } = useCurrentClubContext();

  const openCrisp = () => {
    CrispService.show();
    CrispService.sendMessageToUser(
      `Bonjour ${currentClub?.name}, pour quel club souhaitez-vous demander un devis module Tournoi ? Pouvez-vous également nous indiquer le nombre de tournois concernés ainsi que leurs noms ? 😊`
    );

    CrispService.sendMessageToUser(
      `Bonjour ${currentClub?.name}, comment pouvons-nous vous renseigner sur le module Tournoi ? 
      À votre demande, nous pouvons vous envoyer un devis. 😊`
    );
  };

  const getClubsTournament = useQuery(GetClubsTournamentDocument, {
    context: {
      role: 'club',
    },
  });

  const clubsTournament = useItems(getClubsTournament.data?.clubs_club, 20);

  return (
    <>
      <Paper variant="bordered">
        <Verified sx={{ color: 'premium.main', fontSize: '2.5rem', mb: 1 }} />
        <Typography
          variant="h4"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.TOURNAMENTS_PREMIUM_TITLE),
          }}
        />

        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.TOURNAMENTS_PREMIUM_TEXT),
          }}
        />

        <Button
          onClick={openCrisp}
          sx={{ mt: '1rem' }}
          variant="contained"
          color="premium"
          endIcon={<ArrowForwardIos />}
        >
          {t(T_KEYS.MORE_INFORMATIONS)}
        </Button>
      </Paper>

      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
        >
          En lien avec notre stratégie de devenir un outil majeur dans la
          communication des clubs de sport amateur, nous avons développé un
          module tournoi automatisé pour gagner du temps et faciliter leur(s)
          organisation(s).
          <br />
          Depuis toujours notre volonté est simple : offrir à tous les clubs,
          pour tous les niveaux des outils de communication et d’information
          simples et automatisés.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box>
            <Image
              sx={{
                width: '30rem',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/tournaments/planning-matchs.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Intuitif et simple d’administration
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Image
              sx={{
                width: '30rem',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/tournaments/organisation-tournoi.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Sur le site internet, les résultats, les classements, et les
              calendriers se mettent à jour automatiquement en direct
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          Pour faire de votre tournoi un{' '}
          <span className="highlight">grand</span> tournoi !
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContext: 'space-between',
            }}
          >
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                padding: '2rem',
              }}
              src={`/images/tournaments/widget-tournoi.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Afficher automatiquement et en temps réel l'actualité du tournoi
              sur votre site internet
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContext: 'space-between',
              height: '100%',
            }}
          >
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/tournaments/clubhouse-tournoi.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem', mt: 3 }}
            >
              Système Clubhouse dédié au tournoi
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper variant="bordered" sx={{ marginTop: '2rem', overflow: 'hidden' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          Ces clubs qui utilisent déjà l'outil
        </Typography>

        <Box
          sx={{
            width: '100%',
            height: '60px',
            position: 'relative',
          }}
        >
          <SlideshowInfinite
            length={clubsTournament.length}
            elementRemSize={4}
            style={{ width: '100%', position: 'absolute', left: 0 }}
          >
            {clubsTournament.map((club) => (
              <ClubLogo
                key={club?.id}
                logo={club?.logo}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 3rem;
                  height: 3rem;
                  margin: 0 0.5rem;
                `}
              />
            ))}
          </SlideshowInfinite>
        </Box>
      </Paper>
    </>
  );
};
