import { css } from '@emotion/react';
import { Button, Box } from '@mui/material';
import { ClubLogo } from '@scorenco/components';
import {
  useTranslation,
  useAmplifyServiceContext,
  RouteLink,
} from '@scorenco/core';

import { RouteKey } from '@/routes';
import { T_KEYS } from '@/translations';

import { useCurrentClubContext } from '../CurrentClubContext';

export const ClubSelector = () => {
  const { currentClub } = useCurrentClubContext();
  const { user } = useAmplifyServiceContext();

  const { t } = useTranslation();

  return (
    <Box
      css={css`
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
      `}
    >
      <span
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <ClubLogo
          css={css`
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
          `}
          logo={currentClub?.logo}
        />
        <span>{currentClub?.name}</span>
      </span>

      {user?.info?.is_superuser || user?.info?.club_admin?.clubs?.length > 1 ? (
        <RouteLink href={{ routeKey: RouteKey.Home }}>
          <Button
            component="a"
            css={css`
              margin-top: 1rem;
            `}
            variant="outlined"
            size="small"
          >
            {t(T_KEYS.MENU_CHANGE_CLUB)}
          </Button>
        </RouteLink>
      ) : null}
    </Box>
  );
};
