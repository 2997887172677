import { useEffect } from 'react';

import { css } from '@emotion/react';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import { Algolia, ClubLogo, useAlgoliaContext } from '@scorenco/components';
import { useTranslation } from '@scorenco/core';
import { connectAutoComplete } from 'react-instantsearch-dom';

import { T_KEYS } from '@/translations';

type Club_SearchClubsInput = {
  id: number;
  name: string;
  sport: string;
  logo: string;
};

type SearchClubsInputProps = {
  value?: Club_SearchClubsInput;
  onClubsChange?: (value: any) => void;
  label: string;
  filters?: any;
};

type ClubsAutocompleteProps = SearchClubsInputProps & {
  hits: any[];
  currentRefinement: any;
  refine: (arg0: any) => void;
  disabled: boolean;
};

const ClubsAutocomplete = ({
  hits,
  currentRefinement,
  refine,
  onClubsChange,
  value,
  label,
  disabled,
  filters,
}: ClubsAutocompleteProps) => {
  const { t } = useTranslation();
  const { setFilters } = useAlgoliaContext();

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  useEffect(() => {
    refine(value?.name);
  }, []);

  return (
    <Autocomplete
      fullWidth
      options={hits || []}
      disabled={disabled}
      filterOptions={(options) => options}
      noOptionsText={t(T_KEYS.NO_OPTIONS_TEXT)}
      loadingText={t(T_KEYS.LOADING_TEXT)}
      isOptionEqualToValue={(option, value) => {
        return Number(option.objectID) === value.id;
      }}
      getOptionLabel={(option) => option.name || currentRefinement}
      value={value}
      onChange={(event, newValue) => {
        onClubsChange({
          id: Number(newValue?.objectID),
          name: newValue?.name,
          sport: newValue?.sport_name,
          logo: newValue?.logo,
          has_admins: newValue?.has_admins ? newValue?.has_admins : false,
        });
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} style={{ width: '100%' }}>
            <ClubLogo
              logo={option.logo}
              css={css`
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
              `}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{ width: '100%' }}
                variant="body2"
                component="span"
              >
                {option.name}
              </Typography>
              <Typography
                sx={{
                  width: '100%',
                  fontSize: '0.6rem',
                  fontStyle: 'italic',
                }}
                variant="body2"
                component="span"
              >
                {option.sport_name}
              </Typography>
            </Box>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          label={label}
          value={currentRefinement}
          onChange={(event) => {
            refine(event.currentTarget.value);
          }}
          {...params}
        />
      )}
    />
  );
};

const CustomAutoComplete = connectAutoComplete(ClubsAutocomplete);

export const SearchClubsInput = ({
  onClubsChange,
  value,
  label,
  filters,
}: SearchClubsInputProps) => {
  return (
    <Algolia indexName="Clubs_Club" useCountry={true} hitsPerPage={10}>
      <CustomAutoComplete
        label={label}
        value={value}
        onClubsChange={onClubsChange}
        filters={filters}
      />
    </Algolia>
  );
};
