import { Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import {
  Algolia,
  AlgoliaSearchBar,
  ClubLogo,
  Item,
  ItemContent,
  ItemDesc,
  ItemImage,
  ItemTitle,
} from '@scorenco/components';
import { RouteLink, useTranslation } from '@scorenco/core';
import { connectInfiniteHits } from 'react-instantsearch-dom';

import { RouteKey } from '../../../routes';
import { T_KEYS } from '../../../translations';

const InfiniteHits = ({ hits }) => {
  return (
    <Stack spacing={1}>
      {hits.map((hit) => (
        <RouteLink
          key={hit.objectID}
          href={{
            routeKey: RouteKey.Dashboard,
            params: { clubId: hit.objectID },
          }}
        >
          <Paper
            component="a"
            variant="bordered"
            sx={{ textAlign: 'left', textDecoration: 'none' }}
          >
            <Item>
              <ItemImage>
                <ClubLogo logo={hit.logo} />
              </ItemImage>

              <ItemContent>
                <ItemTitle>{hit.name}</ItemTitle>
                <ItemDesc>{hit.sport_name}</ItemDesc>
              </ItemContent>
            </Item>
          </Paper>
        </RouteLink>
      ))}
    </Stack>
  );
};

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

export const SearchClubs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        sx={{ flex: 1, my: 4, textTransform: 'capitalize' }}
      >
        {t(T_KEYS.SEARCH)}
      </Typography>

      <Algolia indexName="Clubs_Club" useCountry={true}>
        <AlgoliaSearchBar sx={{ mb: 2 }} />
        <CustomInfiniteHits />
      </Algolia>
    </>
  );
};
