import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { ModalLink } from '@scorenco/components';

import { DeleteModal, DeleteModalProps } from './DeleteModal';

type DeleteModalButtonProps = IconButtonProps &
  Omit<DeleteModalProps, 'closeModal'>;

export const DeleteModalButton = ({
  onValidate,
  message,
  children,
  loading,
  explanation,
  disabled = false,
  ...rest
}: DeleteModalButtonProps) => {
  return (
    <ModalLink
      Modal={DeleteModal}
      modalProps={{
        onValidate,
        message,
        explanation,
        loading,
      }}
    >
      {children || (
        <IconButton color="error" disabled={disabled} {...rest}>
          <Close />
        </IconButton>
      )}
    </ModalLink>
  );
};
