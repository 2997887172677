export const eventMessage = (event) => {
  switch (event) {
    case 'requests':
      return "L'email de convocation a bien été envoyé au membre.";
    case 'hardBounces':
      return "L'email n'a pas pu être envoyé car la boîte mail du membre est inexistante.";
    case 'softBounces':
      return "L'email n'a pas pu être envoyé car la boîte mail du membre est pleine ou indisponible.";
    case 'delivered':
      return "Le membre a bien reçu l'email de convocation.";
    case 'spam':
      return 'Le membre a signalé l’email comme spam.';
    case 'opened':
      return "Le membre a ouvert l'email de convocation.";
    case 'clicks':
      return "Le membre a ouvert l'email de convocation.";
    case 'invalid':
      return "L'adresse email du membre n'existe pas.";
    case 'deffered':
      return 'L’email est en attente de réception et pourra être reçu plus tard.';
    case 'blocked':
      return "L'email est arrivé dans les spams.";
    case 'unsubscribed':
      return "Le membre s'est désabonné des emails.";
    case 'loadedByProxy':
      return "Le membre a bien reçu l'email de convocation.";
    case 'error':
      return "L'email n'a pas pu être envoyé, merci de contacter le support.";
    default:
      return "L'email n'a pas pu être envoyé, merci de contacter le support.";
  }
};

export const eventMessageSMS = (event) => {
  switch (event) {
    case 'hardBounces':
      return "Le message n'a pas pu être envoyé car le numéro du membre est inexistante.";
    case 'softBounces':
      return "Le message n'a pas pu être envoyé car le numéro du membre est indisponible.";
    case 'sent':
      return 'Le membre a bien reçu le message de convocation.';
    case 'accepted':
      return 'Le membre a bien reçu le message de convocation.';
    case 'delivered':
      return 'Le membre a bien reçu le message de convocation.';
    case 'replies':
      return 'Le membre a bien reçu le message de convocation.';
    case 'blocked':
      return 'Le message est arrivé dans les spams.';
    case 'unsubscription':
      return "Le membre s'est désabonné des sms.";
    case 'error':
      return "Le message n'a pas pu être envoyé, merci de contacter le support.";
    default:
      return "Le message n'a pas pu être envoyé, merci de contacter le support.";
  }
};
