import { useState } from 'react';

import { css } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Box,
} from '@mui/material';
import { ModalTitle } from '@scorenco/components';
import {
  useUploadImage,
  ImageUsage,
  Types,
  useNewsUpdate,
  useTranslation,
} from '@scorenco/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { T_KEYS } from '@/translations';

export const EditShortNews = ({ news }: { news: Types.News_News }) => {
  const { uploadImage } = useUploadImage(ImageUsage.News);
  const shortnewsUpdateMutation = useNewsUpdate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };

  const [newsImage, setNewsImage] = useState(news?.image || '');

  const handleUploadImage = async () => {
    const image = await uploadImage();
    setNewsImage(image);
  };

  const formik = useFormik({
    initialValues: {
      id: news?.id,
      title: news?.title,
      text: news?.text,
      image: news?.image,
    },
    onSubmit: async (values) => {
      try {
        await shortnewsUpdateMutation.execute({
          id: news.id,
          title: values.title,
          text: values.text,
          image: newsImage,
        });
        enqueueSnackbar(t(T_KEYS.SUCCESS_ADD), {
          variant: 'success',
        });
        setOpen(false);
        formik.resetForm();
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
          variant: 'error',
        });
      }
    },
  });

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={handleClickOpen}
      >
        Voir / modifier la brève
      </Button>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <Box component="form" name="editnews" onSubmit={formik.handleSubmit}>
          <Box sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>
            <ModalTitle
              onClose={() => {
                setOpen(false);
              }}
            >
              Modifier la brève
            </ModalTitle>
            <DialogContent
              style={{
                paddingTop: '1rem',
              }}
            >
              <Stack spacing={2} alignItems={'center'}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  label="Titre de la brève"
                  autoComplete="off"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  slotProps={{
                    htmlInput: { maxLength: 20 },
                  }}
                />
                <TextField
                  fullWidth
                  id="text"
                  name="text"
                  label="Texte de la brève"
                  autoComplete="off"
                  multiline
                  rows={4}
                  value={formik.values.text}
                  onChange={formik.handleChange}
                  slotProps={{
                    htmlInput: { maxLength: 512 },
                  }}
                />
                <Box
                  css={(theme) => css`
                    width: 100%;
                    height: 12rem;
                    background: ${theme.vars.palette.background.default};
                    border-radius: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-image: url(${newsImage});
                    background-position: center center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    box-shadow: inset 2000px 0 0 0 rgb(100 100 100 / 10%);
                    margin: 0 auto;
                  `}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleUploadImage}
                  >
                    {(newsImage && "changer l'image mise en avant") ||
                      "définir l'image mise en avant"}
                  </Button>
                </Box>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 0, p: 1, justifyContent: 'center' }}>
              <Button type="submit" variant="contained" color="secondary">
                Modifier
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
