import { envVars } from '../../helpers';

export const ALGOLIA_APP_ID = envVars(
  'NEXT_PUBLIC_ALGOLIA_APP_ID',
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID
)
  .required()
  .asString();

export const ALGOLIA_SECRET_KEY = envVars(
  'NEXT_PUBLIC_ALGOLIA_SECRET_KEY',
  process.env.NEXT_PUBLIC_ALGOLIA_SECRET_KEY
)
  .required()
  .asString();

export const ALGOLIA_ENVIRONMENT = envVars(
  'NEXT_PUBLIC_ALGOLIA_ENVIRONMENT',
  process.env.NEXT_PUBLIC_ALGOLIA_ENVIRONMENT
)
  .required()
  .asString();

export const ALGOLIA_COUNTRY = envVars(
  'NEXT_PUBLIC_ALGOLIA_COUNTRY',
  process.env.NEXT_PUBLIC_ALGOLIA_COUNTRY
).asString();
