import { css } from '@emotion/react';
import { Button, Box } from '@mui/material';
import { useTranslation } from '@scorenco/core';

import { T_KEYS } from '@/translations';

const style = {
  root: css`
    span.label {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      padding: 0;
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      color: rgba(0, 0, 0, 0.6);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(133% - 24px);
      transform: scale(0.75);
      margin-left: -4px;
    }
    img {
      max-width: 100%;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  `,
};

export const ImageField = ({ label, ...rest }: { label: string }) => {
  const { t } = useTranslation();

  return (
    <Box css={style.root}>
      <span className="label">{label}</span>
      <Box
        sx={{ mb: '20px', display: 'flex', justifyContent: 'center', mt: 1 }}
        {...rest}
      >
        <Box sx={{ maxWidth: '500px', borderRadius: '0.5rem' }}>
          <img
            src="https://www.fidealis.com/wp-content/uploads/2019/09/copyright-filigrane-photo-1024x576.jpg"
            alt=""
            loading="lazy"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Button variant="outlined" color="error" size="small">
              {t(T_KEYS.DELETE)}
            </Button>
            <Button variant="contained" color="secondary" size="small">
              {t(T_KEYS.IMAGEFIELD_NEW_PICTURE)}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
