import { FormEvent } from 'react';

import { css } from '@emotion/react';
import { Close } from '@mui/icons-material';
import { IconButton, DialogTitle, Typography } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

type ModalProps = Omit<BoxProps, 'onSubmit'> & {
  title: string;
  closeModal: () => void;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

export const Modal = ({
  title,
  closeModal,
  children,
  onSubmit,
  ...rest
}: ModalProps) => {
  return (
    <Box
      css={() => css`
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: var(--ion-safe-area-top);

        .MuiDialogContent-root {
          flex: 1;
        }

        .MuiDialogActions-root {
          justify-content: center;
          margin-bottom: 1rem;
        }
      `}
      component={onSubmit ? 'form' : 'div'}
      onSubmit={onSubmit}
      {...rest}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignIteams: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="span"
          css={css`
            display: block;
            text-transform: uppercase;
            line-height: 1;
            text-align: center;
            margin-top: 1rem;
            max-width: 80%;
          `}
        >
          {title}
        </Typography>
        <IconButton
          size="large"
          onClick={closeModal}
          css={css`
            position: absolute;
            top: calc(0 + var(--ion-safe-area-top));
            right: 0;
            margin: 0.5rem;
          `}
        >
          <Close fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <Box
        css={css`
          padding-top: 0.25rem;
        `}
      >
        {children}
      </Box>
    </Box>
  );
};
