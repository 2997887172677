import { Link, LinkProps, Stack, StackProps } from '@mui/material';
import { RouteLink, useRouteInfo } from '@scorenco/core';

export type HorizontalNavigationLinkProps = LinkProps & {
  href?: string;
  withQueryParam?: boolean;
};

export const HorizontalNavigationLink = ({
  children,
  href,
  withQueryParam,
  ...rest
}: HorizontalNavigationLinkProps) => {
  const routeInfo = useRouteInfo();

  return (
    <RouteLink href={href}>
      <Link
        underline="none"
        className={
          (withQueryParam
            ? routeInfo.asPath
            : routeInfo.asPath?.split('?')?.[0]) === href
            ? 'active'
            : ''
        }
        sx={{
          display: 'block',
          padding: 0,
          textTransform: 'uppercase',
          fontWeight: 700,
          borderTop: '3px solid transparent',
          borderBottom: '3px solid transparent',
          ':hover': {
            color: 'secondary.main',
            borderBottomColor: 'secondary.main',
          },
          '&.active': {
            color: 'secondary.main',
            borderBottomColor: 'secondary.main',
          },
        }}
        {...rest}
      >
        {children}
      </Link>
    </RouteLink>
  );
};

export const HorizontalNavigation = ({ children, sx, ...rest }: StackProps) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{ my: 3, flexWrap: 'wrap', ...sx }}
      {...rest}
    >
      {children}
    </Stack>
  );
};
