import { Configure } from 'react-instantsearch-dom';

import { useAlgoliaContext } from './AlgoliaContext';

type PrimitiveValue = string | boolean | number;

export type AlgoliaConfigureProps = {
  hitsPerPage?: number;
};

const or = (propName: string, values: PrimitiveValue[]): string | undefined => {
  if (!values?.length) {
    return undefined;
  }

  return values.map((value) => `${propName}:${value}`).join(' OR ');
};

const toFiltersString = (data: Record<string, PrimitiveValue[]>): string => {
  if (!data) {
    return '';
  }

  return Object.keys(data)
    .map((propName) => or(propName, data[propName]))
    .filter(Boolean)
    .join(' AND ');
};

export const AlgoliaConfigure = ({ hitsPerPage }: AlgoliaConfigureProps) => {
  const { filters } = useAlgoliaContext();

  return (
    <Configure
      filters={toFiltersString(filters)}
      hitsPerPage={hitsPerPage || 10}
      analytics={false}
      distinct
    />
  );
};
