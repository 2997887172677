/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@scorenco/core';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetCurrentClubQueryVariables = Types.Exact<{
  clubId: Types.Scalars['Int']['input'];
}>;

export type GetCurrentClubQuery = {
  __typename: 'query_root';
  clubs_club_by_pk?:
    | {
        __typename: 'clubs_club';
        id: number;
        name?: string | undefined;
        email?: string | undefined;
        logo?: string | undefined;
        color_primary?: string | undefined;
        color_secondary?: string | undefined;
        facebook_page?: string | undefined;
        instagram_account?: string | undefined;
        linkedin_account?: string | undefined;
        presentation?: string | undefined;
        short_name?: string | undefined;
        tiktok_account?: string | undefined;
        twitter_account?: string | undefined;
        website?: string | undefined;
        youtube_account?: string | undefined;
        ticket_office?: string | undefined;
        customer_clubhouse_start?: any | undefined;
        customer_planner_start?: any | undefined;
        customer_premium_start?: any | undefined;
        customer_tournament_start?: any | undefined;
        customer_monsiteclub_start?: any | undefined;
        algolia_secured_api_key?: string | undefined;
        country_id?: number | undefined;
        department_id?: number | undefined;
        clubhouses?:
          | Array<{ __typename: 'clubhouse_club_house'; id: number }>
          | undefined;
        sport?:
          | {
              __typename: 'clubs_sport';
              id: number;
              name?: string | undefined;
              slug?: string | undefined;
            }
          | undefined;
        planner_settings?:
          | {
              __typename: 'clubs_planner_settings';
              id: number;
              send_sms?: boolean | undefined;
              is_empty_licence_invalid?: boolean | undefined;
            }
          | undefined;
        club_customer?:
          | {
              __typename: 'billing_club_customer';
              customer?:
                | {
                    __typename: 'billing_customer';
                    pennylane_id?: string | undefined;
                  }
                | undefined;
            }
          | undefined;
        organizations?:
          | Array<{
              __typename: 'clubs_organization';
              club_ptr_id?: number | undefined;
            }>
          | undefined;
        equipments?:
          | Array<{
              __typename: 'places_equipment';
              id: number;
              place_id?: number | undefined;
            }>
          | undefined;
      }
    | undefined;
};

export const GetCurrentClubDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentClub' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clubs_club_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clubId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color_primary' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color_secondary' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'facebook_page' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instagram_account' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkedin_account' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'presentation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'short_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tiktok_account' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'twitter_account' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'youtube_account' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ticket_office' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer_clubhouse_start' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer_planner_start' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer_premium_start' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer_tournament_start' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer_monsiteclub_start' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'algolia_secured_api_key' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clubhouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'planner_settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'send_sms' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'is_empty_licence_invalid',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'country_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'department_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'club_customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pennylane_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'club_ptr_id' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'equipments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'rank' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'place_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentClubQuery, GetCurrentClubQueryVariables>;
