import { DefaultLayout, DefaultLayoutOptions, GetLayout } from '@scorenco/next';

import { BackofficeLayoutUserMenu } from './BackofficeLayoutUserMenu';

export const BackofficeLayout = {
  ...DefaultLayout,
  buildGetLayout: (
    options?: DefaultLayoutOptions,
    getLayouts?: GetLayout[]
  ) => {
    return DefaultLayout.buildGetLayout(
      {
        ...(options || {}),
        navigation: {
          ...(options?.navigation || {}),
          AppBarActions: BackofficeLayoutUserMenu,
        },
      },
      getLayouts
    );
  },
};
