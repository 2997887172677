import { PropsWithChildren, useEffect, useState } from 'react';

import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  DialogContent,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Paper,
  Skeleton,
  Divider,
  Button,
} from '@mui/material';
import {
  Algolia,
  AlgoliaSearchBar,
  AlgoliaTable,
  useAlgoliaContext,
  Modal,
} from '@scorenco/components';
import {
  Types,
  useInsertMembersToGroup,
  useTranslation,
  algoliaRefreshCache,
} from '@scorenco/core';
import { useFormik } from 'formik';
import NextLink from 'next/link';
import { useSnackbar } from 'notistack';
import type { StateResultsProvided } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';

import { SearchMembersInput } from '@/components';
import { useCurrentClubContext } from '@/layouts';
import { T_KEYS } from '@/translations';

const NoResults = () => {
  const { t } = useTranslation();

  return (
    <Paper
      variant="bordered"
      sx={{
        mt: 2,
      }}
    >
      <Typography variant="h5" component="p" sx={{ mb: 2, fontStyle: 'unset' }}>
        {t(T_KEYS.COMMUNITY_MEMBERS_NO_MEMBER)} <span role="img">😮</span>
      </Typography>
    </Paper>
  );
};

const NoResultsHandler = ({
  searchResults,
  children,
}: PropsWithChildren<StateResultsProvided>) => {
  if (searchResults?.nbHits === 0) {
    return <NoResults />;
  }

  return <>{children}</>;
};

const AlgoliaNoResultsHandler = connectStateResults(NoResultsHandler);

const TableRowHead = () => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell
        sx={{
          width: '5rem',
        }}
      >
        {t(T_KEYS.COMMUNITY_MEMBERS_TABLECELL_NAME)}
      </TableCell>
      <TableCell
        sx={{
          width: 0,
        }}
      >
        {t(T_KEYS.COMMUNITY_MEMBERS_TABLECELL_EMAIL)}
      </TableCell>
    </TableRow>
  );
};

const LinkedTableCell = ({ children, objectID }) => {
  const { currentClub } = useCurrentClubContext();

  return (
    <NextLink
      legacyBehavior
      href={`/${currentClub.id}/community/${objectID}`}
      passHref
    >
      <TableCell>{children}</TableCell>
    </NextLink>
  );
};

const MemberTableRow = ({ hit }) => {
  if (!hit) {
    return (
      <TableRow>
        <TableCell>
          <Skeleton width={150} />
        </TableCell>
        <TableCell>
          <Skeleton width={150} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow hover>
      <LinkedTableCell objectID={hit.objectID}>
        {hit.first_name} {hit.last_name}
      </LinkedTableCell>
      <LinkedTableCell objectID={hit.objectID}>{hit.email}</LinkedTableCell>
    </TableRow>
  );
};

const AddMemberForm = ({ group }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const insertMembersToGroup = useInsertMembersToGroup();

  const formik = useFormik({
    initialValues: {
      members: [],
    },
    onSubmit: async ({ ...values }) => {
      try {
        await insertMembersToGroup.execute({
          objects: values.members.map((member) => ({
            group_id: group?.id,
            member_id: member.id,
          })),
        });
        enqueueSnackbar(t(T_KEYS.SUCCESS_ADD), {
          variant: 'success',
        });
        formik.resetForm();
        algoliaRefreshCache();
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
          variant: 'error',
        });
      }
    },
  });

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SearchMembersInput
          members={formik.values.members}
          onMembersChange={(value) => {
            formik.setFieldValue('members', value);
          }}
          minified={true}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={insertMembersToGroup.status.loading}
        >
          {t(T_KEYS.ADD)}
        </LoadingButton>
      </Stack>
      <Divider sx={{ width: '100%' }} />
    </>
  );
};

const AlgoliaConfigure = ({ group }) => {
  const { setFilters } = useAlgoliaContext();

  useEffect(() => {
    if (group?.id) {
      setFilters({
        'group_list.id': [group?.id],
      });
    }
  }, [group]);

  return null;
};

export const CommunityGroupMembersModal = ({
  closeModal,
  group,
}: {
  closeModal: () => void;
  group: Types.Members_Group;
}) => {
  const { t } = useTranslation();
  const { currentClub } = useCurrentClubContext();

  const [addMemberFormToggle, setAddMemberFormToggle] = useState(false);

  if (!group) {
    return null;
  } else {
    return (
      <Modal
        closeModal={closeModal}
        title={`${t(T_KEYS.EDITCOMMUNITYGROUPMEMBERS_MEMBERS_OF_GROUP)} ${
          group.name
        } ${group.emoji}`}
      >
        <DialogContent>
          <Stack
            spacing={4}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setAddMemberFormToggle(!addMemberFormToggle)}
            >
              {t(T_KEYS.COMMUNITY_ADD_MEMBER)}
              {addMemberFormToggle ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </Button>

            {addMemberFormToggle && <AddMemberForm group={group} />}
            <Algolia
              indexName="Members_Member"
              clearCacheOnMount
              clubSecuredApiKey={currentClub?.algolia_secured_api_key}
            >
              <AlgoliaConfigure group={group} />
              <Stack
                spacing={2}
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AlgoliaSearchBar />
                <AlgoliaNoResultsHandler>
                  <AlgoliaTable
                    TableRowHead={TableRowHead}
                    TableRow={MemberTableRow}
                  />
                </AlgoliaNoResultsHandler>
              </Stack>
            </Algolia>
          </Stack>
        </DialogContent>
      </Modal>
    );
  }
};
