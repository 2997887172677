import { css } from '@emotion/react';
import { Verified, ArrowForwardIos } from '@mui/icons-material';
import { Typography, Button, Box } from '@mui/material';
import { Image } from '@scorenco/components';
import { CrispService } from '@scorenco/core';

export type PremiumFeatureProps = {
  title: string;
  text?: string;
  moreInformationsText?: string;
  image?: string;
};

export const PremiumFeature = ({
  title,
  text,
  moreInformationsText,
  image,
}: PremiumFeatureProps) => {
  const openCrisp = () => {
    CrispService.show();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Verified sx={{ color: 'premium.main', fontSize: '2.5rem', mb: 1 }} />
      <Typography
        variant="h4"
        component="p"
        align="center"
        sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
        css={(theme) => css`
          .highlight {
            background-color: ${theme.vars.palette.secondary.main};
            color: white;
            padding-right: 0.25rem;
            padding-left: 0.25rem;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      {text && (
        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
      {moreInformationsText && (
        <Button
          onClick={openCrisp}
          sx={{ mt: '1rem' }}
          variant="contained"
          color="premium"
          endIcon={<ArrowForwardIos />}
        >
          {moreInformationsText}
        </Button>
      )}
      {image && (
        <Image
          src={image}
          css={css`
            width: 100%;
            max-width: 40rem;
            height: auto;
            object-fit: contain;
            margin-top: 2rem;
            border-radius: 1rem;
          `}
        />
      )}
    </Box>
  );
};
