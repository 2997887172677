import { ElementType, useState, useEffect, PropsWithChildren } from 'react';

import { ExpandLess, ExpandMore, FiberNew } from '@mui/icons-material';
import {
  List,
  LinkProps,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Link,
  Box,
  Typography,
  LinearProgress,
} from '@mui/material';
import {
  RouteLink,
  useAmplifyServiceContext,
  useTranslation,
} from '@scorenco/core';
import { useRouter } from 'next/router';

import { T_KEYS } from '../../../translations';
import { useCurrentClubContext } from '../CurrentClubContext';

const ConvocationProgressBar = () => {
  const { user } = useAmplifyServiceContext();
  const { currentClub } = useCurrentClubContext();
  const progressionDatas = user?.info?.convocation_progress?.find(
    (cp) => cp?.club_id === currentClub?.id
  );
  const progression =
    (((progressionDatas?.has_member ? 1 : 0) +
      (progressionDatas?.has_admin ? 1 : 0) +
      (progressionDatas?.has_role ? 1 : 0) +
      (progressionDatas?.validated_event ? 1 : 0) +
      (progressionDatas?.club_has_send_sms ? 1 : 0)) /
      (progressionDatas?.club_has_sms_credits ? 5 : 4)) *
    100;

  if (progression === 100 || !currentClub?.customer_planner_start) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: -2,
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        gap: 1,
      }}
    >
      <Typography
        variant="caption"
        component="div"
        color="text.secondary"
        fontSize={'0.65rem'}
        ml={3}
      >
        {progression}%
      </Typography>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          value={progression}
          color="secondary"
        />
      </Box>
    </Box>
  );
};

const OptionalLink = ({ href, children, ...rest }: LinkProps) => {
  if (href?.startsWith('/')) {
    return <RouteLink href={href}>{children}</RouteLink>;
  }
  return (
    <Link href={href} {...rest}>
      {children}
    </Link>
  );
};

type NavigationItemProps = LinkProps & {
  path?: string;
  label: string;
  Icon: ElementType;
  isOpen?: boolean;
  isNew?: boolean;
};

export const NavigationItem = ({
  path,
  label,
  Icon,
  isOpen,
  isNew = false,
  ...rest
}: NavigationItemProps) => {
  const router = useRouter();
  const isActive =
    (router.asPath?.split('?')?.[0]?.includes(path) &&
      path !== `/${router.query?.clubId}`) ||
    router.asPath === path;

  const { t } = useTranslation();

  return (
    <OptionalLink
      sx={[
        {
          display: 'block',

          '.MuiListItemIcon-root': {
            color: 'currentColor',
          },
        },
        isActive && {
          backgroundColor: 'secondary.50',
          color: 'secondary.main',

          '.MuiListItemText-primary': {
            fontWeight: 'bold',
          },
        },
      ]}
      underline="none"
      href={path}
      {...rest}
    >
      <ListItemButton>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={label} />
        {isNew && <FiberNew color="secondary" />}

        {!path && (isOpen ? <ExpandLess /> : <ExpandMore />)}
        {label === t(T_KEYS.PLANNER_TITLE) && <ConvocationProgressBar />}
      </ListItemButton>
    </OptionalLink>
  );
};

type CollapseNavigationItemProps = PropsWithChildren<{
  label: string;
  Icon: ElementType;
  refPath: string;
}>;

export const CollapseNavigationItem = ({
  label,
  Icon,
  refPath,
  children,
  path,
  ...rest
}: CollapseNavigationItemProps) => {
  const router = useRouter();
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (router.asPath.includes(refPath)) {
      setOpenSubMenu(true);
    }
  }, []);

  return (
    <>
      <NavigationItem
        label={label}
        Icon={Icon}
        onClick={() => {
          setOpenSubMenu(!openSubMenu);
        }}
        isOpen={openSubMenu}
        path={path}
        {...rest}
      />

      <Collapse in={openSubMenu} timeout="auto">
        <List component="div" dense={true} disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

type SubNavigationItemProps = {
  path?: string;
  label: string;
  Icon?: ElementType;
  isOpen?: boolean;
  isNew?: boolean;
};

export const SubNavigationItem = ({
  path,
  label,
  Icon,
  isNew = false,
  ...rest
}: SubNavigationItemProps) => {
  const router = useRouter();

  const isActive = router.asPath?.split('?')?.[0] === path;

  return (
    <OptionalLink color="inherit" underline="none" href={path} {...rest}>
      <ListItemButton
        sx={[
          {
            display: 'block',
            '.MuiListItemIcon-root': {
              color: 'primary.light',
            },
          },
          isActive && {
            backgroundColor: 'secondary.50',
            color: 'secondary.main',

            '.MuiListItemText-primary': {
              fontWeight: 'bold',
            },

            '.MuiListItemIcon-root': {
              color: 'secondary.main',
            },
          },
        ]}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
        {isNew && <FiberNew color="secondary" />}
      </ListItemButton>
    </OptionalLink>
  );
};
