import { BackofficeLayout } from '../BackofficeLayout';

import { buildGetLayout } from './buildGetLayout';
export { useCurrentClubContext } from './CurrentClubContext';
export { usePermissionsContext } from './PermissionsContext/PermissionsContext';

export const CurrentClubLayout = {
  ...BackofficeLayout,
  buildGetLayout,
};
