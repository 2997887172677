import { gql, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  Box,
  DialogContent,
  TextField,
  FormLabel,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Modal } from '@scorenco/components';
import { useTranslation, Types, useUpdateChallenge } from '@scorenco/core';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { HexColorPicker } from 'react-colorful';

import { T_KEYS } from '@/translations';

type EditChallengeModalProps = {
  challenge: Types.Tournaments_Challenge;
  closeModal: () => void;
};

export const EditChallengeModal = ({
  challenge,
  closeModal,
}: EditChallengeModalProps) => {
  const updateChallengeMutation = useUpdateChallenge();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      id: challenge?.id,
      name: challenge?.name,
      color: challenge?.color,
      tournament_id: Number(router.query.tournamentId),
      gender: challenge?.gender || '',
      age_category: challenge?.age_category || '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateChallengeMutation.execute({
          id: values.id,
          _set: {
            name: values.name,
            color: values.color,
            gender: values.gender,
            age_category: values.age_category,
          },
        });
        enqueueSnackbar(t(T_KEYS.SUCCESS_EDIT), {
          variant: 'success',
        });
        closeModal();
        formik.resetForm();
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
          variant: 'error',
        });
      }
    },
  });

  const ageCategoriesAndGenders = useQuery(
    gql`
      query GetAgeCategoriesAndGenders {
        competitions_age_categories {
          label
          value
        }
        competitions_genders {
          label
          value
        }
      }
    `,
    {
      context: {
        role: 'club',
      },
    }
  );

  const ageCategories =
    ageCategoriesAndGenders?.data?.competitions_age_categories;
  const genders = ageCategoriesAndGenders?.data?.competitions_genders;

  return (
    <Modal title="Éditer le challenge" closeModal={closeModal}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <DialogContent
          style={{
            paddingTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}
        >
          <TextField
            label={t(T_KEYS.TOURNAMENTS_CHALLENGE_NAME_LABEL)}
            value={formik.values.name}
            name="name"
            id="name"
            onChange={formik.handleChange}
            fullWidth
          />
          <Box sx={{ width: '100%' }}>
            <FormLabel>Couleur du challenge</FormLabel>
            <HexColorPicker
              color={formik.values.color}
              onChange={(value) => formik.setFieldValue('color', value)}
              style={{ width: '100%' }}
            />
          </Box>
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <FormControl fullWidth required>
              <InputLabel>
                {t(T_KEYS.COMMUNITY_MEMBERS_LABEL_GENDER)}
              </InputLabel>
              <Select
                id="gender"
                name="gender"
                label={t(T_KEYS.COMMUNITY_MEMBERS_LABEL_GENDER)}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.gender}
              >
                {genders?.map((gender, i) => (
                  <MenuItem key={i} value={gender.value}>
                    {gender.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl required fullWidth>
              <InputLabel>{t(T_KEYS.TEAMS_ADD_STEP2_AGECATEGORY)}</InputLabel>
              <Select
                id="age_category"
                name="age_category"
                label={t(T_KEYS.TEAMS_ADD_STEP2_AGECATEGORY)}
                value={formik.values.age_category}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              >
                {ageCategories?.map((category, i) => (
                  <MenuItem key={i} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ m: 0, p: 1, justifyContent: 'center' }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            loading={updateChallengeMutation.status.loading}
          >
            {t(T_KEYS.EDIT)}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Modal>
  );
};
