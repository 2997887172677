import {
  createContext,
  useRef,
  useContext,
  useMemo,
  useEffect,
  PropsWithChildren,
} from 'react';

import { WidgetClient, Types } from '@scorenco/core';

export type WidgetPreviewContextProps = {
  widget?: Types.Widgets_Widget;
  client: any;
};

const WidgetPreviewContext = createContext<
  WidgetPreviewContextProps | undefined
>(undefined);

export const useWidgetPreviewContext = (): WidgetPreviewContextProps => {
  const context = useContext(WidgetPreviewContext);
  if (!context) {
    throw new Error(
      'useWidgetPreviewContext must be inside a WidgetPreviewProvider'
    );
  }
  return context;
};

export const WidgetPreviewProvider = ({
  children,
  widget,
  widgetKey,
}: PropsWithChildren<{
  widget: Types.Widgets_Widget;
  widgetKey: number;
}>) => {
  const widgetClient = useRef<any>(widgetKey);

  useEffect(() => {
    widgetClient.current = new WidgetClient();
    widgetClient.current.parseDocument();
  }, [widgetKey]);

  useEffect(() => {
    if (widget) {
      widgetClient.current.parseDocument();
    }
  }, [widget]);

  const value = useMemo(() => {
    return {
      widget,
      client: widgetClient.current,
    };
  }, [widget]);

  return (
    <WidgetPreviewContext.Provider value={value}>
      {children}
    </WidgetPreviewContext.Provider>
  );
};
