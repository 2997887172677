import { Edit } from '@mui/icons-material';
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  Stack,
} from '@mui/material';
import { ModalLink } from '@scorenco/components';
import { Types, useGroupDelete, useTranslation } from '@scorenco/core';

import { usePermissionsContext } from '@/layouts';
import { T_KEYS } from '@/translations';

import { CommunityEditGroupModal } from '../../pages/[clubId]/community/_internal/CommunityEditGroupModal';
import { CommunityGroupMembersModal } from '../../pages/[clubId]/community/_internal/CommunityGroupMembersModal';
import { DeleteModalButton } from '../DeleteModal';

const CommunityEditGroupLink = ({ group }) => {
  return (
    <ModalLink Modal={CommunityEditGroupModal} modalProps={{ group }}>
      <IconButton color="primary">
        <Edit color="primary" />
      </IconButton>
    </ModalLink>
  );
};

const CommunityGroupMembersModalLink = ({ group }) => {
  const { t } = useTranslation();
  return (
    <ModalLink Modal={CommunityGroupMembersModal} modalProps={{ group }}>
      <Button color="secondary" size="small">
        {t(T_KEYS.GROUPCARD_SEE_MEMBERS)}
      </Button>
    </ModalLink>
  );
};

type GroupCardProps = {
  group: Types.Members_Group;
  groupEmoji: string;
  groupId: number;
  groupName: string;
};

export const GroupCard = ({
  group,
  groupEmoji,
  groupId,
  groupName,
  ...rest
}: GroupCardProps) => {
  const deleteGroup = useGroupDelete();
  const { t } = useTranslation();
  const { checkPermission } = usePermissionsContext();

  const communityGroupsPermission = checkPermission('community_groups');

  return (
    <Paper variant="bordered" sx={{ minWidth: 'fit-content' }} {...rest}>
      <Stack
        spacing={2}
        sx={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}
      >
        {groupEmoji && (
          <Box
            component="span"
            sx={{
              width: '80px',
              height: '80px',
              fontSize: '80px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {groupEmoji}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            sx={{ lineHeight: 1.1, textAlign: 'center' }}
          >
            {groupName}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: 'gray',
              fontStyle: 'italic',
              fontSize: '0.7rem',
              mt: 1,
            }}
          >
            ID: {groupId}
          </Typography>
        </Box>
        <Box>
          {communityGroupsPermission?.edit && (
            <CommunityEditGroupLink group={group} />
          )}
          {communityGroupsPermission?.delete && (
            <DeleteModalButton
              onValidate={async () => {
                await deleteGroup.execute({ groupId: groupId });
              }}
              message={t(T_KEYS.DELETE_CONFIRMATION, {
                delete_confirmation_message: groupName,
              })}
              loading={deleteGroup.status.loading}
            />
          )}
        </Box>
        <CommunityGroupMembersModalLink group={group} />
      </Stack>
    </Paper>
  );
};
