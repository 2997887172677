import { FC, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { Verified, ArrowForwardIos } from '@mui/icons-material';
import { Typography, Button, Box, Paper } from '@mui/material';
import { ClubLogo, Image, SlideshowInfinite } from '@scorenco/components';
import { CrispService, useTranslation } from '@scorenco/core';
import { useCurrentClubContext } from 'layouts';
import { T_KEYS } from 'translations';

export const PremiumFeatureMonsiteclub: FC = () => {
  const { t } = useTranslation();
  const [clubs, setClubs] = useState<any[]>();
  const { currentClub } = useCurrentClubContext();

  useEffect(() => {
    fetch('https://monsiteclub.scorenco.com/wp-json/wp/v2/club?per_page=100')
      .then((res) => res.json())
      .then(
        (result) => {
          setClubs(result);
        },
        (error) => {
          console.error(error);
        }
      );
  }, []);

  const openCrisp = () => {
    CrispService.show();
    CrispService.sendMessageToUser(
      `Bonjour ${currentClub?.name}, souhaitez-vous un devis MonSiteClub ? Si, oui, pouvez-vous également nous indiquer si vous désirez une ou plusieurs options ? 😊`
    );
  };

  return (
    <>
      <Paper variant="bordered">
        <Verified sx={{ color: 'premium.main', fontSize: '2.5rem', mb: 1 }} />
        <Typography
          variant="h4"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.MONSITECLUB_PREMIUM_TITLE),
          }}
        />

        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.MONSITECLUB_PREMIUM_TEXT),
          }}
        />

        <Button
          onClick={openCrisp}
          sx={{ mt: '1rem' }}
          variant="contained"
          color="premium"
          endIcon={<ArrowForwardIos />}
        >
          {t(T_KEYS.MORE_INFORMATIONS)}
        </Button>
      </Paper>

      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        {/* <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
        >
          En lien avec notre stratégie de devenir un outil majeur dans la
          communication des clubs de sport amateur, nous avons développé un
          module tournoi automatisé pour gagner du temps et faciliter leur(s)
          organisation(s).
          <br />
          Depuis toujours notre volonté est simple : offrir à tous les clubs,
          pour tous les niveaux des outils de communication et d’information
          simples et automatisés.
        </Typography> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
          }}
        >
          <Box>
            <Image
              sx={{
                width: '30rem',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/monsiteclub/rcav.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              100% personnalisable à vos couleurs
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Image
              sx={{
                width: '30rem',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/monsiteclub/widget-clisson.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              L’automatisation en lien avec Score’n’co et les fédérations
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          Partagez plus et mieux la <span className="highlight">passion</span>{' '}
          du sport !
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContext: 'space-between',
            }}
          >
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/monsiteclub/option-site.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Intuitif et simple d’administration
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper variant="bordered" sx={{ marginTop: '2rem', overflow: 'hidden' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          Ces clubs qui utilisent déjà l'outil
        </Typography>

        <Box
          sx={{
            width: '100%',
            height: '60px',
            position: 'relative',
          }}
        >
          <SlideshowInfinite
            length={clubs?.length}
            elementRemSize={4}
            style={{ width: '100%', position: 'absolute', left: 0 }}
          >
            {clubs?.map((club, index) => (
              <ClubLogo
                key={index}
                logo={club?.ACF?.logo}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 3rem;
                  height: 3rem;
                  margin: 0 0.5rem;
                `}
              />
            ))}
          </SlideshowInfinite>
        </Box>
      </Paper>
    </>
  );
};
