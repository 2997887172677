import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Verified, ArrowForwardIos } from '@mui/icons-material';
import { Typography, Button, Box, Paper, Stack } from '@mui/material';
import {
  ClubLogo,
  Image,
  SlideshowInfinite,
  useItems,
} from '@scorenco/components';
import { CrispService, useTranslation } from '@scorenco/core';
import { useCurrentClubContext } from 'layouts';
import { T_KEYS } from 'translations';

import { GetClubsClubhouseDocument } from './_internal/GetClubs.query.generated';

export const PremiumFeatureClubhouse = () => {
  const { t } = useTranslation();
  const { currentClub } = useCurrentClubContext();

  const openCrisp = () => {
    CrispService.show();
    CrispService.sendMessageToUser(
      `Bonjour ${currentClub?.name}, comment pouvons-nous vous renseigner sur le module Clubhouse ?
       Nous pouvons vous proposer une phase de test gratuite et sans engagement pour ce module. 😊`
    );
  };

  const getClubsClubhouse = useQuery(GetClubsClubhouseDocument, {
    context: {
      role: 'club',
    },
  });

  const clubsClubhouse = useItems(getClubsClubhouse.data?.clubs_club, 20);

  return (
    <Stack direction="column">
      <Paper variant="bordered">
        <Verified sx={{ color: 'premium.main', fontSize: '2.5rem', mb: 1 }} />
        <Typography
          variant="h4"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.CLUBHOUSE_PREMIUM_TITLE),
          }}
        />
        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.CLUBHOUSE_PREMIUM_TEXT),
          }}
        />

        <Button
          onClick={openCrisp}
          sx={{ mt: '1rem' }}
          variant="contained"
          color="premium"
          endIcon={<ArrowForwardIos />}
        >
          {t(T_KEYS.PLANNER_BUTTON_DEMO)}
        </Button>
      </Paper>
      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
          marginBottom="1rem"
        >
          Dernière innovation en date de Score’n’co, ce module d’animation de
          votre clubhouse permet de combiner l’automatisation et l’affichage de
          vos données pour les rendre visible, accessible et transmissible à
          tout le club.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box sx={{ textAlign: 'center', width: '30%', padding: '0 1rem' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/clubhouse/gauche.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Des contenus sportifs qui se mettent à jour automatiquement
            </Typography>
          </Box>
          <Box sx={{ width: '40%', padding: '0 1rem' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/clubhouse/centre.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Des écrans personnalisés
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center', width: '30%', padding: '0 1rem' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/clubhouse/droit.webp`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Espace sponsoring et partenaires
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          Autant de <span className="highlight">Clubhouse</span> et autant
          d'écrans que vous voulez
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '30%', padding: '0 1rem' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/clubhouse/parametres-clubhouse.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Simple d'administration
            </Typography>
          </Box>
          <Box sx={{ width: '40%', padding: '0 1rem', textAlign: 'center' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/clubhouse/clubhouse-personnalisation.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              100% personnalisable
            </Typography>
          </Box>
          <Box sx={{ width: '30%', padding: '0 1rem' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/clubhouse/liste-clubhouse.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Créez autant de Clubhouse que vous voulez
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper variant="bordered" sx={{ marginTop: '2rem', overflow: 'hidden' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          Ces clubs qui utilisent déjà l'outil
        </Typography>

        <Box
          sx={{
            width: '100%',
            height: '60px',
            position: 'relative',
          }}
        >
          <SlideshowInfinite
            length={clubsClubhouse.length}
            elementRemSize={4}
            style={{ width: '100%', position: 'absolute', left: 0 }}
          >
            {clubsClubhouse.map((club) => (
              <ClubLogo
                key={club?.id}
                logo={club?.logo}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 3rem;
                  height: 3rem;
                  margin: 0 0.5rem;
                `}
              />
            ))}
          </SlideshowInfinite>
        </Box>
      </Paper>
    </Stack>
  );
};
