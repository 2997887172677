import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  ScrapingSynchronizeEventDocument,
  ScrapingSynchronizeEventMutationVariables,
} from './Resynchronisation.mutation.generated';

export const useScrapingSynchronizeEvent = () => {
  const [runMutation, status] = useMutation(ScrapingSynchronizeEventDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: ScrapingSynchronizeEventMutationVariables) => {
      return runMutation({
        variables,
      });
    },
    [runMutation]
  );

  return { execute, status };
};
