import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { algoliaRefreshCache } from '@scorenco/core';

export type AlgoliaContextProps = {
  filters: Record<string, any[]>;
  setFilters: (value: Record<string, any[]>) => void;
  toggleFilter: (
    filter: string,
    value: any,
    multipleSelection?: boolean
  ) => void;
};

export const AlgoliaContext = createContext<AlgoliaContextProps | undefined>(
  undefined
);

export const useAlgoliaContext = (): AlgoliaContextProps => {
  const context = useContext(AlgoliaContext);
  if (!context) {
    throw new Error('useAlgoliaContext must be inside a Algolia');
  }
  return context;
};

export const useInitializeContext = ({
  clearCacheOnMount = false,
} = {}): AlgoliaContextProps => {
  const [filters, setFilters] = useState<Record<string, any[]>>({});

  const toggleFilter = useCallback(
    (filter: string, value: string, multipleSelection = true) => {
      const options = filters[filter] || [];
      const index = options.indexOf(value);

      if (index >= 0) {
        setFilters({
          ...filters,
          [filter]: multipleSelection ? options.splice(index, 1) : [],
        });
      } else {
        setFilters({
          ...filters,
          [filter]: multipleSelection
            ? [...(filters[filter] || []), value]
            : [value],
        });
      }
    },
    [filters]
  );

  useEffect(() => {
    if (clearCacheOnMount) {
      return algoliaRefreshCache();
    }
    return () => ({});
  }, [clearCacheOnMount]);

  return {
    setFilters,
    filters,
    toggleFilter,
  };
};
