/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MemberUpdateMutationVariables = Types.Exact<{
  memberId: Types.Scalars['Int']['input'];
  set: Types.Members_Member_Set_Input;
}>;

export type MemberUpdateMutation = {
  __typename: 'mutation_root';
  update_members_member_by_pk?:
    | { __typename: 'members_member'; id: number }
    | undefined;
};

export type MemberAddMutationVariables = Types.Exact<{
  object?: Types.InputMaybe<Types.Members_Member_Insert_Input>;
}>;

export type MemberAddMutation = {
  __typename: 'mutation_root';
  insert_members_member_one?:
    | { __typename: 'members_member'; id: number }
    | undefined;
};

export type MemberDeleteMutationVariables = Types.Exact<{
  memberId: Types.Scalars['Int']['input'];
}>;

export type MemberDeleteMutation = {
  __typename: 'mutation_root';
  members_delete_member?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type MembersDeleteMutationVariables = Types.Exact<{
  memberIds:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
}>;

export type MembersDeleteMutation = {
  __typename: 'mutation_root';
  members_delete_members?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type MemberValidateInvitationMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;

export type MemberValidateInvitationMutation = {
  __typename: 'mutation_root';
  update_members_member?:
    | {
        __typename: 'members_member_mutation_response';
        returning?:
          | Array<{
              __typename: 'members_member';
              id: number;
              is_admin?: boolean | undefined;
              is_reporter?: boolean | undefined;
              club?:
                | {
                    __typename: 'clubs_club';
                    id: number;
                    logo?: string | undefined;
                    name?: string | undefined;
                  }
                | undefined;
            }>
          | undefined;
      }
    | undefined;
};

export type GroupDeleteMutationVariables = Types.Exact<{
  groupId: Types.Scalars['Int']['input'];
}>;

export type GroupDeleteMutation = {
  __typename: 'mutation_root';
  delete_members_member_in_group?:
    | {
        __typename: 'members_member_in_group_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
  delete_members_group_by_pk?:
    | { __typename: 'members_group'; id: number }
    | undefined;
};

export type InsertGroupMutationVariables = Types.Exact<{
  clubId: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  emoji: Types.Scalars['String']['input'];
  color: Types.Scalars['String']['input'];
}>;

export type InsertGroupMutation = {
  __typename: 'mutation_root';
  insert_members_group_one?:
    | {
        __typename: 'members_group';
        id: number;
        club_id?: number | undefined;
        name?: string | undefined;
        emoji?: string | undefined;
        color?: string | undefined;
      }
    | undefined;
};

export type UpdateGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emoji?: Types.InputMaybe<Types.Scalars['String']['input']>;
  color?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type UpdateGroupMutation = {
  __typename: 'mutation_root';
  update_members_group_by_pk?:
    | {
        __typename: 'members_group';
        id: number;
        club_id?: number | undefined;
        name?: string | undefined;
        emoji?: string | undefined;
        color?: string | undefined;
      }
    | undefined;
};

export type MemberUpdate2MutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Members_Member_Set_Input;
  groups:
    | Array<Types.Members_Member_In_Group_Insert_Input>
    | Types.Members_Member_In_Group_Insert_Input;
  groupIds?: Types.InputMaybe<
    Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']
  >;
  licenceDetail: Types.Members_Licence_Detail_Insert_Input;
}>;

export type MemberUpdate2Mutation = {
  __typename: 'mutation_root';
  delete_members_member_in_group?:
    | {
        __typename: 'members_member_in_group_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
  insert_members_member_in_group?:
    | {
        __typename: 'members_member_in_group_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
  update_members_member_by_pk?:
    | {
        __typename: 'members_member';
        id: number;
        first_name?: string | undefined;
        groups?:
          | Array<{
              __typename: 'members_member_in_group';
              id: number;
              member_id?: number | undefined;
              group_id?: number | undefined;
              group?:
                | {
                    __typename: 'members_group';
                    id: number;
                    name?: string | undefined;
                  }
                | undefined;
            }>
          | undefined;
      }
    | undefined;
  insert_members_licence_detail_one?:
    | {
        __typename: 'members_licence_detail';
        id: number;
        number?: string | undefined;
        status?: any | undefined;
      }
    | undefined;
};

export type InsertMembersToGroupMutationVariables = Types.Exact<{
  objects:
    | Array<Types.Members_Member_In_Group_Insert_Input>
    | Types.Members_Member_In_Group_Insert_Input;
}>;

export type InsertMembersToGroupMutation = {
  __typename: 'mutation_root';
  insert_members_member_in_group?:
    | {
        __typename: 'members_member_in_group_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export type MemberUpdateTasksMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Members_Member_Set_Input;
  objects:
    | Array<Types.Tasks_Member_Task_Count_Insert_Input>
    | Types.Tasks_Member_Task_Count_Insert_Input;
}>;

export type MemberUpdateTasksMutation = {
  __typename: 'mutation_root';
  update_members_member_by_pk?:
    | {
        __typename: 'members_member';
        id: number;
        first_name?: string | undefined;
      }
    | undefined;
  insert_tasks_member_task_count?:
    | {
        __typename: 'tasks_member_task_count_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export type DeleteGroupMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;

export type DeleteGroupMemberMutation = {
  __typename: 'mutation_root';
  delete_members_member_in_group_by_pk?:
    | { __typename: 'members_member_in_group'; id: number }
    | undefined;
};

export type InsertMembersToRoleMutationVariables = Types.Exact<{
  objects:
    | Array<Types.Permissions_Member_Role_Insert_Input>
    | Types.Permissions_Member_Role_Insert_Input;
}>;

export type InsertMembersToRoleMutation = {
  __typename: 'mutation_root';
  insert_permissions_member_role?:
    | {
        __typename: 'permissions_member_role_mutation_response';
        returning?:
          | Array<{ __typename: 'permissions_member_role'; id: number }>
          | undefined;
      }
    | undefined;
};

export type RemoveMemberToRoleMutationVariables = Types.Exact<{
  roleId: Types.Scalars['Int']['input'];
  memberId: Types.Scalars['Int']['input'];
}>;

export type RemoveMemberToRoleMutation = {
  __typename: 'mutation_root';
  delete_permissions_member_role?:
    | {
        __typename: 'permissions_member_role_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export type MakeMembersExportMutationVariables = Types.Exact<{
  clubId: Types.Scalars['Int']['input'];
}>;

export type MakeMembersExportMutation = {
  __typename: 'mutation_root';
  insert_members_export?:
    | {
        __typename: 'members_export_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export type SelectedOfficialTeamsMutationVariables = Types.Exact<{
  member_id: Types.Scalars['Int']['input'];
  team_ids:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
}>;

export type SelectedOfficialTeamsMutation = {
  __typename: 'mutation_root';
  members_update_official_reporter_teams?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type UpdateMembersMutationVariables = Types.Exact<{
  objects: Array<Types.Members_Member_Updates> | Types.Members_Member_Updates;
}>;

export type UpdateMembersMutation = {
  __typename: 'mutation_root';
  update_members_member_many?:
    | Array<
        | {
            __typename: 'members_member_mutation_response';
            affected_rows?: number | undefined;
          }
        | undefined
      >
    | undefined;
};

export const MemberUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MemberUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'memberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'members_member_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_members_member_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'memberId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MemberUpdateMutation,
  MemberUpdateMutationVariables
>;
export const MemberAddDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MemberAdd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'members_member_insert_input' },
          },
          defaultValue: { kind: 'ObjectValue', fields: [] },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_members_member_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MemberAddMutation, MemberAddMutationVariables>;
export const MemberDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MemberDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'memberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members_delete_member' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'member_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'memberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MemberDeleteMutation,
  MemberDeleteMutationVariables
>;
export const MembersDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MembersDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'memberIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members_delete_members' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'member_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'memberIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MembersDeleteMutation,
  MembersDeleteMutationVariables
>;
export const MemberValidateInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MemberValidateInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_members_member' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'invitation' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'code' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'code' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: { kind: 'ObjectValue', fields: [] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_admin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_reporter' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'club' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MemberValidateInvitationMutation,
  MemberValidateInvitationMutationVariables
>;
export const GroupDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GroupDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_members_member_in_group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'group_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'groupId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_members_group_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupDeleteMutation, GroupDeleteMutationVariables>;
export const InsertGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emoji' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_members_group_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'club_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clubId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emoji' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emoji' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'club_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsertGroupMutation, InsertGroupMutationVariables>;
export const UpdateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emoji' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_members_group_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'emoji' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emoji' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'club_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const MemberUpdate2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MemberUpdate2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'members_member_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groups' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'members_member_in_group_insert_input',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'licenceDetail' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'members_licence_detail_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_members_member_in_group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'id' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'group_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_nin' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'groupIds' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_members_member_in_group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groups' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value:
                          'member_in_group_member_id_group_id_4bd7a055_uniq',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: { kind: 'EnumValue', value: '_updated' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_members_member_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'member_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_members_licence_detail_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'licenceDetail' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'licence_detail_member_id_key',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'number' },
                          { kind: 'EnumValue', value: 'status' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MemberUpdate2Mutation,
  MemberUpdate2MutationVariables
>;
export const InsertMembersToGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertMembersToGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'members_member_in_group_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_members_member_in_group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value:
                          'member_in_group_member_id_group_id_4bd7a055_uniq',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: { kind: 'ListValue', values: [] },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertMembersToGroupMutation,
  InsertMembersToGroupMutationVariables
>;
export const MemberUpdateTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MemberUpdateTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'members_member_set_input' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'tasks_member_task_count_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_members_member_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tasks_member_task_count' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value:
                          'member_task_count_member_id_task_id_79f3288d_uniq',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: { kind: 'EnumValue', value: 'task_count' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MemberUpdateTasksMutation,
  MemberUpdateTasksMutationVariables
>;
export const DeleteGroupMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteGroupMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_members_member_in_group_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGroupMemberMutation,
  DeleteGroupMemberMutationVariables
>;
export const InsertMembersToRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertMembersToRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'permissions_member_role_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_permissions_member_role' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: { kind: 'ListValue', values: [] },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value: 'member_role_member_id_role_id_efbda8fe_uniq',
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertMembersToRoleMutation,
  InsertMembersToRoleMutationVariables
>;
export const RemoveMemberToRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMemberToRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'memberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_permissions_member_role' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'roleId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'memberId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveMemberToRoleMutation,
  RemoveMemberToRoleMutationVariables
>;
export const MakeMembersExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MakeMembersExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_members_export' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'club_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clubId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MakeMembersExportMutation,
  MakeMembersExportMutationVariables
>;
export const SelectedOfficialTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SelectedOfficialTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'member_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'team_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'members_update_official_reporter_teams',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'member_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'member_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'team_ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectedOfficialTeamsMutation,
  SelectedOfficialTeamsMutationVariables
>;
export const UpdateMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'members_member_updates' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_members_member_many' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMembersMutation,
  UpdateMembersMutationVariables
>;
