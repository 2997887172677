import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import {
  Container,
  Typography,
  Paper,
  Skeleton,
  List,
  ListItem,
  Box,
  Button,
} from '@mui/material';
import { ClubLogo, useItems } from '@scorenco/components';
import {
  useTranslation,
  useAmplifyServiceContext,
  RouteLink,
  useUserVerifyInvitationCode,
  useUserDeclineInvitation,
} from '@scorenco/core';
import { NextPageWithLayout } from '@scorenco/next';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import { BackofficeLayout } from '@/layouts';
import { T_KEYS } from '@/translations';

import { RouteKey } from '../routes';

import { GetClubsDocument } from './_internal';
import { ClientClubs } from './_internal/ClientClubs';
import { SearchClubs } from './_internal/SearchClubs';
import { GetInvitationsDocument } from './_internal/UserInvitations.query.generated';

const BackofficeHome: NextPageWithLayout = () => {
  const { t } = useTranslation();
  const { user } = useAmplifyServiceContext();
  const router = useRouter();
  const verifyCodeMutation = useUserVerifyInvitationCode();
  const declineInvitation = useUserDeclineInvitation();
  const { enqueueSnackbar } = useSnackbar();

  const getClubs = useQuery(GetClubsDocument, {
    context: {
      role: 'club',
      forceRole: true,
    },
  });

  const getInvitations = useQuery(GetInvitationsDocument, {
    context: {
      role: 'user',
    },
  });

  const shouldRedirectToClubPage =
    !user?.info?.is_superuser &&
    user?.info?.club_admin?.clubs?.length < 2 &&
    user.info.club_admin.clubs?.[0]?.club_id &&
    getInvitations.data?.users_member_invitation?.length === 0;

  const clubs = useItems(getClubs.data?.clubs_club, 1);

  useEffect(() => {
    if (shouldRedirectToClubPage) {
      router.replace(`/${user.info.club_admin.clubs?.[0]?.club_id}`);
    }
  }, [shouldRedirectToClubPage]);

  return (
    <Container sx={{ mb: 3 }}>
      {!getClubs.loading && !getClubs.data?.clubs_club?.length ? (
        <>
          <Paper variant="bordered" sx={{ mt: 3 }}>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Retrouvez vos invitations ci-dessous. <br />
              Si vous attendez une invitation et qu'elle n'apparaît pas,
              vérifiez votre boîte mail, un message d'invitation s'y cache
              peut-être.
              <br /> Si vous ne l'avez pas reçu, contactez-nous à l'adresse :
              contact@scorenco.com
            </Typography>
          </Paper>
        </>
      ) : (
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
            grid-gap: 1rem;
            margin-top: 3rem;
          `}
        >
          {clubs.map((club, idx) => (
            <RouteLink
              key={idx}
              href={
                club && {
                  routeKey: RouteKey.Dashboard,
                  params: { clubId: club.id },
                }
              }
            >
              <a
                css={(theme) => css`
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid ${theme.vars.palette.divider};
                  background-color: ${theme.vars.palette.background.paper};
                  color: ${theme.vars.palette.text.primary};
                  border-radius: 0.5rem;
                  padding: 1rem;
                  text-decoration: none;
                `}
              >
                <ClubLogo
                  logo={club?.logo}
                  css={css`
                    display: flex;
                    width: 3rem;
                    height: 3rem;
                    margin-bottom: 1rem;
                  `}
                />
                <div
                  css={css`
                    min-width: 3rem;
                    text-align: center;
                  `}
                >
                  {club?.name || <Skeleton />}
                </div>
              </a>
            </RouteLink>
          ))}
        </div>
      )}

      {getInvitations.data?.users_member_invitation?.length > 0 && (
        <Paper variant="bordered" sx={{ mt: 3 }}>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Invitations
          </Typography>
          <List sx={{ width: '100%' }}>
            {getInvitations.data?.users_member_invitation?.map((invitation) => (
              <ListItem
                divider={
                  getInvitations.data?.users_member_invitation?.length >= 2
                }
                key={invitation.id}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  {invitation?.user?.first_name
                    ? invitation?.user?.first_name +
                      ' ' +
                      invitation?.user?.last_name +
                      " t'invite à rejoindre l'espace de gestion du club : "
                    : 'Vous êtes invité.e à gérer le club : '}
                  <strong>{invitation?.member?.club?.name}</strong>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    disabled={
                      declineInvitation?.status.loading ||
                      verifyCodeMutation?.status.loading
                    }
                    onClick={async () => {
                      try {
                        await declineInvitation.execute({
                          invitationId: invitation?.id,
                        });
                        enqueueSnackbar(t(T_KEYS.SUCCESS_DELETE), {
                          variant: 'success',
                        });
                      } catch (error) {
                        console.error('error: ', error);
                        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
                          variant: 'error',
                        });
                      }
                    }}
                  >
                    Décliner
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ ml: 1 }}
                    disabled={
                      declineInvitation?.status.loading ||
                      verifyCodeMutation?.status.loading
                    }
                    onClick={async () => {
                      try {
                        await verifyCodeMutation.execute({
                          invitationId: invitation?.id,
                          code: invitation?.code,
                        });
                        await declineInvitation.execute({
                          invitationId: invitation?.id,
                        });
                        window.location.reload();
                        enqueueSnackbar(t(T_KEYS.SUCCESS_ADD), {
                          variant: 'success',
                        });
                      } catch (error) {
                        console.error('error: ', error);
                        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
                          variant: 'error',
                        });
                      }
                    }}
                  >
                    Accepter
                  </Button>
                </Box>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      {user?.info.is_superuser && (
        <>
          <ClientClubs />
          <SearchClubs />
        </>
      )}
    </Container>
  );
};

BackofficeHome.getLayout = BackofficeLayout.buildGetLayout({
  navigation: {
    noBurgerDrawer: true,
  },
});

export default BackofficeHome;
