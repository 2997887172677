import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  AddTaskDocument,
  AddTaskMutationVariables,
} from './Task.mutation.generated';

export const useTaskAdd = () => {
  const [runMutation, status] = useMutation(AddTaskDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: AddTaskMutationVariables) => {
      return runMutation({
        variables,
        refetchQueries: ['GetTasks'],
      });
    },
    [runMutation]
  );

  return { execute, status };
};
