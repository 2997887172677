import { RestartAlt } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { ModalLink } from '@scorenco/components';
import { useTranslation } from '@scorenco/core';

import { T_KEYS } from '@/translations';

import {
  ResynchronisationModal,
  ResynchronisationModalProps,
} from './ResynchronisationModal';

type ResynchronisationModalButtonProps = IconButtonProps &
  Omit<ResynchronisationModalProps, 'closeModal'>;

export const ResynchronisationModalButton = ({
  eventId,
  message,
  children,
  explanation,
}: ResynchronisationModalButtonProps) => {
  const { t } = useTranslation();

  return (
    <ModalLink
      Modal={ResynchronisationModal}
      modalProps={{
        eventId,
        message,
        explanation,
      }}
    >
      {children || (
        <Tooltip title={t(T_KEYS.RESYNC_TITLE)}>
          <IconButton>
            <RestartAlt sx={{ fontSize: '1rem' }} />
          </IconButton>
        </Tooltip>
      )}
    </ModalLink>
  );
};
