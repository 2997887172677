import { PropsWithChildren } from 'react';

import { Button, Link, Paper, Typography, Box } from '@mui/material';
import ReactDOMServer from 'react-dom/server';

type WidgetListItemProps = PropsWithChildren<{
  href: string;
}>;

export const WidgetListItem = ({
  children,
  href,
  ...rest
}: WidgetListItemProps) => {
  return (
    <Box
      component="li"
      sx={{
        display: 'flex',
        alignItems: 'center',
        '&:before': {
          content: '""',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '0.8rem',
          height: '0.8rem',
          backgroundColor: 'secondary.main',
          ml: '-1rem',
          mr: '10px',
          borderRadius: '999px',
        },
        fontSize: '0.9rem',
      }}
      {...rest}
    >
      <Link underline="none" href={href}>
        {children}
      </Link>
    </Box>
  );
};

type WidgetsCardProps = PropsWithChildren<{
  widgetName: string;
  widgetDescription: string;
  widgetIllustration: string;
}>;

export const WidgetsCard = ({
  widgetName,
  widgetDescription,
  widgetIllustration,
  children,
  ...rest
}: WidgetsCardProps) => {
  const isChildNull = (children) => {
    return !ReactDOMServer.renderToStaticMarkup(children);
  };
  const isNull = isChildNull(children);
  return (
    <Paper
      variant="bordered"
      sx={{
        maxWidth: '250px',
        minWidth: '200px',
        mb: '1rem',
        mt: '1rem',
        p: 0,
        m: 2,
      }}
      {...rest}
    >
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {widgetName}
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          {widgetDescription}
        </Typography>
        {isNull ? (
          <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
            <WidgetListItem href="">
              Vous n’avez pas de widget «{''}
              {widgetName}
              {''}» pour cette équipe.
            </WidgetListItem>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ mt: 2 }}
            >
              Tester le widget
            </Button>
          </Box>
        ) : (
          <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
            {children}
          </Box>
        )}
      </Box>
      <Box
        component="img"
        src={widgetIllustration}
        sx={{
          width: '100%',
          height: 'auto',
          borderRadius: '0 0 0.5rem 0.5rem',
        }}
      ></Box>
    </Paper>
  );
};
