import { Translations } from '@scorenco/components';

export const T_KEYS = {
  ...Translations.T_KEYS,

  // COMMON

  SIGN_OUT: 'common:SIGN_OUT',
  ADD: 'common:ADD',
  CREATE: 'common:CREATE',
  VALIDATE: 'common:VALIDATE',
  EDIT: 'common:EDIT',
  FIX: 'common:FIX',
  DELETE: 'common:DELETE',
  DELETE_USER_PICTURE: 'common:DELETE_USER_PICTURE',
  SAVE: 'common:SAVE',
  NEXT: 'common:NEXT',
  YES: 'common:YES',
  NO: 'common:NO',
  SEARCH: 'common:SEARCH',
  FILTER_BY: 'common:FILTER_BY',
  FILTER: 'common:FILTER',
  LOGGED_IN_AS: 'common:LOGGED_IN_AS',
  ERROR_MESSAGE: 'common:ERROR_MESSAGE',
  LOADING_TEXT: 'common:LOADING_TEXT',
  SELECT: 'common:SELECT',
  DEFAULT_HTML_TITLE: 'common:DEFAULT_HTML_TITLE',
  MORE_INFORMATIONS: 'common:MORE_INFORMATIONS',
  HOUR: 'common:HOUR',
  SELECT_ALL: 'common:SELECT_ALL',
  NO_RESULT: 'common:NO_RESULT',
  NOT_AUTHORIZED: 'common:NOT_AUTHORIZED',
  MORE_INFORMATION: 'common:MORE_INFORMATION',

  // MENU

  MENU_CHANGE_CLUB: 'common:MENU_CHANGE_CLUB',
  MENU_MODIFY_PROFIL: 'common:MENU_MODIFY_PROFIL',
  MENU_CHANGE_LOCALE: 'common:MENU_CHANGE_LOCALE',

  // CLUBS

  CLUBS_YOUR_CLUBS: 'common:CLUBS_YOUR_CLUBS',

  // ACCOUNT

  ACCOUNT_TITLE: 'common:ACCOUNT_TITLE',
  ACCOUNT_USERNAME: 'common:ACCOUNT_USERNAME',
  ACCOUNT_FIRST_NAME: 'common:ACCOUNT_FIRST_NAME',
  ACCOUNT_LAST_NAME: 'common:ACCOUNT_LAST_NAME',
  ACCOUNT_EMAIL: 'common:ACCOUNT_EMAIL',
  ACCOUNT_FRENCH: 'common:ACCOUNT_FRENCH',
  ACCOUNT_GERMAN: 'common:ACCOUNT_GERMAN',
  ACCOUNT_LANGUAGE: 'common:ACCOUNT_LANGUAGE',

  // ADD CLUB ADMINISTRATOR

  ADDCLUBADMINISTRATOR_BUTTON: 'common:ADDCLUBADMINISTRATOR_BUTTON',
  ADDCLUBADMINISTRATOR_TITLE: 'common:ADDCLUBADMINISTRATOR_TITLE',
  ADDCLUBADMINISTRATOR_NO_MEMBER: 'common:ADDCLUBADMINISTRATOR_NO_MEMBER',
  ADDCLUBADMINISTRATOR_SEARCH_FIELD_LABEL:
    'ADDCLUBADMINISTRATOR_SEARCH_FIELD_LABEL',
  ADDCLUBADMINISTRATOR_IF_NOT_IN_LIST:
    'common:ADDCLUBADMINISTRATOR_IF_NOT_IN_LIST',
  ADDCLUBADMINISTRATOR_ADD_IT: 'common:ADDCLUBADMINISTRATOR_ADD_IT',
  ADDCLUBADMINISTRATOR_SUCCESS: 'common:ADDCLUBADMINISTRATOR_SUCCESS',
  MANAGE_ROLES_TITLE: 'common:MANAGE_ROLES_TITLE',
  MANAGE_ROLES_CHECKALL_LABEL: 'common:MANAGE_ROLES_CHECKALL_LABEL',
  MANAGE_ROLES_VIEW_LABEL: 'common:MANAGE_ROLES_VIEW_LABEL',
  MANAGE_ROLES_CREATE_LABEL: 'common:MANAGE_ROLES_CREATE_LABEL',
  MANAGE_ROLES_EDIT_LABEL: 'common:MANAGE_ROLES_EDIT_LABEL',
  MANAGE_ROLES_REMOVE_LABEL: 'common:MANAGE_ROLES_REMOVE_LABEL',
  MANAGE_ROLES_NEW_ROLE: 'common:MANAGE_ROLES_NEW_ROLE',
  MANAGE_ROLES_NAME_OF_ROLE_LABEL: 'common:MANAGE_ROLES_NAME_OF_ROLE_LABEL',
  MANAGE_ROLES_COLLAPSE_ALL: 'common:MANAGE_ROLES_COLLAPSE_ALL',
  MANAGE_ROLES_EXPAND_ALL: 'common:MANAGE_ROLES_EXPAND_ALL',
  MANAGE_ROLES_CHECK_ALL: 'common:MANAGE_ROLES_CHECK_ALL',
  MANAGE_ROLES_UNCHECK_ALL: 'common:MANAGE_ROLES_UNCHECK_ALL',
  MANAGE_ROLES_NO_MEMBERS: 'common:MANAGE_ROLES_NO_MEMBERS',

  // ADD CLUB REPORTER

  ADDCLUBREPORTER_BUTTON: 'common:ADDCLUBREPORTER_BUTTON',
  ADDCLUBREPORTER_TITLE: 'common:ADDCLUBREPORTER_TITLE',
  ADDCLUBREPORTER_SEARCH_FIELD_LABEL:
    'common:ADDCLUBREPORTER_SEARCH_FIELD_LABEL',
  ADDCLUBREPORTER_IF_NOT_IN_LIST: 'common:ADDCLUBREPORTER_IF_NOT_IN_LIST',
  ADDCLUBREPORTER_ADD_IT: 'common:ADDCLUBREPORTER_ADD_IT',
  ADDCLUBREPORTER_SUCCESS: 'common:ADDCLUBREPORTER_SUCCESS',
  ADDCLUBREPORTER_TEAMS: 'common:ADDCLUBREPORTER_TEAMS',

  // CLUB EQUIPMENTS
  ADDCLUBEQUIPMENT_BUTTON: 'common:ADDCLUBEQUIPMENT_BUTTON',
  CREATECLUBEQUIPMENT_SUCCESS: 'common:CREATECLUBEQUIPMENT_SUCCESS',
  ADDCLUBEQUIPMENT_NAME: 'common:ADDCLUBEQUIPMENT_NAME',
  EQUIPMENTS_NOTHING: 'common:EQUIPMENTS_NOTHING',

  // CREATE COMMUNITY GROUP

  CREATECOMMUNITYGROUP_BUTTON: 'common:CREATECOMMUNITYGROUP_BUTTON',
  CREATECOMMUNITYGROUP_TITLE: 'common:CREATECOMMUNITYGROUP_TITLE',
  CREATECOMMUNITYGROUP_NAME_FIELD_LABEL:
    'CREATECOMMUNITYGROUP_NAME_FIELD_LABEL',
  CREATECOMMUNITYGROUP_COLOR_FIELD_LABEL:
    'CREATECOMMUNITYGROUP_COLOR_FIELD_LABEL',
  CREATECOMMUNITYGROUP_EMOJI_FIELD_LABEL:
    'CREATECOMMUNITYGROUP_EMOJI_FIELD_LABEL',
  CREATECOMMUNITYGROUP_SUCCESS: 'common:CREATECOMMUNITYGROUP_SUCCESS',
  EDITCOMMUNITYGROUP_TITLE: 'common:EDITCOMMUNITYGROUP_TITLE',
  EDITCOMMUNITYGROUPMEMBERS_MEMBERS_OF_GROUP:
    'EDITCOMMUNITYGROUPMEMBERS_MEMBERS_OF_GROUP',

  // COMMUNITY IMPORT MEMBERS

  COMMUNITY_IMPORT_BUTTON: 'common:COMMUNITY_IMPORT_BUTTON',
  COMMUNITY_IMPORT_TITLE: 'common:COMMUNITY_IMPORT_TITLE',
  COMMUNITY_IMPORT_ITS_POSSIBLE: 'common:COMMUNITY_IMPORT_ITS_POSSIBLE',
  COMMUNITY_IMPORT_GUIDELINE_TEXT: 'common:COMMUNITY_IMPORT_GUIDELINE_TEXT',
  COMMUNITY_IMPORT_GUIDELINE_LINK: 'common:COMMUNITY_IMPORT_GUIDELINE_LINK',
  COMMUNITY_IMPORT_SUBMIT_BUTTON: 'common:COMMUNITY_IMPORT_SUBMIT_BUTTON',
  COMMUNITY_IMPORT_HELPDESKLINK: 'common:COMMUNITY_IMPORT_HELPDESKLINK',
  COMMUNITY_IMPORT_EMAILNOTIFICATION_TEXT:
    'COMMUNITY_IMPORT_EMAILNOTIFICATION_TEXT',

  // EXPORT MEMBERS
  COMMUNITY_EXPORT_MEMBERS: 'common:COMMUNITY_EXPORT_MEMBERS',
  COMMUNITY_EXPORT_EXPLANATION: 'common:COMMUNITY_EXPORT_EXPLANATION',
  COMMUNITY_EXPORT_SEE: 'common:COMMUNITY_EXPORT_SEE',
  COMMUNITY_EXPORT_IN_PROGRESS: 'common:COMMUNITY_EXPORT_IN_PROGRESS',

  // GROUP CARD

  GROUPCARD_SEE_MEMBERS: 'common:GROUPCARD_SEE_MEMBERS',
  GROUPCARD_DELETE_SUCCESS: 'common:GROUPCARD_DELETE_SUCCESS',

  // IMAGE FIELD

  IMAGEFIELD_NEW_PICTURE: 'common:IMAGEFIELD_NEW_PICTURE',

  // APP BAR

  APPBAR_ORGANISATIONDIALOG_TITLE: 'common:APPBAR_ORGANISATIONDIALOG_TITLE',
  APPBAR_ORGANISATIONDIALOG_TEXT_1: 'common:APPBAR_ORGANISATIONDIALOG_TEXT_1',
  APPBAR_ORGANISATIONDIALOG_TEXT_2: 'common:APPBAR_ORGANISATIONDIALOG_TEXT_2',

  // DASHBOARD

  DASHBOARD_TITLE: 'common:DASHBOARD_TITLE',
  DASHBOARD_HELLO: 'common:DASHBOARD_HELLO',
  DASHBOARD_PAPER1_TITLE: 'common:DASHBOARD_PAPER1_TITLE',
  DASHBOARD_PAPER1_BODY: 'common:DASHBOARD_PAPER1_BODY',
  DASHBOARD_PAPER2_TITLE: 'common:DASHBOARD_PAPER2_TITLE',
  DASHBOARD_PAPER2_BODY: 'common:DASHBOARD_PAPER2_BODY',
  DASHBOARD_PAPER3_TITLE: 'common:DASHBOARD_PAPER3_TITLE',
  DASHBOARD_APP_LINK_TITLE: 'common:DASHBOARD_APP_LINK_TITLE',
  DASHBOARD_APP_LINK_BODY: 'common:DASHBOARD_APP_LINK_BODY',
  DASHBOARD_APP_LINK_BUTTON: 'common:DASHBOARD_APP_LINK_BUTTON',

  // CLUB

  CLUB_TITLE: 'common:CLUB_TITLE',
  CLUB_NAVIGATION_INFORMATIONS: 'common:CLUB_NAVIGATION_INFORMATIONS',
  CLUB_NAVIGATION_ADMINISTRATORS: 'common:CLUB_NAVIGATION_ADMINISTRATORS',
  CLUB_NAVIGATION_REPORTERS: 'common:CLUB_NAVIGATION_REPORTERS',
  CLUB_NAVIGATION_EQUIPMENTS: 'common:CLUB_NAVIGATION_EQUIPMENTS',
  CLUB_NAVIGATION_TASKS: 'common:CLUB_NAVIGATION_TASKS',

  CLUB_INFORMATIONS_CLUBNAME_LABEL: 'common:CLUB_INFORMATIONS_CLUBNAME_LABEL',
  CLUB_INFORMATIONS_CLUBNAME_HELPERTEXT:
    'CLUB_INFORMATIONS_CLUBNAME_HELPERTEXT',

  CLUB_INFORMATIONS_SHORTNAME_LABEL: 'common:CLUB_INFORMATIONS_SHORTNAME_LABEL',
  CLUB_INFORMATIONS_SHORTNAME_HELPERTEXT:
    'CLUB_INFORMATIONS_SHORTNAME_HELPERTEXT',

  CLUB_INFORMATIONS_PRESENTATION_LABEL:
    'common:CLUB_INFORMATIONS_PRESENTATION_LABEL',
  CLUB_INFORMATIONS_PRESENTATION_HELPERTEXT:
    'CLUB_INFORMATIONS_PRESENTATION_HELPERTEXT',

  CLUB_INFORMATIONS_DEPARTMENT_LABEL:
    'common:CLUB_INFORMATIONS_DEPARTMENT_LABEL',
  CLUB_INFORMATIONS_DEPARTMENT_HELPERTEXT:
    'CLUB_INFORMATIONS_DEPARTMENT_HELPERTEXT',

  CLUB_INFORMATIONS_COUNTRY_LABEL: 'common:CLUB_INFORMATIONS_COUNTRY_LABEL',
  CLUB_INFORMATIONS_COUNTRY_HELPERTEXT:
    'common:CLUB_INFORMATIONS_COUNTRY_HELPERTEXT',

  CLUB_INFORMATIONS_PRIMARYCOLOR: 'common:CLUB_INFORMATIONS_PRIMARYCOLOR',
  CLUB_INFORMATIONS_SECONDARYCOLOR: 'common:CLUB_INFORMATIONS_SECONDARYCOLOR',

  CLUB_INFORMATIONS_SCORENCOID_LABEL:
    'common:CLUB_INFORMATIONS_SCORENCOID_LABEL',
  CLUB_INFORMATIONS_SCORENCOID_HELPERTEXT:
    'CLUB_INFORMATIONS_SCORENCOID_HELPERTEXT',

  CLUB_INFORMATIONS_CONTACTINFORMATIONS_TITLE:
    'CLUB_INFORMATIONS_CONTACTINFORMATIONS_TITLE',
  CLUB_INFORMATIONS_EMAIL_LABEL: 'common:CLUB_INFORMATIONS_EMAIL_LABEL',
  CLUB_INFORMATIONS_EMAIL_HELPERTEXT:
    'common:CLUB_INFORMATIONS_EMAIL_HELPERTEXT',

  CLUB_INFORMATIONS_WEBSITE_LABEL: 'common:CLUB_INFORMATIONS_WEBSITE_LABEL',
  CLUB_INFORMATIONS_WEBSITE_HELPERTEXT:
    'common:CLUB_INFORMATIONS_WEBSITE_HELPERTEXT',
  CLUB_INFORMATIONS_TICKETOFFICE_LABEL:
    'common:CLUB_INFORMATIONS_TICKETOFFICE_LABEL',
  CLUB_INFORMATIONS_TICKETOFFICE_HELPERTEXT:
    'CLUB_INFORMATIONS_TICKETOFFICE_HELPERTEXT',

  CLUB_INFORMATIONS_UPDATED_SUCCESS: 'common:CLUB_INFORMATIONS_UPDATED_SUCCESS',

  CLUB_ADMINISTRATORS_INFOALERT: 'common:CLUB_ADMINISTRATORS_INFOALERT',
  CLUB_ADMINISTRATORS_NOBODY: 'common:CLUB_ADMINISTRATORS_NOBODY',

  CLUB_ADMINISTRATORS_TABLECELL_NAME:
    'common:CLUB_ADMINISTRATORS_TABLECELL_NAME',
  CLUB_ADMINISTRATORS_TABLECELL_EMAIL:
    'common:CLUB_ADMINISTRATORS_TABLECELL_EMAIL',
  CLUB_ADMINISTRATORS_TABLECELL_PHONE:
    'common:CLUB_ADMINISTRATORS_TABLECELL_PHONE',

  CLUB_ADMINISTRATORS_TABLECELL_SEE_MEMBER:
    'CLUB_ADMINISTRATORS_TABLECELL_SEE_MEMBER',

  CLUB_ADMINISTRATORS_DELETE_ONE_SUCCESS:
    'CLUB_ADMINISTRATORS_DELETE_ONE_SUCCESS',

  CLUB_REPORTERS_INFOALERT: 'common:CLUB_REPORTERS_INFOALERT',
  CLUB_REPORTERS_NOBODY: 'common:CLUB_REPORTERS_NOBODY',
  CLUB_REPORTERS_DELETE_ONE_SUCCESS: 'common:CLUB_REPORTERS_DELETE_ONE_SUCCESS',

  // TASKS
  CLUB_TASKS_ADD: 'common:CLUB_TASKS_ADD',
  CLUB_TASKS_NO_TASK: 'common:CLUB_TASKS_NO_TASK',
  CLUB_TASKS_OFFICIAL_HELP: 'common:CLUB_TASKS_OFFICIAL_HELP',
  CLUB_TASKS_GAME_TYPE: 'common:CLUB_TASKS_GAME_TYPE',
  CLUB_TASKS_NAME_LIMIT: 'common:CLUB_TASKS_NAME_LIMIT',

  // CLUBHOUSE
  CLUBHOUSE_TITLE: 'common:CLUBHOUSE_TITLE',
  ADD_CLUBHOUSE: 'common:ADD_CLUBHOUSE',
  OPEN_CLUBHOUSE: 'common:OPEN_CLUBHOUSE',
  CLUBHOUSE_NAME: 'common:CLUBHOUSE_NAME',
  CLUBHOUSE_SINGLE_RETURN: 'common:CLUBHOUSE_SINGLE_RETURN',
  DELETE_CONFIRMATION_CLUBHOUSE_THIS_SCREEN:
    'DELETE_CONFIRMATION_CLUBHOUSE_THIS_SCREEN',
  DELETE_CONFIRMATION_CLUBHOUSE_THIS_CLUBHOUSE:
    'DELETE_CONFIRMATION_CLUBHOUSE_THIS_CLUBHOUSE',
  CLUBHOUSE_PREMIUM_TITLE: 'common:CLUBHOUSE_PREMIUM_TITLE',
  CLUBHOUSE_PREMIUM_TEXT: 'common:CLUBHOUSE_PREMIUM_TEXT',
  CLUBHOUSE_NO_CLUBHOUSE: 'common:CLUBHOUSE_NO_CLUBHOUSE',

  // NEWS
  NEWS_TITLE: 'common:NEWS_TITLE',
  ADD_NEWS: 'common:ADD_NEWS',

  // COMMUNITY

  COMMUNITY_TITLE: 'common:COMMUNITY_TITLE',
  CLUB_NAVIGATION_MEMBERS: 'common:CLUB_NAVIGATION_MEMBERS',
  CLUB_NAVIGATION_GROUPS: 'common:CLUB_NAVIGATION_GROUPS',
  CLUB_NAVIGATION_SETTINGS: 'common:CLUB_NAVIGATION_GROUPS',

  COMMUNITY_ADD_MEMBER: 'common:COMMUNITY_ADD_MEMBER',
  COMMUNITY_ADD_MEMBERS_TO_GROUPS: 'common:COMMUNITY_ADD_MEMBERS_TO_GROUPS',
  COMMUNITY_CREATE_GROUP: 'common:COMMUNITY_CREATE_GROUP',
  COMMUNITY_OTHERS_ACTIONS: 'common:COMMUNITY_OTHERS_ACTIONS',
  COMMUNITY_DELETE_MEMBERS: 'common:COMMUNITY_DELETE_MEMBERS',
  COMMUNITY_UPDATE_MEMBERS: 'common:COMMUNITY_UPDATE_MEMBERS',

  COMMUNITY_MEMBERS_TABLECELL_NAME: 'common:COMMUNITY_MEMBERS_TABLECELL_NAME',
  COMMUNITY_MEMBERS_TABLECELL_EMAIL: 'common:COMMUNITY_MEMBERS_TABLECELL_EMAIL',
  COMMUNITY_MEMBERS_TABLECELL_PHONE: 'common:COMMUNITY_MEMBERS_TABLECELL_PHONE',
  COMMUNITY_MEMBERS_TABLECELL_GROUPS:
    'common:COMMUNITY_MEMBERS_TABLECELL_GROUPS',
  COMMUNITY_MEMBERS_TABLECELL_LICENCE:
    'common:COMMUNITY_MEMBERS_TABLECELL_LICENCE',
  COMMUNITY_MEMBERS_DELETE_ONE_SUCCESS:
    'common:COMMUNITY_MEMBERS_DELETE_ONE_SUCCESS',
  COMMUNITY_MEMBERS_DELETE_VALIDATION:
    'common:COMMUNITY_MEMBERS_DELETE_VALIDATION',

  COMMUNITY_MEMBERS_TABLECELL_SEE_MEMBER:
    'COMMUNITY_MEMBERS_TABLECELL_SEE_MEMBER',

  COMMUNITY_ADDMEMBER_RETURN: 'common:COMMUNITY_ADDMEMBER_RETURN',
  COMMUNITY_MEMBERS_LABEL_FIRSTNAME: 'common:COMMUNITY_MEMBERS_LABEL_FIRSTNAME',
  COMMUNITY_MEMBERS_LABEL_LASTNAME: 'common:COMMUNITY_MEMBERS_LABEL_LASTNAME',
  COMMUNITY_MEMBERS_LABEL_EMAIL1: 'common:COMMUNITY_MEMBERS_LABEL_EMAIL1',
  COMMUNITY_MEMBERS_LABEL_EMAIL2: 'common:COMMUNITY_MEMBERS_LABEL_EMAIL2',
  COMMUNITY_MEMBERS_LABEL_EMAIL3: 'common:COMMUNITY_MEMBERS_LABEL_EMAIL3',
  COMMUNITY_MEMBERS_LABEL_PHONE1: 'common:COMMUNITY_MEMBERS_LABEL_PHONE1',
  COMMUNITY_MEMBERS_LABEL_PHONE2: 'common:COMMUNITY_MEMBERS_LABEL_PHONE2',
  COMMUNITY_MEMBERS_LABEL_PHONE3: 'common:COMMUNITY_MEMBERS_LABEL_PHONE3',
  COMMUNITY_MEMBERS_LABEL_POSTADRESS:
    'common:COMMUNITY_MEMBERS_LABEL_POSTADRESS',
  COMMUNITY_MEMBERS_LABEL_LICENSE: 'common:COMMUNITY_MEMBERS_LABEL_LICENSE',
  COMMUNITY_MEMBERS_LABEL_CURRENTLY_IN_THE_CLUB:
    'COMMUNITY_MEMBERS_LABEL_CURRENTLY_IN_THE_CLUB',
  COMMUNITY_MEMBERS_LABEL_CURRENTLY_IN_THE_CLUB_FILTER:
    'COMMUNITY_MEMBERS_LABEL_CURRENTLY_IN_THE_CLUB_FILTER',
  COMMUNITY_MEMBERS_LABEL_EXEMPT_VOLUNTEER:
    'COMMUNITY_MEMBERS_LABEL_EXEMPT_VOLUNTEER',
  COMMUNITY_MEMBERS_LABEL_EXEMPT_VOLUNTEER_FILTER:
    'COMMUNITY_MEMBERS_LABEL_EXEMPT_VOLUNTEER_FILTER',
  COMMUNITY_MEMBERS_LABEL_NOTES: 'common:COMMUNITY_MEMBERS_LABEL_NOTES',
  COMMUNITY_MEMBERS_LABEL_BIRTHDATE: 'common:COMMUNITY_MEMBERS_LABEL_BIRTHDATE',
  COMMUNITY_MEMBERS_LABEL_GENDER: 'common:COMMUNITY_MEMBERS_LABEL_GENDER',
  COMMUNITY_MEMBERS_LABEL_ADDMEMBERTOGROUP:
    'COMMUNITY_MEMBERS_LABEL_ADDMEMBERTOGROUP',
  COMMUNITY_MEMBERS_LABEL_ADDMEMBER_TO_REPORTER:
    'COMMUNITY_MEMBERS_LABEL_ADDMEMBER_TO_REPORTER',
  COMMUNITY_MEMBERS_LABEL_HISTORY: 'common:COMMUNITY_MEMBERS_LABEL_HISTORY',
  COMMUNITY_MEMBERS_LABEL_TEAMS: 'common:COMMUNITY_MEMBERS_LABEL_TEAMS',
  COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE:
    'common:COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE',
  COMMUNITY_MEMBERS_PLAYER_UPGRADE_TYPE:
    'COMMUNITY_MEMBERS_PLAYER_UPGRADE_TYPE',
  COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE_NONE:
    'COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE_NONE',
  COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE_DEPARTEMENT:
    'COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE_DEPARTEMENT',
  COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE_REGION:
    'COMMUNITY_MEMBERS_LABEL_UPGRADE_TYPE_REGION',
  COMMUNITY_MEMBERS_LABEL_ADDMEMBERTOTEAM:
    'COMMUNITY_MEMBERS_LABEL_ADDMEMBERTOTEAM',
  COMMUNITY_MEMBERS_LABEL_NOTIFYMEMBER:
    'common:COMMUNITY_MEMBERS_LABEL_NOTIFYMEMBER',
  COMMUNITY_MEMBERS_LABEL_ALLOWCLUBEMAIL:
    'COMMUNITY_MEMBERS_LABEL_ALLOWCLUBEMAIL',
  COMMUNITY_MEMBERS_LABEL_CHANGEPICTURE:
    'COMMUNITY_MEMBERS_LABEL_CHANGEPICTURE',
  COMMUNITY_MEMBERS_DATE_MASK: 'common:COMMUNITY_MEMBERS_DATE_MASK',
  COMMUNITY_MEMBERS_ADD_SUCCESS: 'common:COMMUNITY_MEMBERS_ADD_SUCCESS',
  COMMUNITY_MEMBERS_NO_ADMINISTRATOR:
    'common:COMMUNITY_MEMBERS_NO_ADMINISTRATOR',
  COMMUNITY_MEMBERS_NO_MEMBER: 'common:COMMUNITY_MEMBERS_NO_MEMBER',
  COMMUNITY_MEMBERS_LABEL_ROLE: 'common:COMMUNITY_MEMBERS_LABEL_ROLE',
  COMMUNITY_MODIFY_A_MEMBER: 'common:COMMUNITY_MODIFY_A_MEMBER',
  COMMUNITY_MEMBERS_LABEL_SIMPLEMEMBER:
    'common:COMMUNITY_MEMBERS_LABEL_SIMPLEMEMBER',
  COMMUNITY_MEMBERS_LABEL_ADMINISTRATOR:
    'COMMUNITY_MEMBERS_LABEL_ADMINISTRATOR',
  COMMUNITY_MEMBERS_LABEL_REPORTER: 'common:COMMUNITY_MEMBERS_LABEL_REPORTER',

  // LIEN FEDERATION
  FEDERATION_LINK_PAGE_TITLE: 'common:FEDERATION_LINK_PAGE_TITLE',
  LINK_FEDE_RESYNCHRONIZE: 'common:LINK_FEDE_RESYNCHRONIZE',
  CORRECTION_SENT: 'common:CORRECTION_SENT',
  LIST_COMPETITION_NOT_SYNCHRONIZED: 'common:LIST_COMPETITION_NOT_SYNCHRONIZED',
  LIST_COMPETITION_SYNCHRONIZED: 'common:LIST_COMPETITION_SYNCHRONIZED',
  FEDERATION_LINK_TITLE: 'common:FEDERATION_LINK_TITLE',
  FEDERATION_LINK_COMPETITIONS: 'common:FEDERATION_LINK_COMPETITIONS',
  FEDERATION_LINK_HELP: 'common:FEDERATION_LINK_HELP',
  FEDERATION_LINK_LOADING: 'common:FEDERATION_LINK_LOADING',
  FEDERATION_LINK_SYNCRO: 'common:FEDERATION_LINK_SYNCRO',
  FEDERATION_LINK_DESCRIPTION: 'common:FEDERATION_LINK_DESCRIPTION',
  FEDERATION_LINK_COMPETITIONS_LOADING:
    'common:FEDERATION_LINK_COMPETITIONS_LOADING',
  FEDERATION_LINK_SCORE: 'common:FEDERATION_LINK_SCORE',
  FEDERATION_LINK_BUTTON_SCORE: 'common:FEDERATION_LINK_BUTTON_SCORE',
  FEDERATION_LINK_ISSUE: 'common:FEDERATION_LINK_ISSUE',
  FEDERATION_LINK_OK: 'common:FEDERATION_LINK_OK',
  FEDERATION_LINK_INPROGRESS: 'common:FEDERATION_LINK_INPROGRESS',
  FEDERATION_LINK_TEAM_CLUB: 'common:FEDERATION_LINK_TEAM_CLUB',
  FEDERATION_LINK_ADD: 'common:FEDERATION_LINK_ADD',
  FEDERATION_LINK_SYNC_COMPETITIONS: 'common:FEDERATION_LINK_SYNC_COMPETITIONS',
  FEDERATION_LINK_OTHER_COMPETITIONS:
    'common:FEDERATION_LINK_OTHER_COMPETITIONS',
  FEDERATION_LINK_ORGANISATION_COMPETITIONS:
    'FEDERATION_LINK_ORGANISATION_COMPETITIONS',
  FEDERATION_LINK_ALL_COMPETITIONS: 'common:FEDERATION_LINK_ALL_COMPETITIONS',
  FEDERATION_LINK_MATCHS: 'common:FEDERATION_LINK_MATCHS',
  FEDERAION_LINK_LAST_CHANCE: 'common:FEDERAION_LINK_LAST_CHANCE',
  FEDERATION_LINK_LAST_CHANCE_DESCRIPTION:
    'FEDERATION_LINK_LAST_CHANCE_DESCRIPTION',
  FEDERATION_LINK_THANKS: 'common:FEDERATION_LINK_THANKS',
  NUMBER_DAYS: 'common:NUMBER_DAYS',
  NUMBER_CHAMPIONSHIP: 'common:NUMBER_CHAMPIONSHIP',
  NUMBER_EVENT_WITHOUT_SCORE: 'common:NUMBER_EVENT_WITHOUT_SCORE',
  SCRAPE_SYNCHRONIZE_COMPETITION: 'common:SCRAPE_SYNCHRONIZE_COMPETITION',
  GET_DAY: 'common:GET_DAY',
  GET_DAYS: 'common:GET_DAYS',
  DELETE_COMPETITION: 'common:DELETE_COMPETITION',
  NOT_FOUND_CLUB: 'common:NOT_FOUND_CLUB',
  UNDETERMINED_DATE: 'common:UNDETERMINED_DATE',

  // MATCHS

  MATCHS_TITLE: 'common:MATCHS_TITLE',
  MATCHS_STATUS: 'common:MATCHS_STATUS',
  MATCHS_STATUS_TOCOMELIVE: 'common:MATCHS_STATUS_TOCOMELIVE',
  MATCHS_STATUS_FINISH: 'common:MATCHS_STATUS_FINISH',
  MATCHS_STATUS_POSTPONED: 'common:MATCHS_STATUS_POSTPONED',
  MATCHS_STATUS_TOCOMELIVE_SINGULAR: 'common:MATCHS_STATUS_TOCOMELIVE',
  MATCHS_STATUS_FINISH_SINGULAR: 'common:MATCHS_STATUS_FINISH_SINGULAR',
  MATCHS_STATUS_POSTPONED_SINGULAR: 'common:MATCHS_STATUS_POSTPONED_SINGULAR',
  MATCHS_STATUS_TOCOME_SINGULAR: 'common:MATCHS_STATUS_TOCOME_SINGULAR',
  MATCHS_STATUS_WITHDRAWAL_SINGULAR: 'common:MATCHS_STATUS_WITHDRAWAL_SINGULAR',
  MATCHS_STATUS_LIVE_SINGULAR: 'common:MATCHS_STATUS_LIVE_SINGULAR',
  MATCHS_MISSINGFORTHISTEAM: 'common:MATCHS_MISSINGFORTHISTEAM',
  MATCHS_THEREISNOMATCHS: 'common:MATCHS_THEREISNOMATCHS',
  MATCHS_THERE_IS_NO_PAST_MATCHS_COMPLETE:
    'MATCHS_THERE_IS_NO_PAST_MATCHS_COMPLETE',
  MATCHS_THERE_IS_NO_NEXT_MATCHS_COMPLETE:
    'MATCHS_THERE_IS_NO_NEXT_MATCHS_COMPLETE',
  MATCHS_OFFTEAM: 'common:MATCHS_OFFTEAM',
  MATCHS_GODETAILS: 'common:MATCHS_GODETAILS',
  MATCHS_DATE_LABEL: 'common:MATCHS_DATE_LABEL',
  MATCHS_TIME_LABEL: 'common:MATCHS_TIME_LABEL',
  MATCH_SCORENCO_URL: 'common:MATCH_SCORENCO_URL',
  MATCHS_SINGLE_FEDEURL: 'common:MATCHS_SINGLE_FEDEURL',
  MATCHS_SINGLE_GAMESHEET: 'common:MATCHS_SINGLE_GAMESHEET',
  MATCHS_SINGLE_RETURN: 'common:MATCHS_SINGLE_RETURN',
  MATCHS_PLANNER_ALLMEMBERS: 'common:MATCHS_PLANNER_ALLMEMBERS',
  MATCHS_ADD_ONE: 'common:MATCHS_ADD_ONE',
  MATCHS_ADD_STEP1_ASK: 'common:MATCHS_ADD_STEP1_ASK',
  MATCHS_ADD_STEP1_IFYES: 'common:MATCHS_ADD_STEP1_IFYES',
  MATCHS_ADD_TEAM_A: 'common:MATCHS_ADD_TEAM_A',
  MATCHS_ADD_TEAM_B: 'common:MATCHS_ADD_TEAM_B',
  MATCHS_NEW_EXHIBITION: 'common:MATCHS_NEW_EXHIBITION',
  MATCHS_NEW_EXHIBITION_CREATE: 'common:MATCHS_NEW_EXHIBITION_CREATE',
  RESYNC_ADD_TEAM_EVENT: 'common:RESYNC_ADD_TEAM_EVENT',
  RESYNC_MODE: 'common:RESYNC_MODE',
  RESYNC_TITLE: 'common:RESYNC_TITLE',
  RESYNC_UPDATE_MATCH: 'common:RESYNC_UPDATE_MATCH',
  MATCHS_RESYNCMODAL_TITLE: 'common:MATCHS_RESYNCMODAL_TITLE',
  MATCHS_RESYNCMODAL_INPUTLABEL: 'common:MATCHS_RESYNCMODAL_INPUTLABEL',
  MATCHS_RESYNCMODAL_INPUTLABEL_2: 'common:MATCHS_RESYNCMODAL_INPUTLABEL_2',
  MATCHS_RESYNCMODAL_NO_COMPETITION: 'common:MATCHS_RESYNCMODAL_NO_COMPETITION',
  MATCHS_RESYNCMODAL_COMPETITION_IS_MISSING:
    'MATCHS_RESYNCMODAL_COMPETITION_IS_MISSING',
  MATCHS_RESYNCMODAL_COMPETITION_FEDEURL_INPUTLABEL:
    'MATCHS_RESYNCMODAL_COMPETITION_FEDEURL_INPUTLABEL',
  RESYNCMODAL_LAUNCH_SYNC: 'common:RESYNCMODAL_LAUNCH_SYNC',
  RESYNCMODAL_LAUNCHED: 'common:RESYNCMODAL_LAUNCHED',
  MATCHS_RESYNCMODAL_TOGGLE_DATAS: 'common:MATCHS_RESYNCMODAL_TOGGLE_DATAS',
  MATCHS_RESYNCMODAL_TOGGLE_ROUNDS: 'common:MATCHS_RESYNCMODAL_TOGGLE_ROUNDS',
  MATCH_RESYNCMODAL_TITLE: 'common:MATCH_RESYNCMODAL_TITLE',
  MATCH_RESYNCMODAL_EXPLANATION: 'common:MATCH_RESYNCMODAL_EXPLANATION',
  MATCHS_SET_PLACE: 'common:MATCHS_SET_PLACE',

  // TEAMS

  TEAMS_TITLE: 'common:TEAMS_TITLE',
  TEAMS_SINGLE_RETURN: 'common:TEAMS_SINGLE_RETURN',
  TEAMS_SINGLE: 'common:TEAMS_SINGLE',
  TEAMS_NO_TEAM: 'common:TEAMS_NO_TEAM',
  TEAMS_TEAM_FAN: 'common:TEAMS_TEAM_FAN',
  TEAMS_NAVIGATION_INFORMATIONS: 'common:TEAMS_NAVIGATION_INFORMATIONS',
  TEAMS_NAVIGATION_PLAYERS: 'common:TEAMS_NAVIGATION_PLAYERS',
  TEAMS_NAVIGATION_WIDGETS: 'common:TEAMS_NAVIGATION_WIDGETS',
  TEAMS_NAVIGATION_RULES: 'common:TEAMS_NAVIGATION_RULES',
  TEAMS_ALERT: 'common:TEAMS_ALERT',
  TEAMS_INDEX_NAMEOFTEAM: 'common:TEAMS_INDEX_NAMEOFTEAM',
  TEAMS_INDEX_NAMEOFTEAM_HELPER: 'common:TEAMS_INDEX_NAMEOFTEAM_HELPER',
  TEAMS_INDEX_NAMEOFTEAM_IN_COMPETITION:
    'TEAMS_INDEX_NAMEOFTEAM_IN_COMPETITION',
  TEAMS_INDEX_NAMEOFTEAM_IN_COMPETITION_HELPER:
    'TEAMS_INDEX_NAMEOFTEAM_IN_COMPETITION_HELPER',
  TEAMS_INDEX_IMAGESOFTHETEAM: 'common:TEAMS_INDEX_IMAGESOFTHETEAM',
  TEAMS_INDEX_SCORENCOID: 'common:TEAMS_INDEX_SCORENCOID',
  TEAMS_INDEX_SCORENCOID_HELPERTEXT: 'common:TEAMS_INDEX_SCORENCOID_HELPERTEXT',
  TEAMS_PLAYERS_STAFF: 'common:TEAMS_PLAYERS_STAFF',
  TEAMS_PLAYERS_STAFF_NOBODY: 'common:TEAMS_PLAYERS_STAFF_NOBODY',
  TEAMS_PLAYERS_PLAYERS: 'common:TEAMS_PLAYERS_PLAYERS',
  TEAMS_PLAYERS_PLAYERS_NOBODY: 'common:TEAMS_PLAYERS_PLAYERS_NOBODY',
  TEAMS_EDITPLAYER_TITLE: 'common:TEAMS_EDITPLAYER_TITLE',
  TEAMS_EDITPLAYER_PUBLICNAME: 'common:TEAMS_EDITPLAYER_PUBLICNAME',
  TEAMS_EDITPLAYER_ROLE: 'common:TEAMS_EDITPLAYER_ROLE',
  TEAMS_EDITPLAYER_NUMBER: 'common:TEAMS_EDITPLAYER_NUMBER',
  TEAMS_EDITPLAYER_CAPTAIN: 'common:TEAMS_EDITPLAYER_CAPTAIN',
  TEAMS_EDITPLAYER_IS_BURNED: 'common:TEAMS_EDITPLAYER_IS_BURNED',
  TEAMS_PLAYER_IS_BURNED: 'common:TEAMS_PLAYER_IS_BURNED',
  TEAMS_EDITPLAYER_DELETE: 'common:TEAMS_EDITPLAYER_DELETE',
  TEAMS_ADDPLAYER_TITLE: 'common:TEAMS_ADDPLAYER_TITLE',
  TEAMS_RULES_EDIT_SUCCESS: 'common:TEAMS_RULES_EDIT_SUCCESS',
  TEAMS_RESYNC_COMPETITIONS: 'common:TEAMS_RESYNC_COMPETITIONS',
  TEAMS_RESYNC_COMPETITIONS_LABEL: 'common:TEAMS_RESYNC_COMPETITIONS_LABEL',
  TEAMS_TRANSFERPLAYER_TITLE: 'common:TEAMS_TRANSFERPLAYER_TITLE',
  TEAMS_ADD_ONE: 'common:TEAMS_ADD_ONE',
  TEAMS_MIGRATE: 'common:TEAMS_MIGRATE',
  TEAMS_ADD_STEP1_ASK: 'common:TEAMS_ADD_STEP1_ASK',
  TEAMS_ADD_STEP1_LIENFEDE: 'common:TEAMS_ADD_STEP1_LIENFEDE',
  TEAMS_ADD_STEP2_CLUBOFTEAM: 'common:TEAMS_ADD_STEP2_CLUBOFTEAM',
  TEAMS_ADD_STEP2_AGECATEGORY: 'common:TEAMS_ADD_STEP2_AGECATEGORY',
  TEAMS_ADD_STEP2_GENDER: 'common:TEAMS_ADD_STEP2_GENDER',
  TEAMS_ADD_STEP2_NUMBER: 'common:TEAMS_ADD_STEP2_NUMBER',
  TEAMS_DELETE_EXPLANATION: 'common:TEAMS_DELETE_EXPLANATION',
  TEAMS_CANNOTDELETE_TOOLTIP: 'common:TEAMS_CANNOTDELETE_TOOLTIP',
  TEAMS_LIEN_FEDE: 'common:TEAMS_LIEN_FEDE',
  MIGRATE: 'common:MIGRATE',
  TEAMS_CURRENT_SEASON: 'common:TEAMS_CURRENT_SEASON',
  TEAMS_NEXT_SEASON: 'common:TEAMS_NEXT_SEASON',
  TEAMS_PREVIOUS_SEASON: 'common:TEAMS_PREVIOUS_SEASON',
  TEAMS_MIGRATE_CURRENT_SEASON: 'common:TEAMS_MIGRATE_CURRENT_SEASON',
  TEAMS_MIGRATE_HELP: 'common:TEAMS_MIGRATE_HELP',
  TEAMS_AGREEMENT: 'common:TEAMS_AGREEMENT',

  // WIDGETS

  WIDGETS_TITLE: 'common:WIDGETS_TITLE',
  WIDGETS_NAME: 'common:WIDGETS_NAME',
  WIDGETS_YOURS: 'common:WIDGETS_YOURS',
  WIDGETS_APPEARANCE: 'common:WIDGETS_APPEARANCE',
  WIDGETS_CREATE: 'common:WIDGETS_CREATE',
  WIDGETS_TYPE: 'common:WIDGETS_TYPE',
  WIDGETS_COPY_CLIPBOARD: 'common:WIDGETS_COPY_CLIPBOARD',
  WIDGETS_SETTINGS_COLOR: 'common:WIDGETS_SETTINGS_COLOR',
  WIDGETS_SETTINGS_BACKGROUNDCOLOR: 'common:WIDGETS_SETTINGS_BACKGROUNDCOLOR',
  WIDGETS_SETTINGS_FONTCOLOR: 'common:WIDGETS_SETTINGS_FONTCOLOR',
  WIDGETS_PREMIUM: 'common:WIDGETS_PREMIUM',
  WIDGETS_SETTINGS: 'common:WIDGETS_SETTINGS',
  WIDGETS_RETURN: 'common:WIDGETS_RETURN',
  WIDGETS_HELP_NAME: 'common:WIDGETS_HELP_NAME',
  WIDGETS_HELP_TITLE: 'common:WIDGETS_HELP_TITLE',
  WIDGETS_CODE_INTEGRATION: 'common:WIDGETS_CODE_INTEGRATION',
  WIDGETS_CODE_INTEGRATION_HELP: 'common:WIDGETS_CODE_INTEGRATION_HELP',
  WIDGETS_CODE_INTEGRATION_IFRAME_HELP:
    'common:WIDGETS_CODE_INTEGRATION_IFRAME_HELP',
  WIDGETS_SELECTED_TEAMS: 'common:WIDGETS_SELECTED_TEAMS',
  WIDGETS_DELETE_VALIDATION: 'common:WIDGETS_DELETE_VALIDATION',
  WIDGETS_DATE: 'common:WIDGETS_DATE',
  WIDGETS_SINGLE_TITLE: 'common:WIDGETS_SINGLE_TITLE',
  WIDGETS_SINGLE_SELECT_TEAMS: 'common:WIDGETS_SINGLE_SELECT_TEAMS',
  WIDGETS_MULTIPLE_SELECT_TEAMS: 'common:WIDGETS_MULTIPLE_SELECT_TEAMS',
  WIDGETS_LOADING: 'common:WIDGETS_LOADING',
  WIDGETS_TEAMS_ALL: 'common:WIDGETS_TEAMS_ALL',
  WIDGETS_NO_WIDGET: 'common:WIDGETS_NO_WIDGET',
  WIDGETS_ORDER_BY: 'common:WIDGETS_ORDER_BY',

  WIDGETS_TEAM_NAME: 'common:WIDGETS_TEAM_NAME',
  WIDGETS_NO_TEAM: 'common:WIDGETS_NO_TEAM',
  WIDGETS_TEAM_DESCRIPTION: 'common:WIDGETS_TEAM_DESCRIPTION',

  WIDGETS_CONVOCATION_NAME: 'common:WIDGETS_CONVOCATION_NAME',
  WIDGETS_CONVOCATION_DESCRIPTION: 'common:WIDGETS_CONVOCATION_DESCRIPTION',

  WIDGETS_RANKING_NAME: 'common:WIDGETS_RANKING_NAME',
  WIDGETS_RANKING_DESCRIPTION: 'common:WIDGETS_RANKING_DESCRIPTION',

  WIDGETS_WEEK_EVENTS_NAME: 'common:WIDGETS_WEEK_EVENTS_NAME',
  WIDGETS_WEEK_EVENTS_DESCRIPTION: 'common:WIDGETS_WEEK_EVENTS_DESCRIPTION',

  WIDGETS_EVENTS_LIST_NAME: 'common:WIDGETS_EVENTS_LIST_NAME',
  WIDGETS_EVENTS_LIST_DESCRIPTION: 'common:WIDGETS_EVENTS_LIST_DESCRIPTION',

  WIDGETS_PREVIOUS_NEXT_NAME: 'common:WIDGETS_PREVIOUS_NEXT_NAME',
  WIDGETS_PREVIOUS_NEXT_DESCRIPTION: 'common:WIDGETS_PREVIOUS_NEXT_DESCRIPTION',

  WIDGETS_LIVE_NAME: 'common:WIDGETS_LIVE_NAME',
  WIDGETS_LIVE_DESCRIPTION: 'common:WIDGETS_LIVE_DESCRIPTION',
  WIDGETS_LIVE_SELECT_EVENT: 'common:WIDGETS_LIVE_SELECT_EVENT',
  WIDGETS_LIVE_TYPE_AUTO: 'common:WIDGETS_LIVE_TYPE_AUTO',
  WIDGETS_LIVE_TYPE_FIXE: 'common:WIDGETS_LIVE_TYPE_FIXE',
  WIDGETS_LIVE_TYPE_HELP: 'common:WIDGETS_LIVE_TYPE_HELP',
  WIDGETS_LIVE_TYPE_AUTO_LABEL: 'common:WIDGETS_LIVE_TYPE_AUTO_LABEL',
  WIDGETS_LIVE_TYPE_AUTO_HELP: 'common:WIDGETS_LIVE_TYPE_AUTO_HELP',
  WIDGETS_LIVE_DAY: 'common:WIDGETS_LIVE_DAY',
  WIDGETS_LIVE_DAY_PLURAL: 'common:WIDGETS_LIVE_DAY_PLURAL',

  WIDGETS_PLAYERS_NAME: 'common:WIDGETS_PLAYERS_NAME',
  WIDGETS_PLAYERS_DESCRIPTION: 'common:WIDGETS_PLAYERS_DESCRIPTION',

  WIDGETS_DAY_CHANGE: 'common:WIDGETS_DAY_CHANGE',
  WIDGETS_DAY_CHANGE_HELP: 'common:WIDGETS_DAY_CHANGE_HELP',

  WIDGETS_COMPETITION_NAME: 'common:WIDGETS_COMPETITION_NAME',
  WIDGETS_COMPETITION_DESCRIPTION: 'common:WIDGETS_COMPETITION_DESCRIPTION',
  WIDGETS_INCOMING_MATCHS: 'common:WIDGETS_INCOMING_MATCHS',
  WIDGETS_CHOICE_TEAM_NAME: 'common:WIDGETS_CHOICE_TEAM_NAME',
  WIDGETS_DISPLAY_PRIORITY: 'common:WIDGETS_DISPLAY_PRIORITY',

  WIDGETS_MATCHS_AWAY_HOME: 'common:WIDGETS_MATCHS_AWAY_HOME',
  WIDGETS_MATCHS_AWAY_HOME_HELP: 'common:WIDGETS_MATCHS_AWAY_HOME_HELP',

  // PLANNER

  PLANNER_TITLE: 'common:PLANNER_TITLE',
  PLANNER_SUMMARY: 'common:PLANNER_SUMMARY',
  PLANNER_LAUNCH: 'common:PLANNER_LAUNCH',
  PLANNER_THISWEEK: 'common:PLANNER_THISWEEK',
  PLANNER_DASHBOARDNOTE_TITLE: 'common:PLANNER_DASHBOARDNOTE_TITLE',
  PLANNER_PREMIUM_TITLE: 'common:PLANNER_PREMIUM_TITLE',
  PLANNER_PREMIUM_TEXT: 'common:PLANNER_PREMIUM_TEXT',
  PLANNER_ERROR_NO_EQUIPMENT: 'common:PLANNER_ERROR_NO_EQUIPMENT',
  PLANNER_BUTTON_DEMO: 'common:PLANNER_BUTTON_DEMO',

  // HELP CENTER

  HELPCENTER_TITLE: 'common:HELPCENTER_TITLE',
  HELPCENTER_LINK: 'common:HELPCENTER_LINK',

  // LOGO INPUT

  PICTURE_INPUT_CHANGE_BUTTON: 'common:PICTURE_INPUT_CHANGE_BUTTON',

  // FILTERS

  FILTERS_STANDBY: 'common:FILTERS_STANDBY',
  FILTERS_INPROGRESS: 'common:FILTERS_INPROGRESS',
  FILTERS_FINISH: 'common:FILTERS_FINISH',

  // EMOJI PICKER

  EMOJIPICKER_SEARCH: 'common:EMOJIPICKER_SEARCH',
  EMOJIPICKER_CLEAR: 'common:EMOJIPICKER_CLEAR',
  EMOJIPICKER_NOTFOUND: 'common:EMOJIPICKER_NOTFOUND',
  EMOJIPICKER_SKINTEXT: 'common:EMOJIPICKER_SKINTEXT',
  EMOJIPICKER_CATEGORIES_LABEL: 'common:EMOJIPICKER_CATEGORIES_LABEL',
  EMOJIPICKER_CATEGORIES_SEARCH: 'common:EMOJIPICKER_CATEGORIES_SEARCH',
  EMOJIPICKER_CATEGORIES_RECENT: 'common:EMOJIPICKER_CATEGORIES_RECENT',
  EMOJIPICKER_CATEGORIES_SMILEYS: 'common:EMOJIPICKER_CATEGORIES_SMILEYS',
  EMOJIPICKER_CATEGORIES_PEOPLE: 'common:EMOJIPICKER_CATEGORIES_PEOPLE',
  EMOJIPICKER_CATEGORIES_NATURE: 'common:EMOJIPICKER_CATEGORIES_NATURE',
  EMOJIPICKER_CATEGORIES_FOODS: 'common:EMOJIPICKER_CATEGORIES_FOODS',
  EMOJIPICKER_CATEGORIES_ACTIVITY: 'common:EMOJIPICKER_CATEGORIES_ACTIVITY',
  EMOJIPICKER_CATEGORIES_PLACES: 'common:EMOJIPICKER_CATEGORIES_PLACES',
  EMOJIPICKER_CATEGORIES_OBJECTS: 'common:EMOJIPICKER_CATEGORIES_OBJECTS',
  EMOJIPICKER_CATEGORIES_SYMBOLS: 'common:EMOJIPICKER_CATEGORIES_SYMBOLS',
  EMOJIPICKER_CATEGORIES_FLAGS: 'common:EMOJIPICKER_CATEGORIES_FLAGS',
  EMOJIPICKER_CATEGORIES_CUSTOM: 'common:EMOJIPICKER_CATEGORIES_CUSTOM',
  EMOJIPICKER_SKINTONES_DEFAULT: 'common:EMOJIPICKER_SKINTONES_DEFAULT',
  EMOJIPICKER_SKINTONES_LIGHT: 'common:EMOJIPICKER_SKINTONES_LIGHT',
  EMOJIPICKER_SKINTONES_MEDIUMLIGHT: 'common:EMOJIPICKER_SKINTONES_MEDIUMLIGHT',
  EMOJIPICKER_SKINTONES_MEDIUM: 'common:EMOJIPICKER_SKINTONES_MEDIUM',
  EMOJIPICKER_SKINTONES_MEDIUMDARK: 'common:EMOJIPICKER_SKINTONES_MEDIUMDARK',
  EMOJIPICKER_SKINTONES_DARK: 'common:EMOJIPICKER_SKINTONES_DARK',

  // SPONSORS

  SPONSORS_TITLE: 'common:SPONSORS_TITLE',
  SPONSORS_LIST: 'common:SPONSORS_LIST',
  SPONSORS_CATEGORIES: 'common:SPONSORS_CATEGORIES',
  SPONSOR_DELETE_ONE_SUCCESS: 'common:SPONSOR_DELETE_ONE_SUCCESS',
  SPONSOR_MAJORSPONSOR_EXPLANATION: 'common:SPONSOR_MAJORSPONSOR_EXPLANATION',
  SPONSOR_NOONE: 'common:SPONSOR_NOONE',
  SPONSOR_MAJORSPONSOR: 'common:SPONSOR_MAJORSPONSOR',
  SPONSOR_LINK: 'common:SPONSOR_LINK',
  SPONSOR_NAME: 'common:SPONSOR_NAME',
  CREATESPONSOR_SUCCESS: 'common:CREATESPONSOR_SUCCESS',
  MODIFY_LOGO: 'common:MODIFY_LOGO',
  ADD_LOGO: 'common:ADD_LOGO',
  ADDSPONSOR_BUTTON: 'common:ADDSPONSOR_BUTTON',
  ADDSPONSOR_TITLE: 'common:ADDSPONSOR_TITLE',
  EDIT_SPONSOR: 'common:EDIT_SPONSOR',
  EDITSPONSOR_SUCCESS: 'common:EDITSPONSOR_SUCCESS',
  SELECT_ONE_OR_MUCH_TEAMS: 'common:SELECT_ONE_OR_MUCH_TEAMS',
  SPONSOR_LINKED_WITH_TEAMS_SWITCH: 'common:SPONSOR_LINKED_WITH_TEAMS_SWITCH',
  SPONSORS_OF_TEAM: 'common:SPONSORS_OF_TEAM',
  SPONSORS_TEAM_EDIT: 'common:SPONSORS_TEAM_EDIT',
  SPONSORS_ADD_SPONSORSTOTEAM_BUTTON:
    'common:SPONSORS_ADD_SPONSORSTOTEAM_BUTTON',
  SPONSORS_NOONE_ISCLIENT: 'common:SPONSORS_NOONE_ISCLIENT',
  SPONSORS_PREMIUM_TITLE: 'common:SPONSORS_PREMIUM_TITLE',
  SPONSORS_PREMIUM_TEXT: 'common:SPONSORS_PREMIUM_TEXT',

  // MONSITECLUB

  MONSITECLUB_TITLE: 'common:MONSITECLUB_TITLE',
  MONSITECLUB_PREMIUM_TITLE: 'common:MONSITECLUB_PREMIUM_TITLE',
  MONSITECLUB_PREMIUM_TEXT: 'common:MONSITECLUB_PREMIUM_TEXT',

  // TOURNAMENTS

  TOURNAMENTS_TITLE: 'common:TOURNAMENTS_TITLE',
  TOURNAMENTS_ADD_ONE: 'common:TOURNAMENTS_ADD_ONE',
  TOURNAMENTS_NAVIGATION_DASHBOARD: 'common:TOURNAMENTS_NAVIGATION_DASHBOARD',
  TOURNAMENTS_NAVIGATION_CHALLENGES: 'common:TOURNAMENTS_NAVIGATION_CHALLENGES',
  TOURNAMENTS_NAVIGATION_TEAMS: 'common:TOURNAMENTS_NAVIGATION_TEAMS',
  TOURNAMENTS_ADDFORM_NAME: 'common:TOURNAMENTS_ADDFORM_NAME',
  TOURNAMENTS_ADDFORM_STARTDATE: 'common:TOURNAMENTS_ADDFORM_STARTDATE',
  TOURNAMENTS_ADDFORM_ENDDATE: 'common:TOURNAMENTS_ADDFORM_ENDDATE',
  TOURNAMENTS_ADDFORM_LOCATION: 'common:TOURNAMENTS_ADDFORM_LOCATION',
  TOURNAMENTS_SINGLE_RETURN: 'common:TOURNAMENTS_SINGLE_RETURN',
  TOURNAMENTS_DOWNLOAD_QRCODE: 'common:TOURNAMENTS_DOWNLOAD_QRCODE',
  TOURNAMENTS_DELETE_LOGO: 'common:TOURNAMENTS_DELETE_LOGO',
  DELETE_CONFIRMATION_LOGO: 'common:DELETE_CONFIRMATION_LOGO',
  TOURNAMENTS_PUBLISH: 'common:TOURNAMENTS_PUBLISH',
  TOURNAMENTS_NOT_PUBLISH: 'common:TOURNAMENTS_NOT_PUBLISH',
  PUBLISH_TOURNAMENT: 'common:PUBLISH_TOURNAMENT',
  UNPUBLISH_TOURNAMENT: 'common:UNPUBLISH_TOURNAMENT',
  EXPLAIN_PUBLISH_TOURNAMENT: 'common:EXPLAIN_PUBLISH_TOURNAMENT',
  TOURNAMENTS_LINK_QRCODE: 'common:TOURNAMENTS_LINK_QRCODE',
  OPEN_TOURNAMENT: 'common:OPEN_TOURNAMENT',
  COPY_WIDGET_TOURNAMENT: 'common:COPY_WIDGET_TOURNAMENT',
  COPY_WIDGET_VALIDATION_TOURNAMENT: 'common:COPY_WIDGET_VALIDATION_TOURNAMENT',
  CHALLENGES_SINGLE_RETURN: 'common:CHALLENGES_SINGLE_RETURN',
  CHALLENGES_PERIOD_TIME_OF: 'common:CHALLENGES_PERIOD_TIME_OF',
  CHALLENGES_MATCH_TIME_OF: 'common:CHALLENGES_MATCH_TIME_OF',
  CHALLENGES_MATCH_NUMBER_SETS: 'common:CHALLENGES_MATCH_NUMBER_SETS',
  CHALLENGES_NO_TEAM_SELECTED: 'common:CHALLENGES_NO_TEAM_SELECTED',
  CHALLENGES_MATCH_DATE_TIME: 'common:CHALLENGES_MATCH_DATE_TIME',
  TOURNAMENTS_CHALLENGES: 'common:TOURNAMENTS_CHALLENGES',
  TOURNAMENTS_ADD_CHALLENGE: 'common:TOURNAMENTS_ADD_CHALLENGE',
  TOURNAMENTS_MALE_LABEL: 'common:TOURNAMENTS_MALE_LABEL',
  TOURNAMENTS_FEMALE_LABEL: 'common:TOURNAMENTS_FEMALE_LABEL',
  TOURNAMENTS_NEUTRE_LABEL: 'common:TOURNAMENTS_NEUTRE_LABEL',
  TOURNAMENTS_RULES_NAME_LABEL: 'common:TOURNAMENTS_RULES_NAME_LABEL',
  TOURNAMENTS_RULES_TYPEOFRULE_LABEL:
    'common:TOURNAMENTS_RULES_TYPEOFRULE_LABEL',
  TOURNAMENTS_RULES_TYPEOFRULE_: 'common:TOURNAMENTS_RULES_TYPEOFRULE_LABEL',
  TOURNAMENTS_RULES_TYPEOFRULE_LABEL_GROUPS_RULE_TYPE:
    'TOURNAMENTS_RULES_TYPEOFRULE_LABEL_GROUPS_RULE_TYPE',
  TOURNAMENTS_RULES_TYPEOFRULE_SAME_POSITION_RULE_TYPE:
    'TOURNAMENTS_RULES_TYPEOFRULE_SAME_POSITION_RULE_TYPE',
  TOURNAMENTS_RULES_TYPEOFRULE_CUP_HIGH_CUP_LOW_RULE_TYPE:
    'TOURNAMENTS_RULES_TYPEOFRULE_CUP_HIGH_CUP_LOW_RULE_TYPE',
  TOURNAMENTS_RULES_TYPEOFRULE_CUP_HIGH_SAME_POSITION_LOW_RULE_TYPE:
    'TOURNAMENTS_RULES_TYPEOFRULE_CUP_HIGH_SAME_POSITION_LOW_RULE_TYPE',
  TOURNAMENTS_RULES_TYPEOFRULE_GROUPS_BACK_AND_FORTH_TYPE:
    'TOURNAMENTS_RULES_TYPEOFRULE_GROUPS_BACK_AND_FORTH_TYPE',
  TOURNAMENTS_RULES_NBTEAMS_LABEL: 'common:TOURNAMENTS_RULES_NBTEAMS_LABEL',
  TOURNAMENTS_RULES_NBTEAMS_ADORNMENT_SINGLE:
    'TOURNAMENTS_RULES_NBTEAMS_ADORNMENT_SINGLE',
  TOURNAMENTS_RULES_NBTEAMS_ADORNMENT_PLURIAL:
    'TOURNAMENTS_RULES_NBTEAMS_ADORNMENT_PLURIAL',
  TOURNAMENTS_RULES_NBGROUPS_LABEL: 'common:TOURNAMENTS_RULES_NBGROUPS_LABEL',
  TOURNAMENTS_RULES_NBGROUPS_ADORNMENT_SINGLE:
    'TOURNAMENTS_RULES_NBGROUPS_ADORNMENT_SINGLE',
  TOURNAMENTS_RULES_NBGROUPS_ADORNMENT_PLURIAL:
    'TOURNAMENTS_RULES_NBGROUPS_ADORNMENT_PLURIAL',
  TOURNAMENTS_RULES_NBTEAMS_INFINAL_LABEL:
    'TOURNAMENTS_RULES_NBTEAMS_INFINAL_LABEL',
  TOURNAMENTS_RULES_PERIODTIME_LABEL:
    'common:TOURNAMENTS_RULES_PERIODTIME_LABEL',
  TOURNAMENTS_RULES_PERIODTIME_ADORNMENT_SINGLE:
    'TOURNAMENTS_RULES_PERIODTIME_ADORNMENT_SINGLE',
  TOURNAMENTS_RULES_PERIODTIME_ADORNMENT_PLURIAL:
    'TOURNAMENTS_RULES_PERIODTIME_ADORNMENT_PLURIAL',
  TOURNAMENTS_RULES_TIMEBETWEENPERIODS_LABEL:
    'TOURNAMENTS_RULES_TIMEBETWEENPERIODS_LABEL',
  TOURNAMENTS_RULES_ADD_ONE: 'common:TOURNAMENTS_RULES_ADD_ONE',
  TOURNAMENTS_TEAMS_MANUAL_ADD: 'common:TOURNAMENTS_TEAMS_MANUAL_ADD',
  TOURNAMENTS_TEAMS_NAME_LABEL: 'common:TOURNAMENTS_TEAMS_NAME_LABEL',
  TOURNAMENTS_TEAMS_ADD_ONE: 'common:TOURNAMENTS_TEAMS_ADD_ONE',
  TOURNAMENTS_TEAMS_EDIT_ONE: 'common:TOURNAMENTS_TEAMS_EDIT_ONE',
  TOURNAMENTS_CHALLENGE_NO_TEAM: 'common:TOURNAMENTS_CHALLENGE_NO_TEAM',
  TOURNAMENTS_CHALLENGE_NAME_LABEL: 'common:TOURNAMENTS_CHALLENGE_NAME_LABEL',
  TOURNAMENTS_CHALLENGE_AGE_LIMIT_LABEL:
    'TOURNAMENTS_CHALLENGE_AGE_LIMIT_LABEL',
  TOURNAMENTS_RULES_AGE_LIMIT_ADORNMENT_SINGLE:
    'TOURNAMENTS_RULES_AGE_LIMIT_ADORNMENT_SINGLE',
  TOURNAMENTS_RULES_AGE_LIMITE_ADORNMENT_PLURIAL:
    'TOURNAMENTS_RULES_AGE_LIMITE_ADORNMENT_PLURIAL',
  TOURNAMENTS_CHALLENGE_MAXPLAYERS_LABEL:
    'TOURNAMENTS_CHALLENGE_MAXPLAYERS_LABEL',
  TOURNAMENTS_RULES_MAXPLAYERS_ADORNMENT_SINGLE:
    'TOURNAMENTS_RULES_MAXPLAYERS_ADORNMENT_SINGLE',
  TOURNAMENTS_RULES_MAXPLAYERS_ADORNMENT_PLURIAL:
    'TOURNAMENTS_RULES_MAXPLAYERS_ADORNMENT_PLURIAL',
  TOURNAMENTS_CHALLENGE_RULES_SECTION_LABEL:
    'TOURNAMENTS_CHALLENGE_RULES_SECTION_LABEL',
  TOURNAMENTS_CHALLENGE_PLANNING_SECTION_LABEL:
    'TOURNAMENTS_CHALLENGE_PLANNING_SECTION_LABEL',
  TOURNAMENTS_CHALLENGE_TEAM_DELETE_VALIDATION:
    'TOURNAMENTS_CHALLENGE_TEAM_DELETE_VALIDATION',
  TOURNAMENTS_PREMIUM_TITLE: 'common:TOURNAMENTS_PREMIUM_TITLE',
  TOURNAMENTS_PREMIUM_TEXT: 'common:TOURNAMENTS_PREMIUM_TEXT',
  TOURNAMENTS_CHALLENGE_ADD_PHASE: 'common:TOURNAMENTS_CHALLENGE_ADD_PHASE',
  TOURNAMENTS_CHALLENGE_EDIT_PHASE: 'common:TOURNAMENTS_CHALLENGE_EDIT_PHASE',
  TOURNAMENTS_FINALES_LABEL: 'common:TOURNAMENTS_FINALES_LABEL',
  TOURNAMENTS_SEMI_FINALS_LABEL: 'common:TOURNAMENTS_SEMI_FINALS_LABEL',
  TOURNAMENTS_QUARTER_FINALS_LABEL: 'common:TOURNAMENTS_QUARTER_FINALS_LABEL',
  TOURNAMENTS_ROUND_OF_SIXTEEN_LABEL:
    'common:TOURNAMENTS_ROUND_OF_SIXTEEN_LABEL',
  TOURNAMENTS_NO_TOURNAMENTS: 'common:TOURNAMENTS_NO_TOURNAMENTS',
  TOURNAMENTS_RANKING_RULES_1: 'common:TOURNAMENTS_RANKING_RULES_1',
  TOURNAMENTS_RANKING_RULES_2: 'common:TOURNAMENTS_RANKING_RULES_2',
  TOURNAMENTS_RANKING_RULES_3: 'common:TOURNAMENTS_RANKING_RULES_3',
  TOURNAMENTS_RANKING_RULES_4: 'common:TOURNAMENTS_RANKING_RULES_4',
  TOURNAMENTS_RANKING_RULES_5: 'common:TOURNAMENTS_RANKING_RULES_5',
  TOURNAMENTS_RANKING_RULES_6: 'common:TOURNAMENTS_RANKING_RULES_6',

  TOURNAMENTS_PRESENTATION_TITLE: 'TOURNAMENTS_PRESENTATION_TITLE',
  TOURNAMENTS_PRESENTATION_HELPERTEXT: 'TOURNAMENTS_PRESENTATION_HELPERTEXT',
  TOURNAMENTS_PRESENTATION_NAME_HELPERTEXT:
    'TOURNAMENTS_PRESENTATION_NAME_HELPERTEXT',
  TOURNAMENTS_PRESENTATION_DESCRIPTION_HELPERTEXT:
    'TOURNAMENTS_PRESENTATION_DESCRIPTION_HELPERTEXT',
  TOURNAMENTS_PRESENTATION_LINK_HELPERTEXT:
    'TOURNAMENTS_PRESENTATION_LINK_HELPERTEXT',
  TOURNAMENTS_PRESENTATION_DURATION_TITLE:
    'TOURNAMENTS_PRESENTATION_DURATION_TITLE',
  TOURNAMENTS_PRESENTATION_DURATION_START:
    'TOURNAMENTS_PRESENTATION_DURATION_START',
  TOURNAMENTS_PRESENTATION_DURATION_END:
    'TOURNAMENTS_PRESENTATION_DURATION_END',
  TOURNAMENTS_PRESENTATION_CONTACT: 'TOURNAMENTS_PRESENTATION_CONTACT',
  TOURNAMENTS_PRESENTATION_EMAIL_HELPERTEXT:
    'TOURNAMENTS_PRESENTATION_EMAIL_HELPERTEXT',
  TOURNAMENTS_PRESENTATION_PHONE_HELPERTEXT:
    'TOURNAMENTS_PRESENTATION_PHONE_HELPERTEXT',
  TOURNAMENTS_PRESENTATION_PARTNERS: 'TOURNAMENTS_PRESENTATION_PARTNERS',

  // ERRORS

  ERROR_EMAIL_INVALID: 'common:ERROR_EMAIL_INVALID',
  ERROR_URL_INVALID: 'common:ERROR_URL_INVALID',
  ERROR_TOLONG_MAX5: 'common:ERROR_TOLONG_MAX5',
  ERROR_TOLONG_MAX10: 'common:ERROR_TOLONG_MAX10',
  ERROR_TOLONG_MAX20: 'common:ERROR_TOLONG_MAX20',
  ERROR_TOLONG_MAX56: 'common:ERROR_TOLONG_MAX56',
  ERROR_TOLONG_MAX256: 'common:ERROR_TOLONG_MAX256',
  ERROR_TOLONG_MAX1000: 'common:ERROR_TOLONG_MAX1000',
  ERROR_PHONENUMBER_TOLONG: 'common:ERROR_PHONENUMBER_TOLONG',
  ERROR_PHONENUMBER_REQUIRED: 'common:ERROR_PHONENUMBER_REQUIRED',
  ERROR_REQUIRED: 'common:ERROR_REQUIRED',
  NO_OPTIONS_TEXT: 'common:NO_OPTIONS_TEXT',

  // CLUBHOUSE

  CLUBHOUSE_SCREEN_DURATION: 'common:CLUBHOUSE_SCREEN_DURATION',
  CLUBHOUSE_SCREEN_SECONDS_LABEL: 'common:CLUBHOUSE_SCREEN_SECONDS_LABEL',

  // SUCCES

  SUCCESS_IMPORT: 'common:SUCCESS_IMPORT',
  SUCCESS_EXPORT: 'common:SUCCESS_EXPORT',
  SUCCESS_ADD: 'common:SUCCESS_ADD',
  SUCCESS_EDIT: 'common:SUCCESS_EDIT',
  SUCCESS_DELETE: 'common:SUCCESS_DELETE',
  SUCCESS_COPY: 'common:SUCCESS_COPY',

  // WARNING
  EDIT_NOT_FINISHED: 'common:EDIT_NOT_FINISHED',

  // INVITATIONS

  INVITATIONS_VERIFY_CODE: 'common:INVITATIONS_VERIFY_CODE',

  // DELETE
  DELETE_CONFIRMATION: 'common:DELETE_CONFIRMATION',
  DELETE_CONFIRMATION_PICTURE: 'common:DELETE_CONFIRMATION_PICTURE',

  // SEARCH

  SEARCH_TITLE: 'common:SEARCH_TITLE',
  SEARCH_EXPLANATION_TEXT: 'common:SEARCH_EXPLANATION_TEXT',
  SEARCH_TEAMS_TITLE: 'common:SEARCH_TEAMS_TITLE',
  SEARCH_TEAMS_HAS_ADMINS_EXPLANATION:
    'common:SEARCH_TEAMS_HAS_ADMINS_EXPLANATION',
  // PLANNER

  PLANNER_TEAM_NAME: 'common:PLANNER_TEAM_NAME',
  PLANNER_OPPOSING_TEAM: 'common:PLANNER_OPPOSING_TEAM',
  PLANNER_DATE: 'common:PLANNER_DATE',
  PLANNER_MATCH_TIME: 'common:PLANNER_MATCH_TIME',
  PLANNER_CONVOCATION_TIME: 'common:PLANNER_CONVOCATION_TIME',
  PLANNER_CONVOCATION: 'common:PLANNER_CONVOCATION',
  PLANNER_STADIUM: 'common:PLANNER_STADIUM',
  PLANNER_HOME: 'common:PLANNER_HOME',
  PLANNER_AWAY: 'common:PLANNER_AWAY',
  PLANNER_EXPORT_TITLE_WITH_DATE: 'common:PLANNER_EXPORT_TITLE_WITH_DATE',
  PLANNER_HOME_MATCHS: 'common:PLANNER_HOME_MATCHS',
  PLANNER_AWAY_MATCHS: 'common:PLANNER_AWAY_MATCHS',

  // TEAM IN AGREEMENT

  TEAM_IN_AGREEMENT_TITLE: 'common:TEAM_IN_AGREEMENT_TITLE',
  TEAM_IN_AGREEMENT_EXPLANATION_TEXT:
    'common:TEAM_IN_AGREEMENT_EXPLANATION_TEXT',
  TEAM_IN_AGREEMENT_DISCLAIMER_TEXT: 'common:TEAM_IN_AGREEMENT_DISCLAIMER_TEXT',
  TEAM_IN_AGREEMENT_REMOVE: 'common:TEAM_IN_AGREEMENT_REMOVE',

  BUTTON_SHOW_DOCUMENTATION: 'common:BUTTON_SHOW_DOCUMENTATION',

  ADMIN_DJANGO: 'common:ADMIN_DJANGO',

  INVOICE_STATUS_LATE: 'common:INVOICE_STATUS_LATE',
  INVOICE_STATUS_PAID: 'common:INVOICE_STATUS_PAID',
  INVOICE_STATUS_UPCOMING: 'common:INVOICE_STATUS_UPCOMING',
  INVOICE_STATUS_CANCELLED: 'common:INVOICE_STATUS_CANCELLED',

  // SEARCH MEMBERS

  IS_EXEMPT_VOLUNTEER: 'common:IS_EXEMPT_VOLUNTEER',
};

export type T_KEYS = typeof T_KEYS;
