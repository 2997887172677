import { ElementType } from 'react';

import { useTheme } from '@emotion/react';
import { Dialog, DialogProps, useMediaQuery } from '@mui/material';

type ModalLinkProps = Omit<DialogProps, 'open' | 'children' | 'key'> & {
  Modal: ElementType;
  modalProps?: any;
  closeModal: () => void;
  open: boolean;
};

export const ModalContainer = ({
  Modal,
  fullWidth = true,
  modalProps = {},
  closeModal,
  open,
  ...rest
}: ModalLinkProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      onClose={closeModal}
      open={open}
      sx={{
        zIndex: 1000,
      }}
      {...rest}
    >
      <Modal closeModal={closeModal} {...modalProps} />
    </Dialog>
  );
};
