// ------- WARN -------
// Cette configuration doit être chargée via next.config.js. Cela implique que :
// - il faut refaire un yarn dev pour voir ses modifications
// - on ne peut pas utiliser la syntaxe es6 ou typescript
// - on ne peut pas utiliser typescript
// - la syntaxe doit être compatible avec node:lts
// --------------------

module.exports = {
  Home: {
    // Page d'accueil
    page: '/',
    pathname: '/',
  },
  CognitoCallback: {
    // Point d'entrée après une connexion avec un service tiers (facebook, apple, ...)
    page: '/cognito-callback',
    pathname: '/cognito-callback',
  },
  Connect: {
    // Page pour l'authentification
    page: '/connect',
    pathname: '/connect',
  },
  Account: {
    // Page de profil
    page: '/account',
    pathname: '/account',
  },
  Dashboard: {
    // Page d'accueil d'un club
    page: '/:clubId',
    pathname: '/:clubId',
  },

  ClubInformations: {
    // Page permettant de renseigner les infos du club
    page: '/:clubId/club',
    pathname: '/:clubId/club',
  },
  ClubEquipments: {
    // Page permettant de renseigner les équipments du club
    page: '/:clubId/club/equipments',
    pathname: '/:clubId/club/equipments',
  },
  ClubBilling: {
    // Page permettant de gérer la facturation du club
    page: '/:clubId/club/billing',
    pathname: '/:clubId/club/billing',
  },
  Convocations: {
    // Page des convocations du club
    page: '/:clubId/convocations',
    pathname: '/:clubId/convocations',
  },
  ClubTasks: {
    // Page permettant de renseigner les types de convocations du club
    page: '/:clubId/convocations/tasks',
    pathname: '/:clubId/convocations/tasks',
  },
  Clubhouses: {
    // Page permettant d'afficher la liste des clubhouses pour un club",
    page: '/:clubId/club-house',
    pathname: '/:clubId/club-house',
  },
  Clubhouse: {
    // Page permettant de modifier un clubhouse
    page: '/:clubId/club-house/:clubhouseId',
    pathname: '/:clubId/club-house/:clubhouseId',
  },

  Community: {
    // Page permettant de rechercher un membre
    page: '/:clubId/community',
    pathname: '/:clubId/community',
  },
  CommunityAdministrators: {
    // Page permettant de lister les administrators
    page: '/:clubId/community/administrators',
    pathname: '/:clubId/community/administrators',
  },
  CommunityReporters: {
    // Page permettant de lister les reporters
    page: '/:clubId/community/reporters',
    pathname: '/:clubId/community/reporters',
  },
  CommunityGroups: {
    // Page permettant de modifier un groupe
    page: '/:clubId/community/groups',
    pathname: '/:clubId/community/groups',
  },
  CommunityMember: {
    // Page permettant de renseigner les infos d'un membre
    page: '/:clubId/community/:memberId',
    pathname: '/:clubId/community/:memberId',
  },
  CommunityMemberConvocations: {
    // Page permettant de voir les convocations d'un membre
    page: '/:clubId/community/:memberId/convocations',
    pathname: '/:clubId/community/:memberId/convocations',
  },
  CommunityExport: {
    // Page permettant voir les exports
    page: '/:clubId/community/exports',
    pathname: '/:clubId/community/exports',
  },

  Matchs: {
    page: '/:clubId/matchs',
    pathname: '/:clubId/matchs',
  },
  Match: {
    page: '/:clubId/matchs/:matchId',
    pathname: '/:clubId/matchs/:matchId',
  },
  MatchsPlanner: {
    page: '/:clubId/matchs/planner',
    pathname: '/:clubId/matchs/planner',
  },
  MatchsPlannerExport: {
    page: '/:clubId/matchs/planner/export',
    pathname: '/:clubId/matchs/planner/export',
  },

  Sponsors: {
    page: '/:clubId/sponsors',
    pathname: '/:clubId/sponsors',
  },

  Teams: {
    page: '/:clubId/teams',
    pathname: '/:clubId/teams',
  },
  Team: {
    page: '/:clubId/teams/:teamId',
    pathname: '/:clubId/teams/:teamId',
  },
  TeamPlayers: {
    page: '/:clubId/teams/:teamId/players',
    pathname: '/:clubId/teams/:teamId/players',
  },
  TeamRules: {
    page: '/:clubId/teams/:teamId/rules',
    pathname: '/:clubId/teams/:teamId/rules',
  },
  TeamWidgets: {
    page: '/:clubId/teams/:teamId/widgets',
    pathname: '/:clubId/teams/:teamId/widgets',
  },
  TeamConvocations: {
    // Page des convocations d'une équipe
    page: '/:clubId/teams/:teamId/convocations',
    pathname: '/:clubId/teams/:teamId/convocations',
  },

  Widgets: {
    page: '/:clubId/widgets',
    pathname: '/:clubId/widgets',
  },
  Widget: {
    page: '/:clubId/widgets/:widgetId',
    pathname: '/:clubId/widgets/:widgetId',
  },

  Search: {
    page: '/:clubId/search',
    pathname: '/:clubId/search',
  },

  Tournaments: {
    // Page des tournois
    page: '/:clubId/tournaments',
    pathname: '/:clubId/tournaments',
  },
  Tournament: {
    // Page d'un tournoi
    page: '/:clubId/tournaments/:tournamentId',
    pathname: '/:clubId/tournaments/:tournamentId',
  },

  Monsiteclub: {
    // Page publicité Monsiteclub
    page: '/:clubId/monsiteclub',
    pathname: '/:clubId/monsiteclub',
  },

  TournamentReporters: {
    // Page des reporters de tournoi
    page: '/:clubId/tournaments/:tournamentId/reporters',
    pathname: '/:clubId/tournaments/:tournamentId/reporters',
  },

  TournamentPlaces: {
    // Page des places de tournoi
    page: '/:clubId/tournaments/:tournamentId/places',
    pathname: '/:clubId/tournaments/:tournamentId/places',
  },

  TournamentScores: {
    // Page pour rentrer les scores de tournoi
    page: '/:clubId/tournaments/:tournamentId/scores',
    pathname: '/:clubId/tournaments/:tournamentId/scores',
  },

  TournamentStatistics: {
    // Page des statistiques de tournoi
    page: '/:clubId/tournaments/:tournamentId/statistics',
    pathname: '/:clubId/tournaments/:tournamentId/statistics',
  },

  Challenges: {
    // Page d'un tournoi - Challenges
    page: '/:clubId/tournaments/:tournamentId/challenges',
    pathname: '/:clubId/tournaments/:tournamentId/challenges',
  },
  Challenge: {
    // Page d'un challenge
    page: '/:clubId/tournaments/:tournamentId/challenges/:challengeId',
    pathname: '/:clubId/tournaments/:tournamentId/challenges/:challengeId',
  },
  ChallengeRules: {
    // Page d'un challenge - Rules
    page: '/:clubId/tournaments/:tournamentId/challenges/:challengeId/rules',
    pathname:
      '/:clubId/tournaments/:tournamentId/challenges/:challengeId/rules',
  },
  ChallengeTeams: {
    // Page d'un challenge - Teams
    page: '/:clubId/tournaments/:tournamentId/challenges/:challengeId/teams',
    pathname:
      '/:clubId/tournaments/:tournamentId/challenges/:challengeId/teams',
  },

  LienFederation: {
    // Page en lien avec la fédération
    page: '/:clubId/lien-federation',
    pathname: '/:clubId/lien-federation',
  },
  InformationsConvocation: {
    // Page de promotion des convocations
    page: '/:clubId/convocations/informations',
    pathname: '/:clubId/convocations/informations',
  },
  InformationsClubhouse: {
    // Page de promotion des clubhouses
    page: '/:clubId/club-house/informations',
    pathname: '/:clubId/club-house/informations',
  },
  InformationsTournament: {
    // Page de promotion des tournois
    page: '/:clubId/tournaments/informations',
    pathname: '/:clubId/tournaments/informations',
  },
  InformationsSponsors: {
    // Page de promotion des sponsors
    page: '/:clubId/sponsors/informations',
    pathname: '/:clubId/sponsors/informations',
  },

  News: {
    // Page des news de club
    page: '/:clubId/news',
    pathname: '/:clubId/news',
  },
};
