import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { GoogleMapsInput, Modal } from '@scorenco/components';
import { useTranslation, useEquipmentAdd } from '@scorenco/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { object, string } from 'yup';

import { useCurrentClubContext } from '@/layouts';
import { T_KEYS } from '@/translations';

const addClubEquipmentSchema = object().shape({
  name: string()
    .max(56, T_KEYS.ERROR_TOLONG_MAX56)
    .required(T_KEYS.ERROR_REQUIRED),
  address: string().required(T_KEYS.ERROR_REQUIRED),
});

export const AddClubEquipment = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const { currentClub } = useCurrentClubContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const equipmentAddMutation = useEquipmentAdd();

  const formik = useFormik({
    initialValues: {
      clubId: currentClub.id,
      name: '',
      address: '',
      equipment_type: 'headoffice',
    },
    validationSchema: addClubEquipmentSchema,
    onSubmit: async (values) => {
      try {
        await equipmentAddMutation.execute({
          clubId: currentClub.id,
          name: values.name,
          address: values.address,
          equipment_type: values.equipment_type,
        });
        closeModal();
        enqueueSnackbar(t(T_KEYS.SUCCESS_ADD), {
          variant: 'success',
        });
        formik.resetForm();
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Modal
      closeModal={closeModal}
      title={t(T_KEYS.ADDCLUBEQUIPMENT_BUTTON)}
      onSubmit={formik.handleSubmit}
    >
      <DialogContent>
        <TextField
          fullWidth
          id="name"
          name="name"
          autoFocus
          required
          label={t(T_KEYS.ADDCLUBEQUIPMENT_NAME)}
          autoComplete="off"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && t(formik.errors.name)}
          sx={{ my: 2 }}
          slotProps={{
            htmlInput: { maxLength: 56 },
          }}
        />

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Type de lieu</InputLabel>
          <Select
            id="equipment_type"
            name="equipment_type"
            value={formik.values.equipment_type}
            label="Type de lieu"
            onChange={formik.handleChange}
            fullWidth
          >
            <MenuItem value="headoffice">Siège</MenuItem>
            <MenuItem value="play">Stade / gymnase</MenuItem>
            <MenuItem value="premises">Lieu de vie</MenuItem>
          </Select>
        </FormControl>

        <GoogleMapsInput
          noOptionsText={t(T_KEYS.NO_OPTIONS_TEXT)}
          loadingText={t(T_KEYS.LOADING_TEXT)}
          fullWidth
          id="address"
          label={t(T_KEYS.COMMUNITY_MEMBERS_LABEL_POSTADRESS)}
          onChange={(newValue) => {
            formik.setFieldValue('address', newValue);
          }}
          value={formik.values.address}
          error={formik.touched.address && !!formik.errors.address}
          helperText={formik.touched.address && t(formik.errors.address)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={equipmentAddMutation.status.loading}
        >
          {t(T_KEYS.ADD)}
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
};
