import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import {
  UpdateChallengeDocument,
  UpdateChallengeMutationVariables,
} from './Tournament.mutation.generated';

export const useUpdateChallenge = () => {
  const [runMutation, status] = useMutation(UpdateChallengeDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: UpdateChallengeMutationVariables) => {
      return runMutation({
        variables,
        update: (cache, res) => {
          const challenge = res.data?.update_tournaments_challenge_by_pk;

          if (challenge) {
            cache.writeFragment({
              id: `tournaments_challenge:${challenge.id}`,
              fragment: gql`
                fragment UpdateChallenge on tournaments_challenge {
                  id
                  name
                  color
                }
              `,
              data: {
                ...challenge,
              },
            });
          }
        },
      });
    },
    [runMutation]
  );

  return { execute, status };
};
