import { useTheme, css } from '@emotion/react';
import { Slide, SlideProps } from '@mui/material';
import { appWithTheme, DatePickersProvider } from '@scorenco/components';
import {
  ApolloService,
  SentryService,
  CrispService,
  useTranslation,
} from '@scorenco/core';
import { AppLayout, AppPropsWithLayout, DefaultLayout } from '@scorenco/next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import i18nextHttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import { SnackbarProvider } from 'notistack';

import { T_KEYS } from '@/translations';

import { routerConfig } from '../routes';

const isBrowser = typeof window !== 'undefined';

DefaultLayout.init({
  i18n: {
    i18n: {
      defaultLocale: 'fr',
      locales: ['fr', 'de'],
    },
    ns: ['snc-components', 'snc-core', 'common'],
    backend: {
      backendOptions: [{ expirationTime: 60 * 60 * 1000 }], // 1 hour
      backends: isBrowser ? [LocalStorageBackend, i18nextHttpBackend] : [],
    },
    detection: {
      order: [
        'path',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
      ],
    },
    use: isBrowser ? [LanguageDetector, ChainedBackend] : [],
    clientOnly: true,
  },
  router: {
    baseUrl: process.env.NEXT_PUBLIC_BACKOFFICE_URL,
    config: routerConfig,
  },
  amplify: {},
});

CrispService.init();

const StyledStyle = (props: SlideProps) => (
  <div
    css={(theme) => css`
      .notistack-MuiContent-success {
        background-color: ${theme.vars.palette.success.main};
        color: ${theme.vars.palette.success.contrastText};
      }
      .notistack-MuiContent-info {
        background-color: ${theme.vars.palette.info.main};
        color: ${theme.vars.palette.info.contrastText};
      }
      .notistack-MuiContent-error {
        background-color: ${theme.vars.palette.error.main};
        color: ${theme.vars.palette.error.contrastText};
      }
      .notistack-MuiContent-warning {
        background-color: ${theme.vars.palette.warning.main};
        color: ${theme.vars.palette.warning.contrastText};
      }
    `}
  >
    <Slide {...props} />
  </div>
);

const App = (appProps: AppPropsWithLayout) => {
  const { pageProps } = appProps;
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <SentryService>
      <CrispService>
        <ApolloService initialApolloState={pageProps.initialApolloState}>
          <NextNProgress color={theme.vars.palette.secondary.main} />
          <Head>
            <title>{t(T_KEYS.DEFAULT_HTML_TITLE)}</title>
            <script async src="https://tally.so/widgets/embed.js"></script>
          </Head>
          <DatePickersProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              maxSnack={6}
              TransitionComponent={StyledStyle}
            >
              <AppLayout {...appProps} />
            </SnackbarProvider>
          </DatePickersProvider>
        </ApolloService>
      </CrispService>
    </SentryService>
  );
};

// if (typeof window !== 'undefined') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const pwaLoader = require('@ionic/pwa-elements/loader');
//   pwaLoader.defineCustomElements(window);
// }

export default appWithTheme(DefaultLayout.appEnhancer(App));
