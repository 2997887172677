import { css } from '@emotion/react';
import { Logout, Settings } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  RouteLink,
  useSignOut,
  useTranslation,
  useAmplifyServiceContext,
} from '@scorenco/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { RouteKey } from '@/routes';
import { T_KEYS } from '@/translations';

export const BackofficeLayoutUserMenu = () => {
  const { t } = useTranslation();
  const { signOut } = useSignOut();
  const { user } = useAmplifyServiceContext();

  if (!user) {
    return <div />;
  }

  return (
    <PopupState variant="popover" popupId="authMenu">
      {(popupState) => (
        <>
          <Avatar
            src={user.info.picture_url}
            sx={{
              width: 40,
              height: 40,
              cursor: 'pointer',
              objectFit: 'cover',
            }}
            {...bindTrigger(popupState)}
          />

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            css={css`
              margin-top: 0.5rem;
            `}
          >
            <List sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <Avatar
                    src={user.info.picture_url}
                    sx={{
                      width: 36,
                      height: 36,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={user.info.username}
                  secondary={[user.info.first_name, user.info.last_name]
                    .filter(Boolean)
                    .join(' ')}
                />
              </ListItem>
            </List>

            <Divider />

            <List sx={{ width: '100%' }}>
              <RouteLink href={{ routeKey: RouteKey.Account }} passHref>
                <ListItemButton onClick={popupState.close}>
                  <ListItemIcon sx={{ color: 'currentColor' }}>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary={t(T_KEYS.MENU_MODIFY_PROFIL)} />
                </ListItemButton>
              </RouteLink>
              <ListItemButton
                onClick={() => {
                  signOut();
                  popupState.close();
                }}
              >
                <ListItemIcon sx={{ color: 'currentColor' }}>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={t(T_KEYS.SIGN_OUT)} />
              </ListItemButton>
            </List>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
