import { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';

import { getRefreshToken } from '../../services/amplify/getRefreshToken';

import {
  UserCheckInvitationDocument,
  UserCheckInvitationMutationVariables,
} from './User.mutation.generated';

export const useUserVerifyInvitationCode = () => {
  const [runMutation, mutationStatus] = useMutation(
    UserCheckInvitationDocument
  );
  const [loading, setLoading] = useState(mutationStatus.loading);

  const execute = useCallback(
    async (variables: UserCheckInvitationMutationVariables) => {
      let result;
      try {
        setLoading(true);
        result = await runMutation({
          variables,
          refetchQueries: ['GetInvitations', 'GetClubs'],
        });
        await getRefreshToken(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
      return result;
    },
    [runMutation, setLoading]
  );

  const status = useMemo(
    () => ({ ...mutationStatus, loading }),
    [mutationStatus, loading]
  );

  return { execute, status };
};
