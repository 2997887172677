/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ScrapingGetNewRoundsMutationVariables = Types.Exact<{
  competitionId:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
}>;

export type ScrapingGetNewRoundsMutation = {
  __typename: 'mutation_root';
  scraping_get_new_rounds?:
    | {
        __typename: 'scraping_get_new_rounds_mutation_response';
        scraping_launched?: any | undefined;
      }
    | undefined;
};

export type ScrapingSynchronizeEventMutationVariables = Types.Exact<{
  eventId: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;

export type ScrapingSynchronizeEventMutation = {
  __typename: 'mutation_root';
  scraping_synchronize_event?:
    | {
        __typename: 'scraping_launched_mutation_response';
        scraping_launched?: any | undefined;
      }
    | undefined;
};

export type ScrapingSynchronizeCompetitionMutationVariables = Types.Exact<{
  competitionId:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
}>;

export type ScrapingSynchronizeCompetitionMutation = {
  __typename: 'mutation_root';
  scraping_synchronize_competition?:
    | {
        __typename: 'scraping_synchronize_competition_mutation_response';
        scraping_launched?: any | undefined;
      }
    | undefined;
};

export type ScrapingGetNewCompetitionsMutationVariables = Types.Exact<{
  clubId: Types.Scalars['Int']['input'];
}>;

export type ScrapingGetNewCompetitionsMutation = {
  __typename: 'mutation_root';
  scraping_get_new_competitions?:
    | {
        __typename: 'scraping_get_new_competitions_mutation_response';
        scraping_launched?: any | undefined;
      }
    | undefined;
};

export type ScrapingUpdateNextRoundsMutationVariables = Types.Exact<{
  clubId: Types.Scalars['Int']['input'];
}>;

export type ScrapingUpdateNextRoundsMutation = {
  __typename: 'mutation_root';
  scraping_update_next_rounds?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type CompetitionSpiderMutationVariables = Types.Exact<{
  championship_id: Types.Scalars['Int']['input'];
}>;

export type CompetitionSpiderMutation = {
  __typename: 'mutation_root';
  crawlers_crawl_competition_spider?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type ScrapingGetCompetitionsMissingResultsMutationVariables =
  Types.Exact<{
    club_id: Types.Scalars['Int']['input'];
  }>;

export type ScrapingGetCompetitionsMissingResultsMutation = {
  __typename: 'mutation_root';
  competitions_missing_results_last_week_for_club?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type ScrapingSynchronizeEventsThisWeekMutationVariables = Types.Exact<{
  club_id: Types.Scalars['Int']['input'];
  start_date?: Types.InputMaybe<Types.Scalars['String']['input']>;
  end_date?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type ScrapingSynchronizeEventsThisWeekMutation = {
  __typename: 'mutation_root';
  competitions_synchronize_events_this_week_for_club?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export const ScrapingGetNewRoundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScrapingGetNewRounds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'competitionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scraping_get_new_rounds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'competition_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'competitionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scraping_launched' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScrapingGetNewRoundsMutation,
  ScrapingGetNewRoundsMutationVariables
>;
export const ScrapingSynchronizeEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScrapingSynchronizeEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scraping_synchronize_event' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'event_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scraping_launched' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScrapingSynchronizeEventMutation,
  ScrapingSynchronizeEventMutationVariables
>;
export const ScrapingSynchronizeCompetitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScrapingSynchronizeCompetition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'competitionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scraping_synchronize_competition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'competition_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'competitionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scraping_launched' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScrapingSynchronizeCompetitionMutation,
  ScrapingSynchronizeCompetitionMutationVariables
>;
export const ScrapingGetNewCompetitionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScrapingGetNewCompetitions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scraping_get_new_competitions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clubId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scraping_launched' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScrapingGetNewCompetitionsMutation,
  ScrapingGetNewCompetitionsMutationVariables
>;
export const ScrapingUpdateNextRoundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScrapingUpdateNextRounds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scraping_update_next_rounds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clubId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScrapingUpdateNextRoundsMutation,
  ScrapingUpdateNextRoundsMutationVariables
>;
export const CompetitionSpiderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CompetitionSpider' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'championship_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crawlers_crawl_competition_spider' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'championship_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'championship_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompetitionSpiderMutation,
  CompetitionSpiderMutationVariables
>;
export const ScrapingGetCompetitionsMissingResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScrapingGetCompetitionsMissingResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'club_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'competitions_missing_results_last_week_for_club',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'club_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScrapingGetCompetitionsMissingResultsMutation,
  ScrapingGetCompetitionsMissingResultsMutationVariables
>;
export const ScrapingSynchronizeEventsThisWeekDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ScrapingSynchronizeEventsThisWeek' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'club_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'competitions_synchronize_events_this_week_for_club',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'club_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start_date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start_date' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end_date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end_date' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScrapingSynchronizeEventsThisWeekMutation,
  ScrapingSynchronizeEventsThisWeekMutationVariables
>;
