import { css } from '@emotion/react';
import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from '@scorenco/core';
import { connectSearchBox } from 'react-instantsearch-dom';

import { T_KEYS } from '../../translations';

type SearchBarProps = {
  refine: (...args: any[]) => any;
  currentRefinement: string;
  isSearchStalled: boolean;
  createURL?: ((...args: any[]) => any) | undefined;
  indexContextValue?: any;
};

const SearchBar = ({
  currentRefinement,
  refine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSearchStalled,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  indexContextValue,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createURL,
  ...rest
}: SearchBarProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      css={(theme) => css`
        background-color: ${theme.vars.palette.background.paper};
      `}
      value={currentRefinement}
      onChange={(e) => {
        refine(e.target.value);
      }}
      label={t(T_KEYS.SEARCH_PLACEHOLDER)}
      {...rest}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export const AlgoliaSearchBar = connectSearchBox(
  SearchBar
) as unknown as typeof TextField;
