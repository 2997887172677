import { Suspense, useCallback } from 'react';

import { css } from '@emotion/react';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Popover,
  InputAdornment,
  TextField,
  TextFieldProps,
  Skeleton,
} from '@mui/material';
import { Stack } from '@mui/system';
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import dynamic from 'next/dynamic';

const EmojiPicker = dynamic(() => import('./EmojiPicker'), { ssr: false });

export type SelectEmojiProps = TextFieldProps & {
  popupId: string;
};

export const SelectEmoji = ({
  label,
  popupId,
  onChange,
  value,
  ...rest
}: SelectEmojiProps) => {
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    disableAutoFocus: true,
  });

  const onPickerChange = useCallback(
    (emoji: any) => {
      if (onChange) {
        onChange(emoji.native);
      }
    },
    [onChange]
  );

  return (
    <>
      <TextField
        label={label}
        value={value}
        onChange={() => {
          // Do nothing
        }}
        {...bindToggle(popupState)}
        {...rest}
        slotProps={{
          input: {
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <KeyboardArrowDown />
              </InputAdornment>
            ),
          },
        }}
      />
      <>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          css={css`
            margin-top: 0.5rem;
          `}
        >
          <Suspense
            fallback={
              <Stack minWidth={350} p={1} gap={1}>
                <Skeleton width="100%" variant="rounded" height={32} />
                <Skeleton width="100%" variant="rounded" height={250} />
              </Stack>
            }
          >
            {popupState.open && <EmojiPicker onEmojiSelect={onPickerChange} />}
          </Suspense>
        </Popover>
      </>
    </>
  );
};
