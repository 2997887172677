export enum ActionType {
  GoalStop = 'goal_stop',
  TimeOut = 'time_out',
  RemainingTime = 'remaining_time',
  PlayedTime = 'played_time',
  Substitution = 'substitution',
  Foul = 'foul',
  TeamFoul = 'team_foul',
  YellowCard = 'yellow_card',
  RedCard = 'red_card',
  BlueCard = 'blue_card',
  WhiteCard = 'white_card',
  Suspension2Min = 'suspension_2_min',
  Suspension5Min = 'suspension_5_min',
  Suspension10Min = 'suspension_10_min',
  Goal = 'goal',
  PenaltyScored = 'penalty_scored',
  ShootoutScored = 'shootout_scored',
  Try = 'try',
  PenaltyTry = 'penalty_try',
  CollectiveTry = 'collective_try',
  Conversion = 'conversion',
  Drop = 'drop',
  TwoPoints = '2pts',
  ThreePoints = '3pts',
  FreeThrow = 'free_throw',
  SevenMeterShot = 'seven_meter',
  Update = 'update',
  OnePeriod = 'one-period',
  Half = 'half',
  Third = 'third',
  Quarter = 'quarter',
  ExtraTime = 'extra',
  Set = 'set',
  ShootOut = 'shootout',
  EndGame = 'end_game',
  FreeKick = 'free_kick',
}

export enum Sport {
  Baseball = 'baseball',
  Basket = 'basket',
  Football = 'football',
  Hand = 'hand',
  IceHockey = 'hockey-sur-glace',
  RinkHockey = 'rink-hockey',
  RollerHockey = 'roller-hockey',
  Rugby = 'rugby',
  Volley = 'volley',
}

export enum ImageUsage {
  ClubLogo = 1,
  ProfilePicture = 2,
  ClubHouse = 3,
  Sponsor = 4,
  News = 5,
  PlayerPhoto = 6,
  TournamentTeamLogo = 7,
  SquadPicture = 8,
  SharePictureBackground = 9,
  LivePostPicture = 10,
  TeamLogo = 11,
  TournamentLogo = 12,
  TournamentAd = 13,
}

// export enum TournamentGameTypes {
//   FINALE = 1,
//   DEMI = 2,
//   PETITE_FINALE = 3,
//   QUART = 4,
//   MATCH_5_8 = 5,
//   MATCH_5_6 = 6,
//   MATCH_7_8 = 7,
//   POOL = 8,
// }

export enum FileUsage {
  ImportMembers = 1,
}

export enum ScreenTemplate {
  Team = 1,
  Sponsors = 2,
  ClubTitle = 3,
  IncomingEvents = 4,
  News = 5,
  Facebook = 6,
  TournamentPhase = 7,
  TournamentLives = 8,
  TournamentAdScorenco = 9,
  TournamentAddChallengeRanking = 12,
  TournamentEliminationPhase = 13,
}

export enum SportType {
  Volley = 15,
  TouchRugby = 16,
  Football = 17,
  Basket = 18,
  BaseballSoftball = 19,
  RinkHockey = 20,
  Handball = 21,
  HockeySurGlace = 22,
  RollerHockey = 23,
  Rugby = 24,
  Futsal = 25,
  Foot7 = 26,
  FootEntreprise = 27,
  BeachSoccer = 28,
  Baseball = 29,
  Softball = 30,
  BeachVolley = 31,
  HandAdapte = 32,
  HandFauteuil = 33,
  Rugby5 = 34,
  Rugby7 = 35,
  Rugby12 = 36,
  BeachRugby = 37,
  ClubsSamedi = 38,
  HandiBasket = 39,
  Basket3X3 = 40,
}
