import { useLazyQuery } from '@apollo/client';

import { CalculateRouteTimeDocument } from './MatchRowForm.query.generated';

export const usePlacesCalculateRouteTime = ({
  place_id_from,
  place_id_to,
}: {
  place_id_from: number;
  place_id_to: number;
}) => {
  const calculateRouteTime = useLazyQuery(CalculateRouteTimeDocument, {
    context: {
      role: 'club',
    },
    variables: {
      place_id_from,
      place_id_to,
    },
  });

  return calculateRouteTime;
};
