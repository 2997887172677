import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { GoogleMapsInput, Modal } from '@scorenco/components';
import { useTranslation, useEquipmentUpdate } from '@scorenco/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { string, object } from 'yup';

import { T_KEYS } from '@/translations';

const addClubEquipmentSchema = object().shape({
  name: string()
    .max(56, T_KEYS.ERROR_TOLONG_MAX56)
    .required(T_KEYS.ERROR_REQUIRED),
  address: string().required(T_KEYS.ERROR_REQUIRED),
});

export const UpdateClubEquipment = ({
  closeModal,
  equipment,
}: {
  closeModal: () => void;
  equipment: any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const equipmentUpdateMutation = useEquipmentUpdate();

  const formik = useFormik({
    initialValues: {
      id: equipment?.id,
      name: equipment?.name,
      address: equipment?.address,
      equipment_type: equipment?.equipment_type,
    },
    validationSchema: addClubEquipmentSchema,
    onSubmit: async (values) => {
      try {
        await equipmentUpdateMutation.execute({
          id: equipment?.id,
          name: values.name,
          address: values.address,
          equipment_type: values.equipment_type,
        });
        closeModal();
        enqueueSnackbar(t(T_KEYS.SUCCESS_ADD), {
          variant: 'success',
        });
        formik.resetForm();
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Modal
      closeModal={closeModal}
      title="Modifier l'équipement"
      onSubmit={formik.handleSubmit}
    >
      <DialogContent>
        <TextField
          fullWidth
          id="name"
          name="name"
          autoFocus
          required
          label={t(T_KEYS.ADDCLUBEQUIPMENT_NAME)}
          autoComplete="off"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && t(formik.errors.name as string)}
          sx={{ my: 2 }}
          slotProps={{
            htmlInput: { maxLength: 80 },
          }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Type de lieu</InputLabel>
          <Select
            id="equipment_type"
            name="equipment_type"
            value={formik.values.equipment_type}
            label="Type de lieu"
            onChange={formik.handleChange}
            fullWidth
          >
            <MenuItem value="headoffice">Siège</MenuItem>
            <MenuItem value="play">Stade / gymnase</MenuItem>
            <MenuItem value="premises">Lieu de vie</MenuItem>
          </Select>
        </FormControl>

        <GoogleMapsInput
          noOptionsText={t(T_KEYS.NO_OPTIONS_TEXT)}
          loadingText={t(T_KEYS.LOADING_TEXT)}
          fullWidth
          id="address"
          label={t(T_KEYS.COMMUNITY_MEMBERS_LABEL_POSTADRESS)}
          onChange={(newValue) => {
            formik.setFieldValue('address', newValue);
          }}
          value={formik.values.address}
          error={formik.touched.address && !!formik.errors.address}
          helperText={
            formik.touched.address && t(formik.errors.address as string)
          }
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={equipmentUpdateMutation.status.loading}
        >
          {t(T_KEYS.EDIT)}
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
};
