import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from '@mui/material';
import { ModalTitle } from '@scorenco/components';

export const ClubNotification = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Notification aux fans
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <Box sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>
          <ModalTitle onClose={handleClose}>
            Envoyer une notification sur le téléphone des fans de votre club
          </ModalTitle>
          <DialogContent sx={{ p: 2 }}></DialogContent>
          <DialogActions sx={{ m: 0, p: 1, justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              Envoyer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
