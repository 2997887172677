// @ts-expect-error iframe-resizer n'a pas de types à jour disponible
import iframeResize from 'iframe-resizer/js/iframeResizer';

import { Types } from '@/types';

const WIDGET_CLASSNAME = 'scorenco-widget';
const WIDGET_ID_PROP = 'data-widget-id';
const WIDGET_TYPE_PROP = 'data-widget-type';

const cleanLanguage = (lang?: string) =>
  lang && lang.toLowerCase().trim().split(/[-_]/)?.[0];

const getHostLocale = () => {
  const defaultLocale = 'fr';
  const locales = ['fr', 'de'];
  const languages = [];

  // Get locale from user navigator settings
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) {
      // chrome only; not an array, so can't use .push.apply instead of iterating
      for (let i = 0; i < navigator.languages.length; i++) {
        languages.push(cleanLanguage(navigator.languages[i]));
      }
    }

    // @ts-expect-error add userLanguage option
    if (navigator.userLanguage) {
      // @ts-expect-error add userLanguage option
      languages.push(cleanLanguage(navigator.userLanguage));
    }

    if (navigator.language) {
      languages.push(cleanLanguage(navigator.language));
    }
  }

  // Recherche la première locale correspondante
  const detectedLocale = languages.find((language) =>
    locales.find((locale) => locale === language)
  );

  return detectedLocale || defaultLocale;
};

export class WidgetClient {
  ids: string[];

  constructor() {
    this.ids = [];
  }

  parseDocument() {
    // Récupération des widgets présents sur la page
    const widgets = document.getElementsByClassName(WIDGET_CLASSNAME);
    const locale = getHostLocale();

    let index = 0;
    while (widgets.length) {
      const element = widgets.item(index);

      if (!element) {
        break;
      }

      // Récuperation des informations du widget
      const widgetId = element.getAttribute(WIDGET_ID_PROP);
      if (!widgetId) {
        console.warn('Widget id is missing in scorenco widget');
        break;
      }

      const widgetType = element.getAttribute(WIDGET_TYPE_PROP);
      if (!widgetType) {
        console.warn('Widget type is missing in scorenco widget');
        break;
      }

      const id = `${widgetType}-${widgetId}-${index}`;

      const iframeSrc =
        widgetType === 'city-score'
          ? `${process.env.NEXT_PUBLIC_CITY_SCORE_URL}/widgets/city/${widgetId}`
          : `${process.env.NEXT_PUBLIC_WIDGETS_URL}/${locale}/${widgetType}/${widgetId}?noCache=true`;

      // Création de l'iframe qui est cachée tant qu'elle n'a pas été initialiser
      const iframe: HTMLIFrameElement = document.createElement('iframe');
      iframe.id = id;
      iframe.name = id;
      iframe.className = 'scorenco-widget-iframe';
      iframe.src = iframeSrc;
      iframe.style.display = 'block';
      iframe.style.borderWidth = '0';
      iframe.style.width = '100%';
      iframe.style.overflow = 'auto';
      iframe.style.margin = 'auto';
      iframe.style.visibility = 'hidden';
      iframe.style.position = 'absolute';
      iframe.style.transform = 'none';
      iframe.width = '1000px';
      iframe.height = '1000px';
      element.parentNode?.insertBefore(iframe, element);

      // Initialisation du resize automatique
      iframeResize(
        {
          log: false,
          checkOrigin: false,
          onInit: (iframe: HTMLIFrameElement) => {
            iframe.style.visibility = 'initial';
            iframe.style.position = 'initial';
            element.parentNode?.removeChild(element);
            this.ids.push(id);
          },
          onMessage: () => {
            // TODO
          },
        },
        `#${id}`
      );

      index++;
    }
  }

  updateWidget(widget: Types.Widgets_Widget) {
    if (this.ids?.[0]) {
      const iframe = document.getElementById(this.ids[0]) as any;
      iframe?.iFrameResizer.sendMessage({
        type: 'WIDGET_UPDATE',
        payload: widget,
      });
    }
  }
}
