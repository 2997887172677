import { Close } from '@mui/icons-material';
import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Typography,
} from '@mui/material';

type ModalTitleProps = DialogTitleProps & {
  onClose: () => void;
};

export const ModalTitle = (props: ModalTitleProps) => {
  const { children, onClose, ...rest } = props;

  return (
    <DialogTitle
      sx={{ display: 'flex', alignItems: 'center', m: 0, pl: 2, pr: 1 }}
      {...rest}
    >
      <Typography
        variant="h4"
        component="span"
        sx={{
          textTransform: 'uppercase',
          lineHeight: 1,
          flex: 1,
        }}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          size="large"
          onClick={onClose}
          sx={(theme) => ({
            color: theme.vars.palette.primary.main,
            m: '0.5rem',
          })}
        >
          <Close fontSize="inherit" />
        </IconButton>
      )}
    </DialogTitle>
  );
};
