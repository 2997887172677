import { PropsWithChildren, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import {
  getAlgoliaIndexName,
  searchClient,
  algoliaRefreshCacheVar,
} from '@scorenco/core';
import { InstantSearch } from 'react-instantsearch-dom';

import { AlgoliaConfigure } from './AlgoliaConfigure';
import { AlgoliaContext, useInitializeContext } from './AlgoliaContext';

export type AlgoliaProps = PropsWithChildren<{
  indexName: string;
  useCountry?: boolean;
  hitsPerPage?: number;
  clearCacheOnMount?: boolean;
  clubSecuredApiKey?: string;
}>;

// Cache global pour stocker les résultats des requêtes
const searchCache = new Map<string, any>();
const clientsCache = new Map<string, any>();

// Créer un client statique pour éviter les recréations inutiles
const createStaticClient = (key?: string) => {
  // Si pas de clé fournie, créer un client standard
  if (!key) {
    return searchClient();
  }

  // Vérification stricte de la clé si elle est fournie
  if (key.trim() === '') return null;

  // Réutiliser le client existant si possible
  if (clientsCache.has(key)) {
    return clientsCache.get(key);
  }

  const client = searchClient(key);
  const enhancedClient = {
    ...client,
    search: async (requests: any[]) => {
      const indexId = requests[0]?.indexName;
      const params = requests[0]?.params || {};
      // Créer une clé de cache qui inclut tous les paramètres de recherche
      const cacheKey = JSON.stringify({
        indexId,
        params,
        key,
      });

      // Vérifier si nous avons un résultat en cache
      if (searchCache.has(cacheKey)) {
        return searchCache.get(cacheKey);
      }

      // Faire la requête et mettre en cache
      const result = await client.search(requests);
      searchCache.set(cacheKey, result);
      return result;
    },
  };

  clientsCache.set(key, enhancedClient);
  return enhancedClient;
};

// Nettoyer le cache périodiquement (toutes les 2 minutes pour être sûr)
setInterval(
  () => {
    searchCache.clear();
  },
  2 * 60 * 1000
);

export const Algolia = ({
  indexName,
  useCountry,
  hitsPerPage,
  clearCacheOnMount,
  children,
  clubSecuredApiKey,
  ...rest
}: AlgoliaProps) => {
  const refresh = useReactiveVar(algoliaRefreshCacheVar);
  const providerValue = useInitializeContext({ clearCacheOnMount });

  const algoliaClient = useMemo(() => {
    // Si pas de clé fournie, utiliser le client standard
    if (!clubSecuredApiKey) {
      return createStaticClient();
    }

    // Vérification stricte de la clé si elle est fournie
    if (clubSecuredApiKey.trim() === '') return null;
    return createStaticClient(clubSecuredApiKey);
  }, [clubSecuredApiKey]);

  // Ne pas rendre si le client n'a pas pu être créé
  if (!algoliaClient) {
    return null;
  }

  return (
    <AlgoliaContext.Provider value={providerValue}>
      <InstantSearch
        indexName={getAlgoliaIndexName(indexName, useCountry)}
        searchClient={algoliaClient}
        refresh={refresh}
        {...rest}
      >
        <>
          <AlgoliaConfigure hitsPerPage={hitsPerPage} />
          {children}
        </>
      </InstantSearch>
    </AlgoliaContext.Provider>
  );
};
