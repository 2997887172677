/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@scorenco/core';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetTeamRulesPlannerQueryVariables = Types.Exact<{
  teamId: Types.Scalars['Int']['input'];
}>;

export type GetTeamRulesPlannerQuery = {
  __typename: 'query_root';
  teams_team_by_pk?:
    | {
        __typename: 'teams_team';
        id: number;
        meeting_before_event?: any | undefined;
        default_equipment_id?: number | undefined;
        seasons?:
          | Array<{
              __typename: 'teams_team_in_season';
              id: number;
              members?:
                | Array<{
                    __typename: 'teams_team_in_season_has_member';
                    id: number;
                    is_staff?: boolean | undefined;
                    captain?: boolean | undefined;
                    member?:
                      | {
                          __typename: 'members_member';
                          id: number;
                          first_name?: string | undefined;
                          last_name?: string | undefined;
                        }
                      | undefined;
                  }>
                | undefined;
            }>
          | undefined;
        team_tasks?:
          | Array<{
              __typename: 'tasks_team_task';
              id: number;
              task_id?: number | undefined;
              officials?: boolean | undefined;
            }>
          | undefined;
      }
    | undefined;
};

export type GetEventPlayingTeamInfosQueryVariables = Types.Exact<{
  teamInfoId: Types.Scalars['Int']['input'];
  isHome: Types.Scalars['Boolean']['input'];
}>;

export type GetEventPlayingTeamInfosQuery = {
  __typename: 'query_root';
  competitions_playing_team_info_by_pk?:
    | {
        __typename: 'competitions_playing_team_info';
        id: number;
        meeting_time?: any | undefined;
        status?: boolean | undefined;
        date?: any | undefined;
        time?: any | undefined;
        private_comment?: string | undefined;
        public_comment?: string | undefined;
        travel_duration?: any | undefined;
        equipment?:
          | {
              __typename: 'places_equipment';
              id: number;
              name?: string | undefined;
            }
          | undefined;
        playing_team?:
          | {
              __typename: 'competitions_playing_team';
              id: number;
              team_in_season?:
                | {
                    __typename: 'teams_team_in_season';
                    id: number;
                    members?:
                      | Array<{
                          __typename: 'teams_team_in_season_has_member';
                          id: number;
                          is_staff?: boolean | undefined;
                          captain?: boolean | undefined;
                          member?:
                            | {
                                __typename: 'members_member';
                                id: number;
                                first_name?: string | undefined;
                                last_name?: string | undefined;
                              }
                            | undefined;
                        }>
                      | undefined;
                    team?:
                      | {
                          __typename: 'teams_team';
                          id: number;
                          meeting_before_event?: any | undefined;
                          team_tasks?:
                            | Array<{
                                __typename: 'tasks_team_task';
                                id: number;
                                officials?: boolean | undefined;
                                task_id?: number | undefined;
                              }>
                            | undefined;
                        }
                      | undefined;
                  }
                | undefined;
            }
          | undefined;
        jobs?:
          | Array<{
              __typename: 'tasks_job';
              id: number;
              task?:
                | {
                    __typename: 'tasks_task';
                    id: number;
                    name?: string | undefined;
                    emoji?: string | undefined;
                  }
                | undefined;
              volunteers?:
                | Array<{
                    __typename: 'tasks_volunteer';
                    id: number;
                    message?:
                      | {
                          __typename: 'emails_message';
                          id: number;
                          has_error?: boolean | undefined;
                          events?:
                            | Array<{
                                __typename: 'emails_event';
                                id: number;
                                event?: string | undefined;
                                date?: any | undefined;
                              }>
                            | undefined;
                        }
                      | undefined;
                    message_sms?:
                      | {
                          __typename: 'sms_message';
                          id: number;
                          has_error?: boolean | undefined;
                          events?:
                            | Array<{
                                __typename: 'sms_event';
                                id: number;
                                event?: string | undefined;
                                date?: any | undefined;
                              }>
                            | undefined;
                        }
                      | undefined;
                    member?:
                      | {
                          __typename: 'members_member';
                          id: number;
                          first_name?: string | undefined;
                          last_name?: string | undefined;
                          licence_detail?:
                            | {
                                __typename: 'members_licence_detail';
                                number?: string | undefined;
                                status?: any | undefined;
                              }
                            | undefined;
                        }
                      | undefined;
                  }>
                | undefined;
            }>
          | undefined;
      }
    | undefined;
};

export type CalculateRouteTimeQueryVariables = Types.Exact<{
  place_id_from: Types.Scalars['Int']['input'];
  place_id_to: Types.Scalars['Int']['input'];
}>;

export type CalculateRouteTimeQuery = {
  __typename: 'query_root';
  places_calculate_route_time?:
    | {
        __typename: 'places_calculate_route_time_output';
        duration_minutes?: number | undefined;
      }
    | undefined;
};

export const GetTeamRulesPlannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTeamRulesPlanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_team_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_before_event' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'default_equipment_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seasons' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'season' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'current' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: {
                                          kind: 'BooleanValue',
                                          value: true,
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'is_staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'captain' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'member' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'officials' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTeamRulesPlannerQuery,
  GetTeamRulesPlannerQueryVariables
>;
export const GetEventPlayingTeamInfosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventPlayingTeamInfos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamInfoId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isHome' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'competitions_playing_team_info_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamInfoId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meeting_time' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'private_comment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'public_comment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'travel_duration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'equipment' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'isHome' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playing_team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team_in_season' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'members' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'is_staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'captain' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'member' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'first_name',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'last_name',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'meeting_before_event',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'team_tasks' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'officials',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'task_id',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emoji' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'volunteers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'has_error' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'events' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_by',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'date',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'desc',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'limit' },
                                        value: { kind: 'IntValue', value: '1' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'event',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message_sms' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'has_error' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'events' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'order_by',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'date',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'desc',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'limit' },
                                        value: { kind: 'IntValue', value: '1' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'event',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'member' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'first_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'last_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'licence_detail',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'number',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEventPlayingTeamInfosQuery,
  GetEventPlayingTeamInfosQueryVariables
>;
export const CalculateRouteTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CalculateRouteTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'place_id_from' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'place_id_to' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'places_calculate_route_time' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'place_id_from' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'place_id_from' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'place_id_to' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'place_id_to' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'duration_minutes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CalculateRouteTimeQuery,
  CalculateRouteTimeQueryVariables
>;
