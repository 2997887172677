/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ValidateEventForTeamMutationVariables = Types.Exact<{
  playing_team_id: Types.Scalars['Int']['input'];
  equipment_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  meeting_time?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
  status: Types.Scalars['Boolean']['input'];
  match_date?: Types.InputMaybe<Types.Scalars['date']['input']>;
  match_time?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
  jobs?: Types.InputMaybe<Array<Types.TaskMembers> | Types.TaskMembers>;
  private_comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  public_comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  travel_duration?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;

export type ValidateEventForTeamMutation = {
  __typename: 'mutation_root';
  competitions_playing_team_validate_event?:
    | { __typename: 'id_response'; id: number }
    | undefined;
};

export type UpdateUserPlannerSettingsMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  planner_settings: Types.Scalars['jsonb']['input'];
}>;

export type UpdateUserPlannerSettingsMutation = {
  __typename: 'mutation_root';
  update_users_user_by_pk?:
    | {
        __typename: 'users_user';
        id: number;
        planner_settings?: any | undefined;
      }
    | undefined;
};

export type UpdateClubPlannerSettingsMutationVariables = Types.Exact<{
  clubId: Types.Scalars['Int']['input'];
  _set: Types.Clubs_Planner_Settings_Set_Input;
}>;

export type UpdateClubPlannerSettingsMutation = {
  __typename: 'mutation_root';
  update_clubs_planner_settings?:
    | {
        __typename: 'clubs_planner_settings_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export const ValidateEventForTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidateEventForTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'playing_team_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'equipment_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'meeting_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'match_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'match_time' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'timestamptz' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TaskMembers' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'private_comment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public_comment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travel_duration' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'competitions_playing_team_validate_event',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'playing_team_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'playing_team_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'equipment_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'equipment_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'match_date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'match_date' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'match_time' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'match_time' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meeting_time' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'meeting_time' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'private_comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'private_comment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'public_comment' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'travel_duration' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'travel_duration' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidateEventForTeamMutation,
  ValidateEventForTeamMutationVariables
>;
export const UpdateUserPlannerSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserPlannerSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'planner_settings' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'jsonb' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_users_user_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'planner_settings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'planner_settings' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'planner_settings' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserPlannerSettingsMutation,
  UpdateUserPlannerSettingsMutationVariables
>;
export const UpdateClubPlannerSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClubPlannerSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'clubs_planner_settings_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_clubs_planner_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'club_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'clubId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClubPlannerSettingsMutation,
  UpdateClubPlannerSettingsMutationVariables
>;
