import { css } from '@emotion/react';
import { Box, BoxProps } from '@mui/system';

const style = {
  root: css`
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    flex-shrink: 0;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill-rule: evenodd;
  `,
};

export type IconProps = BoxProps & {
  titleAccess?: string;
};

export type ToggleIconProps = IconProps & {
  selected?: boolean;
};

export const Icon = ({ titleAccess, ...rest }: IconProps) => (
  <Box
    component="svg"
    strokeWidth="44"
    css={style.root}
    focusable="false"
    viewBox="0 0 512 512"
    aria-hidden={titleAccess ? undefined : true}
    role={titleAccess ? 'img' : undefined}
    {...rest}
  />
);
