import { ReactNode } from 'react';

import { ArrowBackIos } from '@mui/icons-material';
import { Typography, Skeleton, Stack, Link } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import NextLink from 'next/link';

type PageTitleProps = BoxProps & {
  title?: ReactNode;
  callToAction?: JSX.Element;
  returnLink?: string;
  returnText?: string | ReactNode;
  secondCallToAction?: JSX.Element;
};

export const PageTitle = ({
  title,
  callToAction,
  returnLink,
  returnText,
  secondCallToAction,
  ...rest
}: PageTitleProps) => {
  return (
    <Stack spacing={1} sx={{ my: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        {...rest}
      >
        <Typography
          component="h1"
          variant="h1"
          sx={{ flex: 1, m: 0, fontWeight: '900' }}
        >
          {title || <Skeleton sx={{ maxWidth: 400 }} />}
        </Typography>

        <Stack direction="row" spacing={1}>
          {callToAction}
          {secondCallToAction}
        </Stack>
      </Box>
      {returnLink && returnText && (
        <NextLink legacyBehavior href={returnLink} passHref>
          <Link
            underline="none"
            sx={{ fontSize: '0.8rem', width: 'fit-content' }}
          >
            <ArrowBackIos sx={{ verticalAlign: 'middle', fontSize: '1rem' }} />
            {returnText}
          </Link>
        </NextLink>
      )}
    </Stack>
  );
};
