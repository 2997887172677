import { EventHandler, useCallback } from 'react';

import { css } from '@emotion/react';
import {
  Popover,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {
  usePopupState,
  bindFocus,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import { HexColorPicker } from 'react-colorful';

export type ColorInputProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  popupId?: string;
  value?: string;
  onChange: (value?: string) => void;
};

export const ColorInput = ({
  popupId,
  value,
  onChange,
  ...rest
}: ColorInputProps) => {
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    disableAutoFocus: true,
  });

  const onInputChange: EventHandler<any> = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const onPickerChange = useCallback(
    (color: string) => {
      onChange(color);
    },
    [onChange]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onBlur, ...textFieldProps } = bindFocus(popupState);

  return (
    <>
      <TextField
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <div
                  css={(theme) => css`
                    width: 1.5em;
                    height: 1.5em;
                    border-radius: 9999px;
                    border: solid 1px ${theme.vars.palette.grey[500]};
                  `}
                  style={{ background: value }}
                />
              </InputAdornment>
            ),
          },
        }}
        value={value}
        onChange={onInputChange}
        {...textFieldProps}
        {...rest}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        css={css`
          margin-top: 0.5rem;
        `}
      >
        <HexColorPicker
          css={css`
            margin: 1rem;
            width: 18rem;
          `}
          color={value}
          onChange={onPickerChange}
        />
      </Popover>
    </>
  );
};
