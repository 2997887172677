import { css } from '@emotion/react';
import { Button, ButtonProps, Box, BoxProps } from '@mui/material';
import { IconCamera, ImageLoader } from '@scorenco/components';
import { ImageUsage, useUploadImage } from '@scorenco/core';

const Placeholder = (props: BoxProps) => {
  return (
    <Box
      css={(theme) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        background-color: ${theme.vars.palette.primary.lighter};
        border-radius: 100%;
      `}
      {...props}
    >
      <IconCamera
        css={(theme) => css`
          width: 50%;
          height: 100%;
          color: ${theme.vars.palette.text.primary};
        `}
      />
    </Box>
  );
};

type EditablePlayerImageProps = ButtonProps & {
  playerPicture?: string;
  setPlayerPicture: any;
};

export const EditablePlayerImage = ({
  playerPicture,
  setPlayerPicture,
  ...rest
}: EditablePlayerImageProps) => {
  const { uploadImage } = useUploadImage(ImageUsage.PlayerPhoto);

  const handleUploadImage = async () => {
    const image = await uploadImage();
    setPlayerPicture(image);
  };

  return (
    <Button
      css={(theme) => css`
        position: relative;
        padding: 0;
        object-fit: cover;

        &:hover {
          opacity: 0.8;
        }

        &:focus img {
          box-shadow: 0 0 0 0.2em ${theme.vars.palette.primary.lighter};
        }
      `}
      onClick={handleUploadImage}
      {...rest}
    >
      <ImageLoader
        css={css`
          display: block;
          width: 5rem;
          height: 5rem;
          border-radius: 100%;
          object-fit: cover;
        `}
        src={playerPicture}
        Placeholder={Placeholder}
      />
      {playerPicture && (
        <Placeholder
          css={css`
            position: absolute;
            bottom: 0;
            right: 0;
            width: 2rem;
            height: 2rem;
          `}
        />
      )}
    </Button>
  );
};
