import { Typography, Paper } from '@mui/material';
import { useTranslation } from '@scorenco/core';

import { T_KEYS } from '@/translations';

export const NotAuthorized = () => {
  const { t } = useTranslation();
  return (
    <Paper
      variant="bordered"
      sx={{
        mt: 2,
      }}
    >
      <Typography variant="body1">{t(T_KEYS.NOT_AUTHORIZED)}</Typography>
    </Paper>
  );
};
