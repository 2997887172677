import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  UpdateNewsDocument,
  UpdateNewsMutationVariables,
} from './News.mutation.generated';

export const useNewsUpdate = () => {
  const [runMutation, status] = useMutation(UpdateNewsDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: UpdateNewsMutationVariables) => {
      return runMutation({
        variables,
      });
    },
    [runMutation]
  );

  return { execute, status };
};
