import { useQuery } from '@apollo/client';
import {
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { Modal, useItems } from '@scorenco/components';

import {
  GetEmailsEventDocument,
  GetSmsEventDocument,
} from './SearchMembersInput.query.generated';
import { eventMessage, eventMessageSMS } from './utils';

export const MessageEventsDetailsModal = ({
  closeModal,
  type,
  message_id,
}: {
  closeModal: () => void;
  type: 'email' | 'sms';
  message_id: number;
}) => {
  const emailsEvents = useQuery(GetEmailsEventDocument, {
    variables: {
      message_id,
    },
    context: {
      role: 'club',
    },
    skip: type !== 'email' || !message_id,
  });

  const smsEvents = useQuery(GetSmsEventDocument, {
    variables: {
      message_id,
    },
    context: {
      role: 'club',
    },
    skip: type !== 'sms' || !message_id,
  });

  const events =
    type === 'email'
      ? emailsEvents.data?.emails_event
      : smsEvents.data?.sms_event;

  const listEvents = useItems(events, 3);

  return (
    <Modal closeModal={closeModal} title="Détails des événements du message">
      <DialogContent>
        {message_id ? (
          <List>
            {listEvents?.map((event, index) =>
              event?.id ? (
                <ListItem key={event?.id}>
                  <ListItemText
                    primary={
                      type === 'email'
                        ? eventMessage(event?.event)
                        : eventMessageSMS(event?.event)
                    }
                    secondary={
                      new Date(event?.date)?.toLocaleTimeString() +
                        ' - ' +
                        new Date(event?.date)?.toLocaleDateString() +
                        ' - ' +
                        (type === 'email'
                          ? event?.email
                          : event?.phone_number) || ''
                    }
                  />
                </ListItem>
              ) : (
                <Skeleton key={index} width="100%" height="4rem" />
              )
            )}
          </List>
        ) : (
          <Typography variant="body1">
            Le message de convocation n'a pas encore été envoyé. Ils sont
            envoyés 15 jours avant la date du match et à différents moments dans
            la journée. Merci de re-vérifier plus tard.
          </Typography>
        )}
      </DialogContent>
    </Modal>
  );
};
