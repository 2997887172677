import { useCallback, useEffect, useMemo, memo } from 'react';

import {
  CheckBoxOutlineBlank,
  CheckBox,
  Info,
  Brightness1,
  Warning,
} from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import { Algolia, ModalLink, useAlgoliaContext } from '@scorenco/components';
import { Types, useTranslation } from '@scorenco/core';
import debounce from 'lodash/debounce';
import { connectAutoComplete } from 'react-instantsearch-dom';

import { useCurrentClubContext } from '@/layouts';
import { T_KEYS } from '@/translations';

import { MessageEventsDetailsModal } from './MessageEventsDetailsModal';
import {
  SearchMembersFilters,
  SearchMembersFiltersTeamProps,
} from './SearchMembersFilters';
import { eventMessage, eventMessageSMS } from './utils';

type SearchMembersInputProps = SearchMembersFiltersTeamProps & {
  showCheckbox?: boolean;
  members?: Types.Members_Member[];
  membersComplete?: any;
  onMembersChange?: (value: Types.Members_Member[]) => void;
  minified?: boolean;
};

type MembersAutocompleteProps = SearchMembersInputProps & {
  showCheckbox?: boolean;
  hits: any[];
  currentRefinement: any;
  refine: (arg0: any) => void;
};

const MemberOption = memo(
  ({ props, option, selected, showCheckbox, minified, taskId }) => {
    const { t } = useTranslation();
    const { currentClub } = useCurrentClubContext();

    return (
      <Tooltip
        title={option.is_exempt_volunteer && t(T_KEYS.IS_EXEMPT_VOLUNTEER)}
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -20],
                },
              },
            ],
          },
        }}
      >
        <Box>
          <li
            {...props}
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              pointerEvents: option.is_exempt_volunteer ? 'none' : 'auto',
              opacity: option.is_exempt_volunteer ? 0.5 : 1,
            }}
          >
            {showCheckbox && (
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                sx={{ marginRight: 1 }}
                checked={selected}
                value={option}
              />
            )}
            <Stack direction="column" sx={{ width: '100%' }}>
              <Typography
                sx={{ width: '100%' }}
                variant="body2"
                component="span"
              >
                {option.first_name} {option.last_name}{' '}
                {option?.licence_status !== 1 &&
                  (!!option?.licence_number?.length ||
                    currentClub?.planner_settings
                      ?.is_empty_licence_invalid) && (
                    <Tooltip title="Licence non valide" arrow>
                      <Warning
                        sx={{ fontSize: '0.8rem', mx: 1, lineHeight: 1 }}
                        color="warning"
                      />
                    </Tooltip>
                  )}
                {!minified && (
                  <Typography
                    sx={{ width: '100%' }}
                    variant="body2"
                    component="span"
                  >
                    (
                    {option.task_count_list?.find((t) => t?.id === taskId)
                      ?.count || 0}
                    /
                    {option.task_count_list?.reduce(
                      (acc, curr) => acc + (curr?.count || 0),
                      0
                    )}
                    )
                  </Typography>
                )}
              </Typography>
              <Typography
                variant="caption"
                sx={{ filter: 'grayscale(1)', opacity: 0.8 }}
              >
                {option.team_list.find((team) => team.role)?.role}
              </Typography>
            </Stack>
            {(option.group_list.length > 0 || option.team_list.length > 0) && (
              <Tooltip
                sx={{ background: 'white' }}
                title={
                  <Box>
                    {option.team_list.map((team, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{
                          fontSize: '0.7rem',
                          pr: 1,
                          pl: 1,
                          pb: 0.5,
                          pt: 0.5,
                          borderRadius: '999px',
                          border: '1px solid',
                          borderColor: 'secondary.main',
                          display: 'inline-block',
                          width: 'fit-content',
                          m: '3px',
                        }}
                      >
                        {team.name_in_club} {team?.is_staff ? ': Staff' : ''}
                      </Box>
                    ))}
                    {option.group_list.map((group, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{
                          fontSize: '0.7rem',
                          pr: 1,
                          pl: 1,
                          pb: 0.5,
                          pt: 0.5,
                          borderRadius: '999px',
                          border: '1px solid',
                          borderColor: group.color,
                          display: 'inline-block',
                          width: 'fit-content',
                          m: '3px',
                        }}
                      >
                        <Box component="span" sx={{ mr: 1 }}>
                          {group.emoji}
                        </Box>
                        {group.name}
                      </Box>
                    ))}
                  </Box>
                }
              >
                <IconButton sx={{ ml: 2 }} size="small" color="primary">
                  <Info sx={{ width: '1rem', height: 'auto' }} />
                </IconButton>
              </Tooltip>
            )}
          </li>
        </Box>
      </Tooltip>
    );
  }
);

const MemberLabel = memo(
  ({ option, membersCompleteMap, taskId, currentClub }) => {
    const completeMember = membersCompleteMap[option?.id];

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {taskId && (
          <>
            <ModalLink
              Modal={MessageEventsDetailsModal}
              modalProps={{
                type: 'email',
                message_id: completeMember?.message?.id,
              }}
            >
              <Tooltip
                title={
                  !completeMember?.message
                    ? "L'e-mail de convocation n'a pas encore été envoyé. Ils sont envoyés 15 jours avant la date du match et à différents moments dans la journée. Merci de re-vérifier plus tard."
                    : eventMessage(completeMember?.message.events?.[0]?.event) +
                      ' Cliquez pour plus de détails'
                }
                style={{ cursor: 'pointer' }}
              >
                <Brightness1
                  sx={{
                    fontSize: '1rem',
                    mr: '0.25rem',
                    color: !completeMember?.message
                      ? 'divider'
                      : completeMember?.message.has_error
                        ? 'error.main'
                        : 'secondary.main',
                  }}
                />
              </Tooltip>
            </ModalLink>
            {currentClub?.planner_settings?.send_sms && (
              <ModalLink
                Modal={MessageEventsDetailsModal}
                modalProps={{
                  type: 'sms',
                  message_id: completeMember?.message_sms?.id,
                }}
              >
                <Tooltip
                  title={
                    !completeMember?.message_sms
                      ? "Le sms de convocation n'a pas encore été envoyé. Ils sont envoyés 15 jours avant la date du match et à différents moments dans la journée. Merci de re-vérifier plus tard."
                      : eventMessageSMS(
                          completeMember?.message_sms.events?.[0]?.event
                        )
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <Brightness1
                    sx={{
                      fontSize: '1rem',
                      mr: '0.25rem',
                      color: !completeMember?.message_sms
                        ? 'divider'
                        : completeMember?.message_sms.has_error
                          ? 'error.main'
                          : 'secondary.main',
                    }}
                  />
                </Tooltip>
              </ModalLink>
            )}
          </>
        )}
        {option?.first_name} {option?.last_name}{' '}
        {(option?._highlightResult
          ? option?.licence_status !== 1 &&
            (!!option?.licence_number?.length ||
              currentClub?.planner_settings?.is_empty_licence_invalid)
          : option?.licence_detail?.status !== 1 &&
            (!!option?.licence_detail?.number?.length ||
              currentClub?.planner_settings?.is_empty_licence_invalid)) && (
          <Tooltip title="Licence non valide" arrow>
            <Warning sx={{ fontSize: '0.8rem', ml: 1 }} color="warning" />
          </Tooltip>
        )}
      </Box>
    );
  }
);

const MembersAutocomplete = ({
  showCheckbox,
  hits,
  refine,
  onMembersChange,
  members,
  membersComplete,
  teamId,
  previousPlayingTeam,
  nextPlayingTeam,
  minified,
  taskId,
  ...rest
}: MembersAutocompleteProps) => {
  const { t } = useTranslation();
  const { currentClub } = useCurrentClubContext();

  const handleChange = useCallback(
    (_e, values) => {
      const selectedMembers = values.map(({ objectID, ...value }) => ({
        id: Number(objectID),
        ...value,
      }));
      onMembersChange?.(selectedMembers);
    },
    [onMembersChange]
  );

  const debouncedRefine = useMemo(
    () =>
      debounce((value: string) => {
        refine(value);
      }, 300),
    [refine]
  );

  useEffect(() => {
    return () => {
      debouncedRefine.cancel();
    };
  }, [debouncedRefine]);

  const handleInputChange = useCallback(
    (event) => {
      // debouncedRefine(event.currentTarget.value);
      refine(event.currentTarget.value);
    },
    [refine]
  );

  const membersCompleteMap = useMemo(() => {
    return (membersComplete || [])?.reduce((acc, member) => {
      acc[member?.member?.id] = member;
      return acc;
    }, {});
  }, [membersComplete]);

  const PaperComponent = useCallback(
    (props) => (
      <SearchMembersFilters
        teamId={teamId}
        previousPlayingTeam={previousPlayingTeam}
        nextPlayingTeam={nextPlayingTeam}
        taskId={taskId}
        {...props}
      />
    ),
    [teamId, previousPlayingTeam, nextPlayingTeam, taskId]
  );

  return (
    <Autocomplete
      {...rest}
      multiple
      fullWidth
      options={hits || []}
      filterOptions={(options) => options}
      disableCloseOnSelect
      noOptionsText={t(T_KEYS.NO_OPTIONS_TEXT)}
      loadingText={t(T_KEYS.LOADING_TEXT)}
      PaperComponent={!minified ? PaperComponent : undefined}
      isOptionEqualToValue={(option, value) =>
        Number(option.objectID) === value?.id
      }
      getOptionLabel={(option) => (
        <MemberLabel
          option={option}
          membersCompleteMap={membersCompleteMap}
          taskId={taskId}
          currentClub={currentClub}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MemberOption
          props={props}
          option={option}
          selected={selected}
          showCheckbox={showCheckbox}
          minified={minified}
          taskId={taskId}
        />
      )}
      onChange={handleChange}
      value={members || []}
      sx={{
        minWidth: '15rem',
        maxWidth: '30rem',
        width: '100%',
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            m: 0,
            fieldset: !minified ? { border: 'none' } : undefined,
          }}
          onChange={handleInputChange}
          {...params}
        />
      )}
    />
  );
};

const MemoizedMembersAutocomplete = memo(MembersAutocomplete);
const CustomAutoComplete = connectAutoComplete(MemoizedMembersAutocomplete);

const AlgoliaConfigure = () => {
  const { setFilters } = useAlgoliaContext();

  useEffect(() => {
    setFilters({
      is_currently_in_the_club: [true],
    });
  }, []);

  return null;
};

export const SearchMembersInput = memo(
  ({
    showCheckbox = true,
    onMembersChange,
    members,
    teamId,
    previousPlayingTeam,
    nextPlayingTeam,
    minified,
    membersComplete,
    taskId,
    ...rest
  }: SearchMembersInputProps) => {
    const { currentClub } = useCurrentClubContext();

    return (
      <Algolia
        indexName="Members_Member"
        hitsPerPage={20}
        clubSecuredApiKey={currentClub?.algolia_secured_api_key}
      >
        <AlgoliaConfigure />
        <CustomAutoComplete
          minified={minified}
          teamId={teamId}
          members={members}
          membersComplete={membersComplete}
          onMembersChange={onMembersChange}
          previousPlayingTeam={previousPlayingTeam}
          nextPlayingTeam={nextPlayingTeam}
          taskId={taskId}
          showCheckbox={showCheckbox}
          {...rest}
        />
      </Algolia>
    );
  }
);
