import { useState } from 'react';

type PromiseState<T = any> = {
  loading: boolean;
  error?: any;
  data?: T;
};

type UsePromiseStateReturn<T = any> = PromiseState<T> & {
  setPromiseState: (state: PromiseState<T>) => void;
};

export const usePromiseState = (): UsePromiseStateReturn => {
  const [state, setPromiseState] = useState<PromiseState>({ loading: false });
  return { ...state, setPromiseState };
};
