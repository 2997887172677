import { PropsWithChildren } from 'react';

import { Container, Stack, Box } from '@mui/material';
import { useTranslation } from '@scorenco/core';

import { T_KEYS } from '@/translations';

import {
  HorizontalNavigation,
  HorizontalNavigationLink,
  PageTitle,
} from '../../components';
import { useCurrentClubContext } from '../CurrentClubLayout';

const TabsNavigation = ({
  additionalMenus,
}: {
  additionalMenus?: JSX.Element;
}) => {
  const { t } = useTranslation();
  const { currentClub } = useCurrentClubContext();

  return (
    <Stack
      direction="row"
      sx={{ my: 3, justifyContent: 'space-between', flexWrap: 'wrap' }}
      spacing={3}
    >
      <HorizontalNavigation sx={{ my: 0 }}>
        {process.env.NEXT_PUBLIC_CLUB_INFORMATIONS_ALLOW && (
          <HorizontalNavigationLink href={`/${currentClub.id}/club`}>
            {t(T_KEYS.CLUB_NAVIGATION_INFORMATIONS)}
          </HorizontalNavigationLink>
        )}
        {process.env.NEXT_PUBLIC_CLUB_EQUIPMENTS_ALLOW && (
          <HorizontalNavigationLink href={`/${currentClub.id}/club/equipments`}>
            {t(T_KEYS.CLUB_NAVIGATION_EQUIPMENTS)}
          </HorizontalNavigationLink>
        )}
        <HorizontalNavigationLink href={`/${currentClub.id}/club/billing`}>
          Facturation
        </HorizontalNavigationLink>
      </HorizontalNavigation>
      {additionalMenus && <Box display="inline">{additionalMenus}</Box>}
    </Stack>
  );
};

export type ClubInfosLayoutComponentProps = PropsWithChildren<{
  additionalMenus?: JSX.Element;
}>;

export const ClubInfosLayoutComponent = ({
  children,
  additionalMenus,
}: ClubInfosLayoutComponentProps) => {
  const { t } = useTranslation();
  const { currentClub } = useCurrentClubContext();

  return (
    <Container sx={{ my: 3 }}>
      <PageTitle
        title={
          currentClub?.is_organization ? 'Organisation' : t(T_KEYS.CLUB_TITLE)
        }
      />
      <TabsNavigation additionalMenus={additionalMenus} />
      {children}
    </Container>
  );
};
