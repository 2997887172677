import { ElementType, ReactElement, useEffect } from 'react';

import { css } from '@emotion/react';
import {
  Paper,
  TableContainer,
  Table as MuiTable,
  TableFooter,
  TableHead,
  TableBody,
  TableRow as MuiTableRow,
  PaperProps,
  TablePagination,
} from '@mui/material';
import { Types, useTranslation } from '@scorenco/core';
import { useRouter } from 'next/router';
import {
  connectHits,
  connectPagination,
  connectStats,
} from 'react-instantsearch-dom';

import { useItems } from '../../hooks';
import { T_KEYS } from '../../translations';

type PaginationProps = {
  currentRefinement: number;
  nbHits: number;
  refine: (page: number) => void;
  hitsPerPage?: number;
};

const Pagination = ({
  currentRefinement,
  nbHits,
  refine,
  hitsPerPage,
}: PaginationProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    if (router.query.page) {
      refine(Number(router.query.page));
    }
  }, [router.query.clubId]);

  return (
    <TablePagination
      colSpan={5}
      count={nbHits}
      rowsPerPage={hitsPerPage}
      page={currentRefinement - 1}
      onPageChange={(_event, newPage) => {
        router.replace({
          pathname: router.pathname,
          query: {
            ...router.query,
            page: newPage + 1,
          },
        });
        refine(newPage + 1);
      }}
      rowsPerPageOptions={[]}
      labelDisplayedRows={({ from, to, count }) =>
        t(T_KEYS.TABLE_PAGINATION_STATE, { from, to, count })
      }
    />
  );
};

const AlgoliaPagination = connectStats(connectPagination(Pagination));

type TableProps<T = any> = PaperProps & {
  hits: T[];
  TableRow: ElementType;
  TableRowHead: ElementType;
  hitsPerPage?: number;
  selected?: Types.Members_Member[];
  setSelected?: (selected: Types.Members_Member[]) => void;
};

const Table = ({
  hits,
  sx,
  TableRow,
  TableRowHead,
  hitsPerPage = 10,
  selected,
  setSelected,
  ...rest
}: TableProps) => {
  const items = useItems(!hits?.length ? undefined : hits, hitsPerPage);

  return (
    <Paper
      variant="bordered"
      css={css`
        padding: 0;
        overflow: hidden;
        width: 100%;
      `}
      sx={sx}
    >
      <TableContainer>
        <MuiTable
          css={(theme) => css`
            .MuiTableHead-root {
              background: ${theme.vars.palette.background.default};

              .MuiTableCell-root {
                text-transform: uppercase;
                font-weight: 600;
                line-height: 1;
                border-top: 0;
                border-bottom: 0;
              }
            }

            .MuiTableRow-root {
              border-bottom: 1px solid ${theme.vars.palette.divider};
              cursor: pointer;

              &:last-child {
                border-bottom: 0;
              }

              .MuiTableCell-root {
                border-top: 0;
                border-bottom: 0;
              }
            }

            .MuiTableBody-root {
              border-top: 1px solid ${theme.vars.palette.divider};
              border-bottom: 1px solid ${theme.vars.palette.divider};
            }
          `}
        >
          <TableHead>
            <TableRowHead
              setSelected={setSelected}
              selected={selected}
              hits={hits}
            />
          </TableHead>

          <TableBody>
            {items.map((hit, index) => (
              <TableRow
                key={index}
                hit={hit}
                setSelected={setSelected}
                selected={selected}
                {...rest}
              />
            ))}
          </TableBody>

          <TableFooter>
            <MuiTableRow>
              <AlgoliaPagination hitsPerPage={hitsPerPage} />
            </MuiTableRow>
          </TableFooter>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
};

export const AlgoliaTable = connectHits(Table) as unknown as (
  props: Omit<TableProps, 'hits'>
) => ReactElement | null;
