import { PropsWithChildren } from 'react';

import { Stack, Box } from '@mui/material';
import {
  NavigationBurgerDrawer,
  NextNavigationServiceOptions,
} from '@scorenco/next';

import { CurrentClubProvider } from './CurrentClubContext';
import { Navigation } from './Navigation';
import { PermissionsProvider } from './PermissionsContext/PermissionsContext';

export const CurrentClubLayoutComponent = ({
  children,
  noBurgerDrawer,
}: PropsWithChildren<NextNavigationServiceOptions>) => {
  return (
    <CurrentClubProvider>
      <PermissionsProvider>
        <Stack direction="row">
          {!noBurgerDrawer && (
            <NavigationBurgerDrawer>
              <Navigation />
            </NavigationBurgerDrawer>
          )}

          <Box
            component="main"
            sx={{
              flex: 1,
              width: '100%',
            }}
          >
            {children}
          </Box>
        </Stack>
      </PermissionsProvider>
    </CurrentClubProvider>
  );
};
