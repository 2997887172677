import { css } from '@emotion/react';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Image } from '@scorenco/components';
import { RouteLink, useTranslation } from '@scorenco/core';

import { T_KEYS } from '@/translations';

export const ErrorPageContainer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Stack sx={{ mt: 4 }} spacing={4} alignItems="center">
        <Typography variant="h1">Oups ...</Typography>
        <RouteLink href="/">
          <Image
            css={css`
              width: 100%;
              height: auto;
              max-width: 480px;
              cursor: pointer;
            `}
            src="https://media.giphy.com/media/8TY5Uw0CNztcwYuP1t/giphy.gif"
          />
        </RouteLink>
        <RouteLink href="/">
          <Button variant="contained" color="secondary">
            {t(T_KEYS.BACK)}
          </Button>
        </RouteLink>
      </Stack>
    </Container>
  );
};
