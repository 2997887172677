import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { usePromiseState, userSession } from '../../helpers';

import { useAmplifyServiceContext } from './AmplifyServiceContext';
import { getAuthClient } from './auth-client';

type SignOutFunction = () => void;

export const useSignOut = ({
  urlOpener,
}: {
  urlOpener?: (url: string) => void;
} = {}): {
  loading: boolean;
  error?: any;
  signOut: SignOutFunction;
} => {
  const { loading, error, setPromiseState } = usePromiseState();
  const { signOut } = useAmplifyServiceContext();
  const apolloClient = useApolloClient();

  const signOutCb: SignOutFunction = useCallback(async () => {
    setPromiseState({ loading: true });

    try {
      userSession.reset();

      const authClient = await getAuthClient();

      // WARNING: L'option urlOpener a été ajouté par patch de la lib
      // @aws-amplify
      await authClient.signOut({ global: true, options: { urlOpener } });

      signOut();

      await apolloClient.clearStore();
      await apolloClient.stop();

      setPromiseState({ loading: false });
    } catch (error) {
      setPromiseState({ loading: false, error });
    }
  }, [signOut]);

  return {
    loading,
    error,
    signOut: signOutCb,
  };
};
