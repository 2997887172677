import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  ScrapingSynchronizeEventsThisWeekDocument,
  ScrapingSynchronizeEventsThisWeekMutationVariables,
} from './Resynchronisation.mutation.generated';

export const useScrapingSynchronizeEventsThisWeek = () => {
  const [runMutation, status] = useMutation(
    ScrapingSynchronizeEventsThisWeekDocument,
    {
      context: { role: 'club' },
    }
  );

  const execute = useCallback(
    (variables: ScrapingSynchronizeEventsThisWeekMutationVariables) => {
      return runMutation({
        variables,
      });
    },
    [runMutation]
  );

  return { execute, status };
};
