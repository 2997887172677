import { setHours, setMinutes, parseISO } from 'date-fns';

export const validateEvent = async (
  values,
  event,
  formik,
  validateEventForTeamMutation
) => {
  await validateEventForTeamMutation.execute({
    equipment_id: values.equipment || null,
    playing_team_id: values.playingTeamId,
    meeting_time: new Date(values.meetingTime),
    status: values.status === 'ok',
    match_date:
      values.eventDate === event.date
        ? null
        : values.eventDate === null
          ? null
          : new Date(
              Date.UTC(
                formik.values.eventDate.getFullYear(),
                formik.values.eventDate.getMonth(),
                formik.values.eventDate.getDate()
              )
            )
              .toISOString()
              .split('T')[0],

    match_time:
      values.eventTime === event.time
        ? null
        : values.eventTime &&
          setMinutes(
            setHours(
              values.eventDate
                ? values.eventDate
                : setHours(parseISO(event.date), 2),
              values.eventTime.getHours()
            ),
            values.eventTime.getMinutes()
          ),
    jobs: values.jobs.map((job) => {
      return {
        id: job.task_id,
        member_ids: job.members?.map((member) => member?.id) || [],
      };
    }),
    private_comment: values.privateComment || '',
    public_comment: values.publicComment || '',
    travel_duration: values.travelDuration || 0,
  });
};
