import { makeVar } from '@apollo/client';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';

import {
  ALGOLIA_APP_ID,
  ALGOLIA_SECRET_KEY,
  ALGOLIA_ENVIRONMENT,
  ALGOLIA_COUNTRY,
} from './env-vars';

let singleton: SearchClient;

export const algoliaRefreshCacheVar = makeVar(false);

export const searchClient = (clubSecuredApiKey = '') => {
  if (clubSecuredApiKey) {
    return algoliasearch(
      ALGOLIA_APP_ID.get(),
      clubSecuredApiKey || ALGOLIA_SECRET_KEY.get()
    );
  }

  if (!singleton) {
    singleton = algoliasearch(
      ALGOLIA_APP_ID.get(),
      clubSecuredApiKey || ALGOLIA_SECRET_KEY.get()
    );
  }

  return singleton;
};

export const getAlgoliaIndexName = (index: string, useCountry = false) =>
  useCountry
    ? `${index}${ALGOLIA_COUNTRY.get()}_${ALGOLIA_ENVIRONMENT.get()}`
    : `${index}_${ALGOLIA_ENVIRONMENT.get()}`;

export const algoliaRefreshCache = () => {
  const timeoutId = setTimeout(() => {
    algoliaRefreshCacheVar(true);
    algoliaRefreshCacheVar(false);
  }, 3500);

  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };
};
