import { GetLayout } from '@scorenco/next';

import { BackofficeBuildGetLayout } from '../BackofficeLayout';
import { CurrentClubLayout } from '../CurrentClubLayout';

import {
  ClubInfosLayoutComponent,
  ClubInfosLayoutComponentProps,
} from './ClubInfosLayoutComponent';

const getLayout =
  (layoutProps: ClubInfosLayoutComponentProps): GetLayout =>
  (page) =>
    (
      <ClubInfosLayoutComponent {...layoutProps}>
        {page}
      </ClubInfosLayoutComponent>
    );

type ClubInfosBuildGetLayout =
  BackofficeBuildGetLayout<ClubInfosLayoutComponentProps>;

export const buildGetLayout: ClubInfosBuildGetLayout = (
  layoutProps = {},
  getLayouts = []
) => {
  return CurrentClubLayout.buildGetLayout(layoutProps, [
    getLayout(layoutProps),
    ...getLayouts,
  ]);
};
