import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  EquipmentUpdateMutationVariables,
  EquipmentUpdateDocument,
} from './Equipment.mutation.generated';

export const useEquipmentUpdate = () => {
  const [runMutation, status] = useMutation(EquipmentUpdateDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: EquipmentUpdateMutationVariables) => {
      return runMutation({
        variables,
      });
    },
    [runMutation]
  );

  return { execute, status };
};
