import { Box, Button } from '@mui/material';
import { useTranslation } from '@scorenco/core';

import { T_KEYS } from '../../translations';

export const ButtonDocumentation = ({ link, title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...rest}
    >
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Button variant="outlined" sx={{ textTransform: 'none' }}>
          {title ? `🛟 ${title}` : t(T_KEYS.BUTTON_SHOW_DOCUMENTATION)}
        </Button>
      </a>
    </Box>
  );
};
