import { useCallback } from 'react';

import { userSession } from '../../helpers';

import { REST_ENDPOINT } from './env-vars';

const setAuthorization = (token?: string, headers?: HeadersInit) => {
  if (token) {
    return { ...(headers || {}), authorization: token };
  }
  return headers;
};

export const useRest = (endpoint = REST_ENDPOINT.get()) => {
  const token = userSession.getToken();

  const del = useCallback(
    async (url: string) =>
      await fetch(`https://${endpoint}${url}`, {
        method: 'DELETE',
        headers: setAuthorization(token),
      }).then((response) => response.json()),
    [token, endpoint]
  );

  const post = useCallback(
    async (url: string, data: any, stringify = true) =>
      await fetch(`https://${endpoint}${url}`, {
        method: 'POST',
        headers: setAuthorization(
          token,
          stringify ? { 'Content-Type': 'application/json' } : {}
        ),
        body: stringify ? JSON.stringify(data) : data,
      }).then((response) => response.json()),
    [token, endpoint]
  );

  return {
    del,
    post,
  };
};
