import { Icon, IconProps } from './Icon';

export const IconCamera = (props: IconProps) => (
  <Icon {...props}>
    <g fill="none">
      <circle
        cx="260.9"
        cy="280.1"
        r="86.9"
        stroke="currentColor"
        strokeWidth="32"
      />
      <path fill="currentColor" d="M401 186a22 22 0 110 43 22 22 0 010-43z" />
      <path
        stroke="currentColor"
        strokeWidth="32"
        d="M450 138h-86l-10-35a25 25 0 00-24-18H185a25 25 0 00-23 18l-13 35H66a29 29 0 00-28 29v236a29 29 0 0028 29h384a29 29 0 0028-29V167a29 29 0 00-28-29z"
      />
    </g>
  </Icon>
);
