import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Box, Button, Chip, Skeleton, Stack, Typography } from '@mui/material';
import { ClubLogo, useItems } from '@scorenco/components';
import { RouteLink } from '@scorenco/core';
import { formatDistance } from 'date-fns';
import { fr } from 'date-fns/locale';

import { RouteKey } from '../../../routes';
import {
  GetClubsClientsDocument,
  GetOrganizationsDocument,
} from '../GetClubs.query.generated';

const FILTERS = [
  {
    name: 'customer_clubhouse_start',
    label: '📺 Clubhouse',
  },
  {
    name: 'customer_planner_start',
    label: '🤝 Convocations',
  },
  {
    name: 'customer_premium_start',
    label: '⭐️ Partenaires',
  },
  {
    name: 'customer_tournament_start',
    label: '🏆 Tournois',
  },
];

export const ClientClubs = () => {
  const [showClients, setShowClients] = useState(false);
  const getClubsClients = useQuery(GetClubsClientsDocument, {
    context: {
      role: 'superuser',
    },
    skip: !showClients,
  });

  const [showOrganizations, setShowOrganizations] = useState(false);
  const getOrganizations = useQuery(GetOrganizationsDocument, {
    context: {
      role: 'superuser',
    },
    skip: !showOrganizations,
  });

  const [clientFilter, setClientFilter] = useState('');

  const clubsClients = useItems(getClubsClients.data?.clubs_club, 1);
  const organizations = useItems(getOrganizations.data?.clubs_organization, 1);
  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        sx={{ flex: 1, mt: 4, textTransform: 'capitalize' }}
      >
        Clubs clients
        <Button
          variant="outlined"
          onClick={() => setShowClients(!showClients)}
          size="small"
          sx={{ ml: 2 }}
        >
          {showClients ? 'Masquer' : 'Voir'}
        </Button>
      </Typography>
      {showClients && (
        <>
          <Stack flexDirection="row" gap={2} sx={{ my: 2 }}>
            {FILTERS.map((filter, index) => (
              <Button
                key={index}
                variant={
                  clientFilter === filter.name ? 'contained' : 'outlined'
                }
                onClick={() =>
                  setClientFilter(
                    clientFilter === filter.name ? '' : filter.name
                  )
                }
                size="small"
              >
                {filter.label}
              </Button>
            ))}
          </Stack>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
              grid-gap: 1rem;
            `}
          >
            {clubsClients
              .filter((c) => !!c?.[clientFilter] || clientFilter === '')
              ?.map((club, idx) => (
                <RouteLink
                  key={idx}
                  href={
                    club && {
                      routeKey: RouteKey.Dashboard,
                      params: { clubId: club.id },
                    }
                  }
                >
                  <a
                    css={(theme) => css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border: 1px solid ${theme.vars.palette.divider};
                      background-color: ${theme.vars.palette.background.paper};
                      color: ${theme.vars.palette.text.primary};
                      border-radius: 0.5rem;
                      padding: 1rem;
                      text-decoration: none;
                    `}
                  >
                    <ClubLogo
                      logo={club?.logo}
                      css={css`
                        display: flex;
                        width: 3rem;
                        height: 3rem;
                        margin-bottom: 1rem;
                      `}
                    />
                    <div
                      css={css`
                        min-width: 3rem;
                        text-align: center;
                        margin-bottom: 0.25rem;
                      `}
                    >
                      {club?.name || <Skeleton />}
                    </div>
                    <Box>
                      {FILTERS.map(
                        ({ name, label }, index) =>
                          !!club?.[name] && (
                            <Chip
                              key={index}
                              variant="outlined"
                              label={label}
                              size="small"
                              color="secondary"
                              sx={{ mx: 0.25, mb: 0.4, fontSize: '0.65rem' }}
                            />
                          )
                      )}
                    </Box>
                    {club?.admins?.[0]?.admin?.user?.last_login && (
                      <Typography
                        variant="body2"
                        fontSize="small"
                        sx={{ color: 'lightgrey' }}
                        textAlign="center"
                      >
                        Dernière connexion : il y a{' '}
                        <>
                          {formatDistance(
                            new Date(
                              club?.admins?.[0]?.admin?.user?.last_login
                            ),
                            new Date(),
                            {
                              locale: fr,
                            }
                          )}
                        </>
                      </Typography>
                    )}
                  </a>
                </RouteLink>
              ))}
          </div>
        </>
      )}
      <Typography
        component="h2"
        variant="h2"
        sx={{ flex: 1, mt: 4, textTransform: 'capitalize' }}
      >
        Organisations
        <Button
          variant="outlined"
          onClick={() => setShowOrganizations(!showOrganizations)}
          size="small"
          sx={{ ml: 2 }}
        >
          {showOrganizations ? 'Masquer' : 'Voir'}
        </Button>
      </Typography>
      {showOrganizations && (
        <>
          <Stack flexDirection="row" gap={2} sx={{ my: 2 }}>
            {FILTERS.map((filter, index) => (
              <Button
                key={index}
                variant={
                  clientFilter === filter.name ? 'contained' : 'outlined'
                }
                onClick={() =>
                  setClientFilter(
                    clientFilter === filter.name ? '' : filter.name
                  )
                }
                size="small"
              >
                {filter.label}
              </Button>
            ))}
          </Stack>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
              grid-gap: 1rem;
            `}
          >
            {organizations?.map((organization, idx) => (
              <RouteLink
                key={idx}
                href={
                  organization?.club && {
                    routeKey: RouteKey.Dashboard,
                    params: { clubId: organization?.club.id },
                  }
                }
              >
                <a
                  css={(theme) => css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid ${theme.vars.palette.divider};
                    background-color: ${theme.vars.palette.background.paper};
                    color: ${theme.vars.palette.text.primary};
                    border-radius: 0.5rem;
                    padding: 1rem;
                    text-decoration: none;
                  `}
                >
                  <ClubLogo
                    logo={organization?.club?.logo}
                    css={css`
                      display: flex;
                      width: 3rem;
                      height: 3rem;
                      margin-bottom: 1rem;
                    `}
                  />
                  <div
                    css={css`
                      min-width: 3rem;
                      text-align: center;
                      margin-bottom: 0.25rem;
                    `}
                  >
                    {organization?.club?.name || <Skeleton />}
                  </div>
                  <Box>
                    {FILTERS.map(
                      ({ name, label }, index) =>
                        !!organization?.club?.[name] && (
                          <Chip
                            key={index}
                            variant="outlined"
                            label={label}
                            size="small"
                            color="secondary"
                            sx={{ mx: 0.25, mb: 0.4, fontSize: '0.65rem' }}
                          />
                        )
                    )}
                  </Box>
                  {organization?.club?.admins?.[0]?.admin?.user?.last_login && (
                    <Typography
                      variant="body2"
                      fontSize="small"
                      sx={{ color: 'lightgrey' }}
                      textAlign="center"
                    >
                      Dernière connexion : il y a{' '}
                      <>
                        {formatDistance(
                          new Date(
                            organization?.club?.admins?.[0]?.admin?.user?.last_login
                          ),
                          new Date(),
                          {
                            locale: fr,
                          }
                        )}
                      </>
                    </Typography>
                  )}
                </a>
              </RouteLink>
            ))}
          </div>
        </>
      )}
    </>
  );
};
