import { createContext, useContext, useMemo, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Types, useAmplifyServiceContext } from '@scorenco/core';
import { useRouter } from 'next/router';

import { GetMemberAndPermissionsDocument } from './PermissionsContext.query.generated';

export type PermissionsContextProps = {
  permissions: Types.Permission[];
  checkPermission: (codename: string) => {
    read: boolean;
    add: boolean;
    delete: boolean;
    edit: boolean;
  };
};

export const PermissionsContext = createContext<
  PermissionsContextProps | undefined
>(undefined);

export const usePermissionsContext = (): PermissionsContextProps => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(
      'usePermissionsContext must be inside an PermissionsContext'
    );
  }
  return context;
};

export const PermissionsProvider = ({ children }) => {
  const router = useRouter();
  const clubId = Number(router.query.clubId);
  const { user } = useAmplifyServiceContext();

  const getMemberAndPermissionsQuery = useQuery(
    GetMemberAndPermissionsDocument,
    {
      variables: {
        clubId,
        userId: Number(user.info.id),
      },
      skip: !clubId,
      context: {
        role: 'club',
      },
    }
  );

  useEffect(() => {
    if (router.query.clubId) {
      getMemberAndPermissionsQuery.refetch({
        clubId,
      });
    }
  }, [clubId]);

  const permissions =
    getMemberAndPermissionsQuery.data?.members_member?.[0]?.permissions;

  const checkPermission = (codename: string) => {
    if (
      user?.info?.is_superuser ||
      user?.info?.club_admin?.clubs?.some((c) => c.club_id === clubId)
    ) {
      return {
        read: true,
        add: true,
        delete: true,
        edit: true,
      };
    }

    return permissions?.filter(
      (permission) => permission.codename === codename
    )[0];
  };

  const context = useMemo(
    () => ({ permissions, checkPermission }),
    [permissions]
  );

  return (
    <>
      <PermissionsContext.Provider value={context}>
        {children}
      </PermissionsContext.Provider>
    </>
  );
};
