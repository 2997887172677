/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InternalTeamAddToFavoriteMutationVariables = Types.Exact<{
  teamId: Types.Scalars['Int']['input'];
}>;

export type InternalTeamAddToFavoriteMutation = {
  __typename: 'mutation_root';
  teams_add_fan?:
    | {
        __typename: 'teams_add_fan_response';
        nb_fans?: number | undefined;
        fan_id?: number | undefined;
        team_id?: number | undefined;
      }
    | undefined;
};

export type InternalTeamDeleteFromFavoriteMutationVariables = Types.Exact<{
  teamId: Types.Scalars['Int']['input'];
}>;

export type InternalTeamDeleteFromFavoriteMutation = {
  __typename: 'mutation_root';
  teams_delete_fan?:
    | {
        __typename: 'teams_delete_fan_response';
        nb_fans?: number | undefined;
        team_id?: number | undefined;
      }
    | undefined;
};

export type AddTeamMemberMutationVariables = Types.Exact<{
  captain: Types.Scalars['Boolean']['input'];
  is_staff: Types.Scalars['Boolean']['input'];
  member_id: Types.Scalars['Int']['input'];
  number: Types.Scalars['smallint']['input'];
  public_name: Types.Scalars['String']['input'];
  role: Types.Scalars['String']['input'];
  team_in_season_id: Types.Scalars['Int']['input'];
  photo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  is_burned?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AddTeamMemberMutation = {
  __typename: 'mutation_root';
  insert_teams_team_in_season_has_member_one?:
    | { __typename: 'teams_team_in_season_has_member'; id: number }
    | undefined;
  update_members_member_by_pk?:
    | { __typename: 'members_member'; id: number }
    | undefined;
};

export type UpdateTeamMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  _set: Types.Teams_Team_In_Season_Has_Member_Set_Input;
}>;

export type UpdateTeamMemberMutation = {
  __typename: 'mutation_root';
  update_teams_team_in_season_has_member_by_pk?:
    | {
        __typename: 'teams_team_in_season_has_member';
        id: number;
        captain?: boolean | undefined;
      }
    | undefined;
};

export type TeamMemberDeleteMutationVariables = Types.Exact<{
  memberIds:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
  teamInSeasonId: Types.Scalars['Int']['input'];
}>;

export type TeamMemberDeleteMutation = {
  __typename: 'mutation_root';
  delete_teams_team_in_season_has_member?:
    | {
        __typename: 'teams_team_in_season_has_member_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export type SetTeamRulesMutationVariables = Types.Exact<{
  teamId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  time?: Types.InputMaybe<Types.Scalars['smallint']['input']>;
  equipment?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type SetTeamRulesMutation = {
  __typename: 'mutation_root';
  update_teams_team?:
    | {
        __typename: 'teams_team_mutation_response';
        affected_rows?: number | undefined;
        returning?:
          | Array<{
              __typename: 'teams_team';
              meeting_before_event?: any | undefined;
              default_equipment_id?: number | undefined;
            }>
          | undefined;
      }
    | undefined;
};

export type UpdateTeamMutationVariables = Types.Exact<{
  teamInSeasonId: Types.Scalars['Int']['input'];
  custom_name_in_club: Types.Scalars['String']['input'];
  squad_picture: Types.Scalars['String']['input'];
  share_picture_background: Types.Scalars['String']['input'];
  name_in_competition: Types.Scalars['String']['input'];
  club_id: Types.Scalars['Int']['input'];
}>;

export type UpdateTeamMutation = {
  __typename: 'mutation_root';
  update_teams_team_in_season?:
    | {
        __typename: 'teams_team_in_season_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
  teams_update_team_name?:
    | {
        __typename: 'teams_update_team_name_mutation_response';
        team_updated?: any | undefined;
      }
    | undefined;
};

export type SearchUpdateTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['Int']['input'];
  name_in_competition: Types.Scalars['String']['input'];
}>;

export type SearchUpdateTeamMutation = {
  __typename: 'mutation_root';
  teams_update_team_name?:
    | {
        __typename: 'teams_update_team_name_mutation_response';
        team_updated?: any | undefined;
      }
    | undefined;
};

export type TransferPlayerMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  objects:
    | Array<Types.Teams_Team_In_Season_Has_Member_Insert_Input>
    | Types.Teams_Team_In_Season_Has_Member_Insert_Input;
}>;

export type TransferPlayerMutation = {
  __typename: 'mutation_root';
  insert_teams_team_in_season_has_member?:
    | {
        __typename: 'teams_team_in_season_has_member_mutation_response';
        returning?:
          | Array<{ __typename: 'teams_team_in_season_has_member'; id: number }>
          | undefined;
      }
    | undefined;
  delete_teams_team_in_season_has_member?:
    | {
        __typename: 'teams_team_in_season_has_member_mutation_response';
        returning?:
          | Array<{ __typename: 'teams_team_in_season_has_member'; id: number }>
          | undefined;
      }
    | undefined;
};

export type InsertOrUpdatePermissionsForTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['Int']['input'];
  teamIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  officials: Types.Scalars['Boolean']['input'];
  groupIds:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
  taskId: Types.Scalars['Int']['input'];
}>;

export type InsertOrUpdatePermissionsForTeamMutation = {
  __typename: 'mutation_root';
  tasks_insert_or_update_permissions_for_team?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type AddTeamMutationVariables = Types.Exact<{
  ageCategory: Types.Scalars['String']['input'];
  clubId: Types.Scalars['Int']['input'];
  gender: Types.Scalars['String']['input'];
  number: Types.Scalars['smallint']['input'];
}>;

export type AddTeamMutation = {
  __typename: 'mutation_root';
  teams_add_team?:
    | {
        __typename: 'teams_add_team_response';
        team_id?: number | undefined;
        team_in_season_id?: number | undefined;
      }
    | undefined;
};

export type DeleteTeamByTeamInSeasonIdMutationVariables = Types.Exact<{
  teamInSeasonId: Types.Scalars['Int']['input'];
}>;

export type DeleteTeamByTeamInSeasonIdMutation = {
  __typename: 'mutation_root';
  teams_delete_team_in_season?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type DeleteTeamGroupOfTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['Int']['input'];
  clubId: Types.Scalars['Int']['input'];
}>;

export type DeleteTeamGroupOfTeamMutation = {
  __typename: 'mutation_root';
  delete_teams_club_group?:
    | {
        __typename: 'teams_club_group_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export type MigrateTeamSeasonMutationVariables = Types.Exact<{
  teamInSeasonIds:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
}>;

export type MigrateTeamSeasonMutation = {
  __typename: 'mutation_root';
  teams_migrate_to_season?:
    | {
        __typename: 'teams_migrate_to_season_response';
        nb_teams?: number | undefined;
      }
    | undefined;
};

export type InsertTeamInClubGroupMutationVariables = Types.Exact<{
  existing_team_id: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  new_club_id: Types.Scalars['Int']['input'];
  rank: Types.Scalars['smallint']['input'];
}>;

export type InsertTeamInClubGroupMutation = {
  __typename: 'mutation_root';
  insert_teams_club_group_one?:
    | { __typename: 'teams_club_group'; id: number }
    | undefined;
};

export type ReorderTeamsMutationVariables = Types.Exact<{
  club_id: Types.Scalars['Int']['input'];
  team_ids:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
}>;

export type ReorderTeamsMutation = {
  __typename: 'mutation_root';
  teams_set_rank?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type ReorderFavoriteTeamsMutationVariables = Types.Exact<{
  updates:
    | Array<Types.Teams_Team_Has_Fans_Updates>
    | Types.Teams_Team_Has_Fans_Updates;
}>;

export type ReorderFavoriteTeamsMutation = {
  __typename: 'mutation_root';
  update_teams_team_has_fans_many?:
    | Array<
        | {
            __typename: 'teams_team_has_fans_mutation_response';
            affected_rows?: number | undefined;
          }
        | undefined
      >
    | undefined;
};

export type UpdateTeamCompetitionMutationVariables = Types.Exact<{
  competitionHasTeamId: Types.Scalars['Int']['input'];
  newTeamInSeasonId: Types.Scalars['Int']['input'];
}>;

export type UpdateTeamCompetitionMutation = {
  __typename: 'mutation_root';
  update_competitions_competition_has_teams_by_pk?:
    | {
        __typename: 'competitions_competition_has_teams';
        id: number;
        team_in_season_id?: number | undefined;
      }
    | undefined;
};

export type UpdateChallengesOrderMutationVariables = Types.Exact<{
  updates:
    | Array<Types.Tournaments_Challenge_Updates>
    | Types.Tournaments_Challenge_Updates;
}>;

export type UpdateChallengesOrderMutation = {
  __typename: 'mutation_root';
  update_tournaments_challenge_many?:
    | Array<
        | {
            __typename: 'tournaments_challenge_mutation_response';
            affected_rows?: number | undefined;
          }
        | undefined
      >
    | undefined;
};

export type UpdatePhasesOrderMutationVariables = Types.Exact<{
  updates:
    | Array<Types.Tournaments_Phase_Updates>
    | Types.Tournaments_Phase_Updates;
}>;

export type UpdatePhasesOrderMutation = {
  __typename: 'mutation_root';
  update_tournaments_phase_many?:
    | Array<
        | {
            __typename: 'tournaments_phase_mutation_response';
            affected_rows?: number | undefined;
          }
        | undefined
      >
    | undefined;
};

export const InternalTeamAddToFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InternalTeamAddToFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_add_fan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nb_fans' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fan_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'team_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InternalTeamAddToFavoriteMutation,
  InternalTeamAddToFavoriteMutationVariables
>;
export const InternalTeamDeleteFromFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InternalTeamDeleteFromFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_delete_fan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nb_fans' } },
                { kind: 'Field', name: { kind: 'Name', value: 'team_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InternalTeamDeleteFromFavoriteMutation,
  InternalTeamDeleteFromFavoriteMutationVariables
>;
export const AddTeamMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTeamMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'captain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'is_staff' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'member_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'number' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'smallint' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'public_name' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'team_in_season_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'photo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'is_burned' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_teams_team_in_season_has_member_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captain' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'captain' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_staff' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'is_staff' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'member_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'number' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'number' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'public_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'public_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_in_season_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'team_in_season_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'photo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'photo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_burned' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'is_burned' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_members_member_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'member_id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_currently_in_the_club' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTeamMemberMutation,
  AddTeamMemberMutationVariables
>;
export const UpdateTeamMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTeamMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'teams_team_in_season_has_member_set_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_teams_team_in_season_has_member_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'captain' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
>;
export const TeamMemberDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TeamMemberDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'memberIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamInSeasonId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_teams_team_in_season_has_member',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'memberIds' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_in_season' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'teamInSeasonId',
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TeamMemberDeleteMutation,
  TeamMemberDeleteMutationVariables
>;
export const SetTeamRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetTeamRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'time' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'smallint' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'equipment' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_teams_team' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'teamId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'meeting_before_event' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'default_equipment_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'equipment' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meeting_before_event' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'default_equipment_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetTeamRulesMutation,
  SetTeamRulesMutationVariables
>;
export const UpdateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamInSeasonId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'custom_name_in_club' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'squad_picture' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'share_picture_background' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'name_in_competition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'club_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_teams_team_in_season' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'teamInSeasonId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'squad_picture' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'squad_picture' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'share_picture_background' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'share_picture_background',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_update_team_name' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_in_season_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamInSeasonId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name_in_competition' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name_in_competition' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name_in_club' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'custom_name_in_club' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'club_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_updated' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const SearchUpdateTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SearchUpdateTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'name_in_competition' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_update_team_name' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_in_season_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name_in_competition' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name_in_competition' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_updated' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchUpdateTeamMutation,
  SearchUpdateTeamMutationVariables
>;
export const TransferPlayerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TransferPlayer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'objects' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'teams_team_in_season_has_member_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_teams_team_in_season_has_member',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'objects' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value:
                          'team_in_season_has_membe_team_in_season_id_member_9b92670b_uniq',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: { kind: 'EnumValue', value: 'public_name' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_teams_team_in_season_has_member',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TransferPlayerMutation,
  TransferPlayerMutationVariables
>;
export const InsertOrUpdatePermissionsForTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertOrUpdatePermissionsForTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'officials' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'tasks_insert_or_update_permissions_for_team',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'task_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'officials' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'officials' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertOrUpdatePermissionsForTeamMutation,
  InsertOrUpdatePermissionsForTeamMutationVariables
>;
export const AddTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ageCategory' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'number' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'smallint' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_add_team' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'age_category' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ageCategory' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clubId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gender' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gender' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'number' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'number' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'team_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_in_season_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddTeamMutation, AddTeamMutationVariables>;
export const DeleteTeamByTeamInSeasonIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTeamByTeamInSeasonId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamInSeasonId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_delete_team_in_season' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_in_season_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamInSeasonId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTeamByTeamInSeasonIdMutation,
  DeleteTeamByTeamInSeasonIdMutationVariables
>;
export const DeleteTeamGroupOfTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTeamGroupOfTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_teams_club_group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'teamId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'club_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'clubId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTeamGroupOfTeamMutation,
  DeleteTeamGroupOfTeamMutationVariables
>;
export const MigrateTeamSeasonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MigrateTeamSeason' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamInSeasonIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_migrate_to_season' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_in_season_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamInSeasonIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nb_teams' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MigrateTeamSeasonMutation,
  MigrateTeamSeasonMutationVariables
>;
export const InsertTeamInClubGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertTeamInClubGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'existing_team_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'new_club_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rank' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'smallint' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_teams_club_group_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'club_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'new_club_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'custom_name_in_club' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rank' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rank' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'existing_team_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertTeamInClubGroupMutation,
  InsertTeamInClubGroupMutationVariables
>;
export const ReorderTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReorderTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'club_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'team_ids' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams_set_rank' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'team_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'team_ids' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'club_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReorderTeamsMutation,
  ReorderTeamsMutationVariables
>;
export const ReorderFavoriteTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReorderFavoriteTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'teams_team_has_fans_updates' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_teams_team_has_fans_many' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReorderFavoriteTeamsMutation,
  ReorderFavoriteTeamsMutationVariables
>;
export const UpdateTeamCompetitionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTeamCompetition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'competitionHasTeamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newTeamInSeasonId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_competitions_competition_has_teams_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'competitionHasTeamId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'custom_team_in_season_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newTeamInSeasonId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_in_season_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTeamCompetitionMutation,
  UpdateTeamCompetitionMutationVariables
>;
export const UpdateChallengesOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChallengesOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'tournaments_challenge_updates',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_challenge_many' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChallengesOrderMutation,
  UpdateChallengesOrderMutationVariables
>;
export const UpdatePhasesOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePhasesOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'tournaments_phase_updates' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_phase_many' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePhasesOrderMutation,
  UpdatePhasesOrderMutationVariables
>;
