import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  ScrapingUpdateNextRoundsDocument,
  ScrapingUpdateNextRoundsMutationVariables,
} from './Resynchronisation.mutation.generated';

export const useScrapingUpdateNextRounds = () => {
  const [runMutation, status] = useMutation(ScrapingUpdateNextRoundsDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: ScrapingUpdateNextRoundsMutationVariables) => {
      return runMutation({
        variables,
      });
    },
    [runMutation]
  );

  return { execute, status };
};
