import { DefaultLayoutOptions } from '@scorenco/next';

import { BackofficeLayout } from '../BackofficeLayout';

import { CurrentClubLayoutComponent } from './CurrentClubLayoutComponent';

const getLayoutFn =
  ({ navigation = {} }: DefaultLayoutOptions) =>
  (page) =>
    (
      <CurrentClubLayoutComponent {...navigation}>
        {page}
      </CurrentClubLayoutComponent>
    );

export const buildGetLayout = (layoutProps = {}, getLayouts = []) => {
  return BackofficeLayout.buildGetLayout(layoutProps, [
    getLayoutFn(layoutProps),
    ...getLayouts,
  ]);
};
