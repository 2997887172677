import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { useQuery } from '@apollo/client';
import { CrispService, Types, useTranslation } from '@scorenco/core';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { ErrorPageContainer } from '@/components';
import { T_KEYS } from '@/translations';

import { GetCurrentClubDocument } from './CurrentClubContext.query.generated';

export const CurrentClubContext = createContext<
  CurrentClubContextProps | undefined
>(undefined);

export const useCurrentClubContext = (): CurrentClubContextProps => {
  const context = useContext(CurrentClubContext);
  if (!context) {
    throw new Error(
      'useCurrentClubContext must be inside an CurrentClubContext'
    );
  }
  return context;
};

export type CurrentClubContextProps = {
  currentClub: Types.Clubs_Club & { is_organization: boolean };
};

export const CurrentClubProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const clubId = Number(router.query.clubId);
  const { t } = useTranslation();
  const getClubQuery = useQuery(GetCurrentClubDocument, {
    variables: {
      clubId,
    },
    skip: !clubId,
    context: {
      role: 'club',
    },
  });

  useEffect(() => {
    if (router.query.clubId) {
      getClubQuery.refetch({
        clubId,
      });
    }
  }, [clubId]);

  const currentClub = getClubQuery.data?.clubs_club_by_pk;

  useEffect(() => {
    if (currentClub) {
      CrispService.addSegment(
        [
          currentClub?.sport?.slug,
          (currentClub?.customer_tournament_start ||
            currentClub?.customer_clubhouse_start ||
            currentClub?.customer_monsiteclub_start ||
            currentClub?.customer_planner_start ||
            currentClub?.customer_premium_start) &&
            'client',
        ].filter(Boolean),
        true
      );
    }
  }, [currentClub]);

  const context = useMemo(
    () => ({
      currentClub: {
        ...currentClub,
        is_organization: currentClub?.organizations?.length > 0,
      },
    }),
    [currentClub]
  );

  if (!currentClub) {
    if (getClubQuery.loading) {
      return null;
    }

    return <ErrorPageContainer />;
  }

  return (
    <>
      <Head>
        <title>
          {router.route.split('/')[2] &&
            t(
              T_KEYS[
                router.route.split('/')[2].toUpperCase().replace('-', '') +
                  '_TITLE'
              ]
            ) + ' / '}
          {currentClub?.name} | Score'n'co Administration
        </title>
      </Head>
      <CurrentClubContext.Provider value={context}>
        {children}
      </CurrentClubContext.Provider>
    </>
  );
};
