import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import { ColorInput, Modal } from '@scorenco/components';
import { useGroupUpdate, useTranslation } from '@scorenco/core';
import { Types } from '@scorenco/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { string, object } from 'yup';

import { SelectEmoji } from '@/components';
import { T_KEYS } from '@/translations';

const createCommunityGroupSchema = object().shape({
  name: string()
    .max(20, T_KEYS.ERROR_TOLONG_MAX20)
    .required(T_KEYS.ERROR_REQUIRED),
  // emoji: yup.string().required('emoji required'),
  color: string().required(T_KEYS.ERROR_REQUIRED),
});

export const CommunityEditGroupModal = ({
  closeModal,
  group,
}: {
  closeModal: () => void;
  group: Types.Members_Group;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const updateGroupMutation = useGroupUpdate();

  const formik = useFormik({
    initialValues: {
      id: group.id,
      name: group.name,
      emoji: group.emoji,
      color: group.color,
    },
    validationSchema: createCommunityGroupSchema,
    onSubmit: async (values) => {
      try {
        await updateGroupMutation.execute({
          id: group.id,
          name: values.name,
          emoji: values.emoji,
          color: values.color,
        });
        closeModal();
        enqueueSnackbar(t(T_KEYS.SUCCESS_EDIT), {
          variant: 'success',
        });
        formik.resetForm();
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Modal
      closeModal={closeModal}
      title={t(T_KEYS.EDITCOMMUNITYGROUP_TITLE)}
      onSubmit={formik.handleSubmit}
    >
      <DialogContent>
        <Stack spacing={4} mt={2}>
          <TextField
            id="name"
            name="name"
            label={t(T_KEYS.CREATECOMMUNITYGROUP_NAME_FIELD_LABEL)}
            required
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && t(formik.errors.name)}
          />
          <ColorInput
            sx={{ width: '100%' }}
            popupId="color"
            value={formik.values.color}
            onChange={(value) => {
              formik.setFieldValue('color', value);
            }}
            error={formik.touched.color && Boolean(formik.errors.color)}
            helperText={formik.touched.color && t(formik.errors.color)}
          />
          <SelectEmoji
            popupId="emoji"
            label={t(T_KEYS.CREATECOMMUNITYGROUP_EMOJI_FIELD_LABEL)}
            value={formik.values.emoji}
            onChange={(emoji) => {
              formik.setFieldValue('emoji', emoji);
            }}
            error={formik.touched.emoji && Boolean(formik.errors.emoji)}
            helperText={formik.touched.emoji && t(formik.errors.emoji)}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={updateGroupMutation.status.loading}
        >
          {t(T_KEYS.EDIT)}
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
};
