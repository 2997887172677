import { useCallback, useMemo, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  Paper,
  PaperProps,
  Stack,
  Chip,
  Divider,
  CircularProgress,
  Typography,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Box,
} from '@mui/material';
import { useAlgoliaContext } from '@scorenco/components';
import { RouteLink, Types, useTranslation } from '@scorenco/core';

import { useCurrentClubContext } from '@/layouts';
import { T_KEYS } from '@/translations';

import { useMatchRowFormContext } from '../../pages/[clubId]/matchs/planner/_internal/MatchRowForm/MatchRowFormContext';

import { GetAuthorizedNewDocument } from './SearchMembersInput.query.generated';

type FilterValues = 'teams' | 'members' | 'groups';

export type SearchMembersFiltersTeamProps = PaperProps & {
  teamId?: number;
  previousPlayingTeam?: Types.Teams_Team_In_Season;
  nextPlayingTeam?: Types.Teams_Team_In_Season;
  taskId?: number;
};

export const SearchMembersFilters = ({
  children,
  teamId,
  previousPlayingTeam,
  nextPlayingTeam,
  taskId,
  ...rest
}: SearchMembersFiltersTeamProps) => {
  const { setFilters } = useAlgoliaContext();
  const { currentClub } = useCurrentClubContext();
  const { t } = useTranslation();
  const { event } = useMatchRowFormContext();
  const [teamSort, setTeamSort] = useState('all_others');

  const handleChange = (teamSort) => {
    setTeamSort(teamSort);
  };

  // Récupération des groupes et équipes autorisés pour ce permissionType (toutes les tâches dynamiques)
  const getAuthorizedNew = useQuery(GetAuthorizedNewDocument, {
    variables: {
      teamId,
      taskId: taskId,
    },
    skip: !teamId || !taskId,
    context: {
      role: 'club',
    },
  });

  const permissions = getAuthorizedNew.data?.teams_team_by_pk?.team_tasks?.[0];

  const groups = permissions?.authorized_groups;

  // On sépare les équipes et les groupes
  const teams = useMemo<Types.Teams_Team_In_Season[]>(
    () =>
      [
        previousPlayingTeam,
        nextPlayingTeam,
        ...(permissions?.authorized_teams?.reduce((acc, team) => {
          const teamInSeason = {
            ...team.team?.seasons?.[0],
            custom_name_in_club:
              team.team?.club_groups?.find(
                (cg) => cg?.club_id === currentClub.id
              )?.custom_name_in_club ||
              team.team?.seasons?.[0]?.custom_name_in_club,
          } as Types.Teams_Team_In_Season;
          // On supprime les équipes déjà présentes dans le tableau
          if (
            teamInSeason.id === previousPlayingTeam?.id ||
            teamInSeason.id === nextPlayingTeam?.id ||
            !teamInSeason
          ) {
            return acc;
          }
          return [...acc, teamInSeason];
        }, [] as Types.Teams_Team_In_Season[]) || []),
      ].filter((team): team is Types.Teams_Team_In_Season => Boolean(team)),
    [
      previousPlayingTeam,
      nextPlayingTeam,
      permissions?.authorized_teams,
      currentClub.id,
    ]
  );

  // État du filtre global (Tous les membres / équipes / groupes)
  const [currentFilter, setCurrentFilter] = useState<FilterValues>('members');
  const handleFilter = useCallback((filter: FilterValues) => {
    setCurrentFilter(filter);
  }, []);

  // État du filtre spécifique pour les équipes ([teamId])
  const [currentTeamFilter, setCurrentTeamFilter] = useState(
    teams?.map((team) => team.id)
  );
  const handleTeamFilter = useCallback((team) => {
    setCurrentTeamFilter(team);
  }, []);

  // État du filtre spécifique pour les groupes ([groupId])
  const [currentGroupFilter, setCurrentGroupFilter] = useState(
    groups?.map((group) => group.group_id)
  );
  const handleGroupFilter = useCallback((group) => {
    setCurrentGroupFilter(group);
  }, []);

  // Setter des filtres pour Algolia
  useEffect(() => {
    setFilters({
      club_id: [currentClub.id],
      'group_list.id':
        currentFilter === 'groups' ? currentGroupFilter : undefined,
      'team_list.id': currentFilter === 'teams' ? currentTeamFilter : undefined,
      is_currently_in_the_club: [true],
    });
  }, [currentFilter, currentTeamFilter, currentGroupFilter]);

  return (
    <Paper sx={{ minWidth: '16rem' }} {...rest}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        padding="0.5rem"
        paddingTop="1rem"
        spacing={1}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        <Chip
          label={t(T_KEYS.MATCHS_PLANNER_ALLMEMBERS)}
          size="small"
          variant={currentFilter === 'members' ? 'filled' : 'outlined'}
          onClick={() => {
            handleFilter('members');
          }}
          sx={{ fontSize: '0.7rem', height: '1.3rem', lineHeight: 1 }}
        />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Chip
            label={t(T_KEYS.TEAMS_TITLE)}
            size="small"
            variant={currentFilter === 'teams' ? 'filled' : 'outlined'}
            disabled={!teams?.length}
            onClick={() => {
              handleFilter('teams');
            }}
            sx={{ fontSize: '0.7rem', height: '1.3rem', lineHeight: 1 }}
          />
          <Chip
            label={
              getAuthorizedNew.loading ? (
                <Stack direction="row" spacing={1}>
                  <CircularProgress size="0.7rem" />{' '}
                  <span>{t(T_KEYS.CLUB_NAVIGATION_GROUPS)}</span>
                </Stack>
              ) : (
                t(T_KEYS.CLUB_NAVIGATION_GROUPS)
              )
            }
            size="small"
            variant={currentFilter === 'groups' ? 'filled' : 'outlined'}
            disabled={!groups?.length}
            onClick={() => {
              handleFilter('groups');
            }}
            sx={{ fontSize: '0.7rem', height: '1.3rem', lineHeight: 1 }}
          />
        </Stack>
        {!permissions?.authorized_groups?.length &&
          !permissions?.authorized_teams?.length && (
            <RouteLink
              href={`/${currentClub.id}/teams/${event?.playing_teams?.[0]?.team_in_season_id}/rules`}
            >
              <Typography variant="caption" color="error" component="a">
                Cliquez ici pour ajouter des règles de gestion
              </Typography>
            </RouteLink>
          )}
      </Stack>
      <Divider />
      {currentFilter === 'teams' && (
        <>
          <FormControl
            sx={{
              width: '90%',
              margin: '1rem auto',
              display: 'flex',
              flexDirection: 'column',
              fontSize: '0.5rem',
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            <FormLabel
              sx={{
                width: '100%',
                textAlign: 'center',
                fontSize: '0.8rem',
              }}
            >
              Tri des équipes
            </FormLabel>
            <RadioGroup
              id="sort"
              name="sort"
              value={teamSort}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              {/* Bouton radio pour l'équipe précédente à jouer */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  value="previous"
                  control={<Radio size="small" />}
                  sx={{ m: 0 }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  label=""
                />
                <Box
                  style={{ fontSize: '0.75rem', cursor: 'pointer' }}
                  onClick={() => {
                    handleChange('previous');
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  Équipe précédente à jouer
                </Box>
              </Box>

              {/* Bouton radio pour l'équipe suivante à jouer */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  value="next"
                  control={<Radio size="small" />}
                  sx={{ m: 0 }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  label=""
                />
                <Box
                  style={{ fontSize: '0.75rem', cursor: 'pointer' }}
                  onClick={() => {
                    handleChange('next');
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  Équipe suivante à jouer
                </Box>
              </Box>

              {/* Bouton radio pour toutes les autres équipes */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  value="all_others"
                  control={<Radio size="small" />}
                  sx={{ m: 0 }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                  label=""
                />
                <Box
                  style={{ fontSize: '0.75rem', cursor: 'pointer' }}
                  onClick={() => {
                    handleChange('all_others');
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  Toutes les équipes
                </Box>
              </Box>
            </RadioGroup>
          </FormControl>
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            maxWidth="auto"
            padding="0.5rem"
            paddingBottom={0}
            marginBottom={1}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {teamSort === 'previous' ? (
              <Chip
                key={teams?.[0]?.id}
                label={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    {teams?.[0]?.id === previousPlayingTeam?.id && (
                      <KeyboardArrowUp
                        sx={{
                          fontSize: '1.2rem',
                        }}
                      />
                    )}
                    {previousPlayingTeam?.custom_name_in_club}
                  </span>
                }
                size="small"
                variant={
                  currentTeamFilter?.length === 1 &&
                  currentTeamFilter?.[0] === previousPlayingTeam?.id
                    ? 'filled'
                    : 'outlined'
                }
                onClick={() => {
                  handleTeamFilter([previousPlayingTeam?.id]);
                }}
                sx={{
                  fontSize: '0.6rem',
                  height: '1.2rem',
                  lineHeight: 'normal',
                  m: 0.5,
                }}
              />
            ) : teamSort === 'next' ? (
              <Chip
                key={teams?.[1]?.id}
                label={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    {teams?.[1]?.id === nextPlayingTeam?.id && (
                      <KeyboardArrowDown
                        sx={{
                          fontSize: '1.2rem',
                        }}
                      />
                    )}
                    {nextPlayingTeam?.custom_name_in_club}
                  </span>
                }
                size="small"
                variant={
                  currentTeamFilter?.length === 1 &&
                  currentTeamFilter?.[0] === nextPlayingTeam?.id
                    ? 'filled'
                    : 'outlined'
                }
                onClick={() => {
                  handleTeamFilter([nextPlayingTeam?.id]);
                }}
                sx={{
                  fontSize: '0.6rem',
                  height: '1.2rem',
                  lineHeight: 'normal',
                  m: 0.5,
                }}
              />
            ) : teamSort === 'all_others' || teamSort === '' ? (
              teams?.map((team, i) => {
                return (
                  <Chip
                    key={i}
                    label={
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'uppercase',
                        }}
                      >
                        {team.id === previousPlayingTeam?.id && (
                          <KeyboardArrowUp
                            sx={{
                              fontSize: '1.2rem',
                            }}
                          />
                        )}
                        {team.id === nextPlayingTeam?.id && (
                          <KeyboardArrowDown
                            sx={{
                              fontSize: '1.2rem',
                            }}
                          />
                        )}
                        {team.custom_name_in_club}
                      </span>
                    }
                    size="small"
                    variant={
                      currentTeamFilter?.length === 1 &&
                      currentTeamFilter?.[0] === team.id
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => {
                      handleTeamFilter([team.id]);
                    }}
                    sx={{
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      lineHeight: 'normal',
                      m: 0.5,
                    }}
                  />
                );
              })
            ) : null}
          </Stack>
          <Divider />
        </>
      )}
      {currentFilter === 'groups' && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            maxWidth="auto"
            padding="0.5rem"
            paddingBottom={0}
            marginBottom={1}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {groups?.map(
              (group, i) =>
                group.group && (
                  <Chip
                    key={i}
                    label={
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textTransform: 'uppercase',
                        }}
                      >
                        {group.group.emoji} {group.group.name}
                      </span>
                    }
                    size="small"
                    variant={
                      currentGroupFilter?.length === 1 &&
                      currentGroupFilter?.[0] === group.group.id
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => {
                      handleGroupFilter([group.group.id]);
                    }}
                    sx={{
                      fontSize: '0.6rem',
                      height: '1.2rem',
                      lineHeight: 'normal',
                      m: 0.5,
                      borderColor: group.group.color,
                    }}
                  />
                )
            )}
          </Stack>

          <Divider />
        </>
      )}
      {children}
    </Paper>
  );
};
