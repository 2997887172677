import { useMemo } from 'react';

import { LocationOn } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import { PlaceType } from '@scorenco/core';
import parse from 'autosuggest-highlight/parse';

type GoogleMapsInputOptionProps = BoxProps<'li'> & {
  option: PlaceType | string;
};

const useNormalizeOption = (option: string | PlaceType) =>
  useMemo(() => {
    if (typeof option === 'string') {
      return { highlightTexts: [{ text: option, highlight: false }] };
    }

    const matches = option.structured_formatting.main_text_matched_substrings;

    return {
      highlightTexts: parse(
        option.structured_formatting.main_text,
        matches?.map((match: any) => [
          match.offset,
          match.offset + match?.length,
        ])
      ),
      secondaryText: option.structured_formatting.secondary_text,
    };
  }, [option]);

export const GoogleMapsInputOption = ({
  option,
  ...rest
}: GoogleMapsInputOptionProps) => {
  const { highlightTexts, secondaryText } = useNormalizeOption(option);

  return (
    <Box component="li" {...rest}>
      <Grid container alignItems="center">
        <Grid item>
          <Box component={LocationOn} sx={{ color: 'text.secondary', mr: 2 }} />
        </Grid>

        <Grid item xs>
          <div>
            {highlightTexts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
              >
                {part.text}
              </span>
            ))}
          </div>
          {secondaryText && (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mt: 0, mb: 0 }}
            >
              {secondaryText}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
