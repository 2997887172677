import { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

import { Types } from '../../types';

import {
  EquipmentAddDocument,
  EquipmentAddMutationVariables,
} from './Equipment.mutation.generated';

export const useEquipmentAdd = () => {
  const [runMutation, status] = useMutation(EquipmentAddDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: EquipmentAddMutationVariables) => {
      return runMutation({
        variables,
        update: (cache, res) => {
          const equipments = cache.readQuery<{
            places_equipment: Types.Places_Equipment[];
          }>({
            query: gql`
              query GetEquipments($clubId: Int!) {
                places_equipment(where: { club_id: { _eq: $clubId } }) {
                  id
                }
              }
            `,
            variables: {
              clubId: variables.clubId,
            },
          });

          cache.writeQuery({
            query: gql`
              query GetEquipments($clubId: Int!) {
                places_equipment(where: { club_id: { _eq: $clubId } }) {
                  id
                }
              }
            `,
            variables: {
              clubId: variables.clubId,
            },
            data: {
              places_equipment: [
                ...(equipments?.places_equipment || []),
                res?.data?.insert_places_equipment_one,
              ],
            },
          });
        },
      });
    },
    [runMutation]
  );

  return { execute, status };
};
