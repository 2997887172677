import { css } from '@emotion/react';
import { Button, Stack } from '@mui/material';
import { ClubLogo } from '@scorenco/components';
import { useClubUpdateLogo, useTranslation } from '@scorenco/core';
import { useSnackbar } from 'notistack';

import { T_KEYS } from '@/translations';

type UpdateLogoProps = {
  clubLogo?: string;
  clubId: number;
  disabled?: boolean;
};

export const UpdateLogo = ({
  clubLogo,
  clubId,
  disabled = false,
  ...rest
}: UpdateLogoProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { changeImage } = useClubUpdateLogo(clubId);

  return (
    <Stack
      spacing={2}
      width="100%"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <ClubLogo
        css={() => css`
          width: 5rem;
          height: 5rem;
          object-fit: contain;
        `}
        logo={clubLogo}
      />
      <Button
        disabled={disabled}
        onClick={async () => {
          await changeImage();
          enqueueSnackbar(t(T_KEYS.SUCCESS_ADD), {
            variant: 'success',
          });
        }}
      >
        {t(T_KEYS.PICTURE_INPUT_CHANGE_BUTTON)}
      </Button>
    </Stack>
  );
};
