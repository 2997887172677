import { useCallback } from 'react';

import { gql, useApolloClient } from '@apollo/client';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { useRest, useTranslation } from '../../services';
import { T_KEYS } from '../../translations';
import { ImageUsage } from '../../types';

const isUserCancellation = (error: any) => {
  return (
    error?.message === 'User cancelled photos app' ||
    error?.message === 'User rejected permissions'
  );
};

export const useClubUpdateLogo = (clubId: number) => {
  const { del, post } = useRest();
  const { cache } = useApolloClient();
  const { t } = useTranslation();

  const changeImage = useCallback(async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        source: CameraSource.Photos,
        resultType: CameraResultType.Base64,
        promptLabelHeader: t(T_KEYS.PICTURE_TITLE) || undefined,
        promptLabelCancel: t(T_KEYS.PICTURE_CANCEL) || undefined,
        promptLabelPhoto: t(T_KEYS.PICTURE_FROM_PHOTOS) || undefined,
        promptLabelPicture: t(T_KEYS.PICTURE_TAKE_PICTURE) || undefined,
      });

      const response = await post('/uploads/images/', {
        image: image.base64String,
        usage: ImageUsage.ClubLogo,
        target_id: clubId,
      });

      cache.writeFragment({
        id: `clubs_club:${response.target_id}`,
        fragment: gql`
          fragment ClubLogo on clubs_club {
            logo
          }
        `,
        data: {
          __typename: 'clubs_club',
          logo: response.image,
        },
      });
    } catch (error: any) {
      if (isUserCancellation(error)) {
        return new Promise(() => {}); // Promesse qui ne se résout jamais
      }
      console.error('Error while updating club logo:', error);
      throw error;
    }
  }, [post, cache, clubId, t]);

  const deleteImage = useCallback(async () => {
    await del('/users/me/profile-picture/');
  }, [del, clubId]);

  return { changeImage, deleteImage };
};
