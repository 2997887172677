import { PropsWithChildren } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Locale, useTranslation } from '@scorenco/core';
import deLocale from 'date-fns/locale/de';
import frLocale from 'date-fns/locale/fr';

const localeMap = {
  [Locale.fr_FR]: frLocale,
  [Locale.de_CH]: deLocale,
};

export const DatePickersProvider = ({ children }: PropsWithChildren) => {
  const { locale } = useTranslation();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[locale]}
    >
      {children}
    </LocalizationProvider>
  );
};
