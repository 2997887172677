/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@scorenco/core';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetCurrentUserQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserQuery = {
  __typename: 'query_root';
  users_user?:
    | Array<{
        __typename: 'users_user';
        id: number;
        first_name?: string | undefined;
        username?: string | undefined;
      }>
    | undefined;
};

export type DashboardGetEventsToCheckValidatesQueryVariables = Types.Exact<{
  clubId: Types.Scalars['Int']['input'];
  startDate: Types.Scalars['timestamptz']['input'];
  endDate: Types.Scalars['timestamptz']['input'];
}>;

export type DashboardGetEventsToCheckValidatesQuery = {
  __typename: 'query_root';
  competitions_event_detail_by_club_id_between_dates?:
    | Array<{
        __typename: 'competitions_event_detail';
        date?: any | undefined;
        teams?: any | undefined;
        playing_teams?:
          | Array<{
              __typename: 'competitions_playing_team';
              id: number;
              role?: string | undefined;
              info?:
                | {
                    __typename: 'competitions_playing_team_info';
                    id: number;
                    status?: boolean | undefined;
                  }
                | undefined;
            }>
          | undefined;
      }>
    | undefined;
};

export const GetCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const DashboardGetEventsToCheckValidatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardGetEventsToCheckValidates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clubId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'timestamptz' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'competitions_event_detail_by_club_id_between_dates',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clubId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date_start' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'date_end' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playing_teams' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team_in_season' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'team' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_or' },
                                        value: {
                                          kind: 'ListValue',
                                          values: [
                                            {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'club',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'id',
                                                        },
                                                        value: {
                                                          kind: 'ObjectValue',
                                                          fields: [
                                                            {
                                                              kind: 'ObjectField',
                                                              name: {
                                                                kind: 'Name',
                                                                value: '_eq',
                                                              },
                                                              value: {
                                                                kind: 'Variable',
                                                                name: {
                                                                  kind: 'Name',
                                                                  value:
                                                                    'clubId',
                                                                },
                                                              },
                                                            },
                                                          ],
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                            {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: {
                                                    kind: 'Name',
                                                    value: 'club_groups',
                                                  },
                                                  value: {
                                                    kind: 'ObjectValue',
                                                    fields: [
                                                      {
                                                        kind: 'ObjectField',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'club_id',
                                                        },
                                                        value: {
                                                          kind: 'ObjectValue',
                                                          fields: [
                                                            {
                                                              kind: 'ObjectField',
                                                              name: {
                                                                kind: 'Name',
                                                                value: '_eq',
                                                              },
                                                              value: {
                                                                kind: 'Variable',
                                                                name: {
                                                                  kind: 'Name',
                                                                  value:
                                                                    'clubId',
                                                                },
                                                              },
                                                            },
                                                          ],
                                                        },
                                                      },
                                                    ],
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardGetEventsToCheckValidatesQuery,
  DashboardGetEventsToCheckValidatesQueryVariables
>;
