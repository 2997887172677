import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Home, DirectionsCar } from '@mui/icons-material';
import { Typography, Stack, Box, Divider, Button } from '@mui/material';
import { useTranslation, RouteLink } from '@scorenco/core';
import { addDays, addWeeks, endOfWeek, format, startOfWeek } from 'date-fns';
import { DashboardGetEventsToCheckValidatesDocument } from 'pages/[clubId]/index.query.generated';

import { useCurrentClubContext } from '@/layouts';
import { RouteKey } from '@/routes';
import { T_KEYS } from '@/translations';

export const PlannerNote = () => {
  const { t } = useTranslation();

  const { currentClub } = useCurrentClubContext();

  const startDate = startOfWeek(new Date(), {
    weekStartsOn: 1,
  });
  const endDate = endOfWeek(new Date(), {
    weekStartsOn: 1,
  });

  const dates = [
    {
      startDate: startDate,
      endDate: endDate,
      isCurrent: true,
    },
    {
      startDate: addWeeks(startDate, 1),
      endDate: addWeeks(endDate, 1),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 2),
      endDate: addWeeks(endDate, 2),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 3),
      endDate: addWeeks(endDate, 3),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 4),
      endDate: addWeeks(endDate, 4),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 5),
      endDate: addWeeks(endDate, 5),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 6),
      endDate: addWeeks(endDate, 6),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 7),
      endDate: addWeeks(endDate, 7),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 8),
      endDate: addWeeks(endDate, 8),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 9),
      endDate: addWeeks(endDate, 9),
      isCurrent: false,
    },
    {
      startDate: addWeeks(startDate, 10),
      endDate: addWeeks(endDate, 10),
      isCurrent: false,
    },
  ];

  const query = useQuery(DashboardGetEventsToCheckValidatesDocument, {
    variables: {
      clubId: currentClub.id,
      startDate: addDays(dates?.[0]?.startDate, 1),
      endDate: dates?.[dates?.length - 1]?.endDate,
    },
    skip: !currentClub.id,
    context: {
      role: 'anonymous',
    },
  });

  return (
    <Stack spacing={2} sx={{ width: '100%' }} alignItems="center">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Home />
        <DirectionsCar sx={{ marginLeft: '1.5rem' }} />
      </Box>
      <Box sx={{ maxHeight: '10rem', overflow: 'auto', width: '100%' }}>
        {dates?.map((item, i) => {
          const eventsHome =
            query?.data?.competitions_event_detail_by_club_id_between_dates?.filter(
              (event) => {
                const eventDate = new Date(event.date).getTime();
                return (
                  eventDate > new Date(item.startDate).getTime() &&
                  eventDate < new Date(item.endDate).getTime() &&
                  event?.teams?.[0]?.is_team_of_club === 1
                );
              }
            );

          const validatesEventsHome = eventsHome?.filter(
            (event) => event.playing_teams[0]?.info?.status === true
          );

          const eventsAway =
            query?.data?.competitions_event_detail_by_club_id_between_dates?.filter(
              (event) => {
                const eventDate = new Date(event.date).getTime();
                return (
                  eventDate > new Date(item.startDate).getTime() &&
                  eventDate < new Date(item.endDate).getTime() &&
                  event?.teams?.[0]?.is_team_of_club === 0
                );
              }
            );

          const validatesEventsAway = eventsAway?.filter(
            (event) => event.playing_teams[0]?.info?.status === true
          );

          if (
            eventsHome &&
            validatesEventsHome &&
            eventsAway &&
            validatesEventsAway
          ) {
            return (
              <>
                <RouteLink
                  key={i}
                  href={{
                    routeKey: RouteKey.MatchsPlanner,
                    params: {
                      clubId: currentClub.id,
                      date: addDays(item.startDate, 1)
                        .toISOString()
                        .split('T')[0],
                    },
                  }}
                >
                  <Box
                    css={(theme) => css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      cursor: pointer;
                      padding: 0.5rem 0;
                      &:hover {
                        background-color: ${theme.vars.palette.background
                          .default};
                      }
                    `}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{
                          textTransform: 'uppercase',
                          lineHeight: 1.2,
                          fontWeight: 'bold',
                        }}
                      >
                        {`${format(item.startDate, 'dd/MM')} - ${format(
                          item.endDate,
                          'dd/MM'
                        )}`}
                      </Typography>
                      {item.isCurrent && (
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{
                            textTransform: 'uppercase',
                            lineHeight: 1.2,
                            fontSize: '0.8rem',
                          }}
                        >
                          {t(T_KEYS.PLANNER_THISWEEK)}
                        </Typography>
                      )}
                    </Box>

                    <Box>
                      <Typography
                        variant="h5"
                        component="span"
                        sx={{
                          marginRight: '1rem',
                          lineHeight: 1,
                          fontWeight: 'bold',
                          fontStyle: 'unset',
                          color:
                            validatesEventsHome.length < eventsHome.length
                              ? 'error.main'
                              : 'secondary.main',
                        }}
                      >
                        {validatesEventsHome.length}/{eventsHome.length}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="span"
                        sx={{
                          lineHeight: 1,
                          fontWeight: 'bold',
                          fontStyle: 'unset',
                          color:
                            validatesEventsAway.length < eventsAway.length
                              ? 'error.main'
                              : 'secondary.main',
                        }}
                      >
                        {validatesEventsAway.length}/{eventsAway.length}
                      </Typography>
                    </Box>
                  </Box>
                </RouteLink>
                <Divider />
              </>
            );
          } else {
            return null;
          }
        })}
      </Box>
      <RouteLink
        href={{
          routeKey: RouteKey.MatchsPlanner,
          params: {
            clubId: currentClub.id,
          },
        }}
      >
        <Button
          component="a"
          size="small"
          variant="contained"
          color="secondary"
          sx={{ m: '0 auto' }}
        >
          {t(T_KEYS.PLANNER_LAUNCH)}
        </Button>
      </RouteLink>
    </Stack>
  );
};
