import { useState } from 'react';

import { css } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  Stack,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Modal } from '@scorenco/components';
import {
  useScrapingGetNewCompetitions,
  useScrapingSynchronizeEventsThisWeek,
  useScrapingUpdateNextRounds,
  useTranslation,
} from '@scorenco/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { useCurrentClubContext } from '@/layouts';
import { T_KEYS } from '@/translations';

export const PlannerResyncModal = ({
  closeModal,
  startDate,
  endDate,
}: {
  closeModal: () => void;
  startDate: Date;
  endDate: Date;
}) => {
  const { currentClub } = useCurrentClubContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [resyncMode, setResyncMode] = useState(undefined);
  const scrapingGetNewCompetitionsMutation = useScrapingGetNewCompetitions();
  const scrapingUpdateNextRoundsMutation = useScrapingUpdateNextRounds();
  const scrapingSynchronizeEventsThisWeekMutation =
    useScrapingSynchronizeEventsThisWeek();

  const formik = useFormik({
    initialValues: {
      club_id: currentClub.id,
    },
    onSubmit: async () => {
      if (resyncMode === 'updateDatas') {
        try {
          await scrapingGetNewCompetitionsMutation.execute({
            clubId: currentClub.id,
          });
          closeModal();
          enqueueSnackbar(t(T_KEYS.RESYNCMODAL_LAUNCHED), {
            variant: 'success',
          });
          formik.resetForm();
        } catch (error) {
          console.error('error: ', error);
          enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
            variant: 'error',
          });
        }
      } else if (resyncMode === 'missingGames') {
        try {
          await scrapingUpdateNextRoundsMutation.execute({
            clubId: currentClub.id,
          });
          closeModal();
          enqueueSnackbar(t(T_KEYS.RESYNCMODAL_LAUNCHED), {
            variant: 'success',
          });
          formik.resetForm();
        } catch (error) {
          console.error('error: ', error);
          enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
            variant: 'error',
          });
        }
      } else if (resyncMode === 'synchronizeEventsThisWeek') {
        try {
          const start_date = startDate
            ? new Date(startDate).toISOString().split('T')[0]
            : undefined;
          const end_date = endDate
            ? new Date(endDate).toISOString().split('T')[0]
            : undefined;

          await scrapingSynchronizeEventsThisWeekMutation.execute({
            club_id: currentClub.id,
            start_date: start_date,
            end_date: end_date,
          });
          closeModal();
          enqueueSnackbar(t(T_KEYS.RESYNCMODAL_LAUNCHED), {
            variant: 'success',
          });
          formik.resetForm();
        } catch (error) {
          console.error('error: ', error);
          enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar(t(T_KEYS.RESYNC_MODE), {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Modal
      closeModal={closeModal}
      title={t(T_KEYS.RESYNC_TITLE)}
      onSubmit={formik.handleSubmit}
    >
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Stack spacing={2} direction="row">
            <ToggleButtonGroup
              value={resyncMode}
              exclusive
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                mode: string | null
              ) => {
                setResyncMode(mode);
                formik.resetForm();
              }}
            >
              <ToggleButton
                value="synchronizeEventsThisWeek"
                css={(theme) => css`
                  flex: 1;
                  text-transform: none;
                  color: ${theme.vars.palette.primary.main};
                  &.mui-selected: {
                    border-color: ${theme.vars.palette.primary.main};
                  }
                `}
              >
                Mettre à jour les matchs de la semaine
              </ToggleButton>
              <ToggleButton
                value="missingGames"
                css={(theme) => css`
                  flex: 1;
                  text-transform: none;
                  color: ${theme.vars.palette.primary.main};
                  &.mui-selected: {
                    border-color: ${theme.vars.palette.primary.main};
                  }
                `}
              >
                {t(T_KEYS.RESYNC_UPDATE_MATCH)}
              </ToggleButton>
              <ToggleButton
                value="updateDatas"
                css={(theme) => css`
                  flex: 1;
                  text-transform: none;
                  color: ${theme.vars.palette.primary.main};
                  &.mui-selected: {
                    border-color: ${theme.vars.palette.primary.main};
                  }
                `}
              >
                {t(T_KEYS.RESYNC_ADD_TEAM_EVENT)}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={
            scrapingGetNewCompetitionsMutation.status.loading ||
            scrapingUpdateNextRoundsMutation.status.loading
          }
        >
          {t(T_KEYS.RESYNCMODAL_LAUNCH_SYNC)}
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
};
