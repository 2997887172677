import { useState } from 'react';

import {
  Info,
  Home,
  HomeOutlined,
  DirectionsCar,
  DirectionsCarOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Modal } from '@scorenco/components';
import { Types, useTaskUpdate, useTranslation } from '@scorenco/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { SelectEmoji } from '@/components';
import { useCurrentClubContext } from '@/layouts';
import { T_KEYS } from '@/translations';

export const EditClubTask = ({
  closeModal,
  task,
}: {
  closeModal: () => void;
  task: Types.Tasks_Task;
}) => {
  const { currentClub } = useCurrentClubContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isHome, setIsHome] = useState(
    task.game_type === 'home' ? true : task.game_type === 'away' ? false : true
  );
  const [isAway, setIsAway] = useState(
    task.game_type === 'away' ? true : task.game_type === 'home' ? false : true
  );
  const taskUpdateMutation = useTaskUpdate();

  const formik = useFormik({
    initialValues: {
      clubId: currentClub.id,
      name: task.name,
      emoji: task.emoji || '💚',
      allowOfficials: task.allow_officials,
      gameType: task.game_type || '',
    },
    onSubmit: async (values) => {
      try {
        await taskUpdateMutation.execute({
          id: task.id,
          _set: {
            name: values.name,
            emoji: values.emoji,
            allow_officials: values.allowOfficials,
            game_type: isHome ? (isAway ? '' : 'home') : 'away',
          },
        });
        closeModal();
        enqueueSnackbar(t(T_KEYS.SUCCESS_ADD), {
          variant: 'success',
        });
        formik.resetForm();
      } catch (error) {
        console.error('error: ', error);
        enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Modal
      closeModal={closeModal}
      title={`Modifier le type de convocation ${task.name}`}
      onSubmit={formik.handleSubmit}
    >
      <DialogContent>
        <Stack spacing={3}>
          <Box sx={{ my: 2 }}>
            <TextField
              fullWidth
              id="name"
              name="name"
              autoFocus
              required
              label="Nom"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && t(formik.errors.name)}
              slotProps={{
                htmlInput: { maxLength: 30 },
              }}
            />
            {formik.values.name.length >= 20 && (
              <Typography variant="caption" color="error">
                {t(T_KEYS.CLUB_TASKS_NAME_LIMIT, {
                  count: 30 - formik.values.name.length,
                })}
              </Typography>
            )}
          </Box>
          <Box sx={{ flexDirection: 'row' }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="allowOfficials"
                  name="allowOfficials"
                  checked={formik.values.allowOfficials}
                  onChange={formik.handleChange}
                  defaultChecked={formik.values.allowOfficials}
                />
              }
              label={t(T_KEYS.CLUB_TASKS_OFFICIAL_HELP)}
            />

            <Tooltip title="Par exemple l'arbitrage peut être effectué officiellement ou par un bénévole selon les équipes.">
              <IconButton size="small" color="primary">
                <Info />
              </IconButton>
            </Tooltip>
          </Box>
          <InputLabel>{t(T_KEYS.CLUB_TASKS_GAME_TYPE)}</InputLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Paper
              variant="bordered"
              sx={{ padding: '1rem', height: '5rem', width: '9rem', mr: 2 }}
              onClick={() => (isHome && !isAway ? null : setIsHome(!isHome))}
            >
              {isHome ? <Home /> : <HomeOutlined color="disabled" />}

              <Typography
                variant="body1"
                color={isHome ? 'primary' : '#00000042'}
              >
                {t(T_KEYS.PLANNER_HOME)}
              </Typography>
            </Paper>
            <Paper
              variant="bordered"
              sx={{ padding: '1rem', height: '5rem', width: '9rem', mr: 2 }}
              onClick={() => (!isHome && isAway ? null : setIsAway(!isAway))}
            >
              {isAway ? (
                <DirectionsCar />
              ) : (
                <DirectionsCarOutlined color="disabled" />
              )}

              <Typography
                variant="body1"
                color={isAway ? 'primary' : '#00000042'}
              >
                {t(T_KEYS.PLANNER_AWAY)}
              </Typography>
            </Paper>
          </Box>
          <SelectEmoji
            popupId="emoji"
            label="Emoji"
            error={formik.touched.emoji && Boolean(formik.errors.emoji)}
            helperText={formik.touched.emoji && t(formik.errors.emoji)}
            value={formik.values.emoji}
            onChange={(emoji) => {
              formik.setFieldValue('emoji', emoji);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={taskUpdateMutation.status.loading}
        >
          {t(T_KEYS.EDIT)}
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
};
