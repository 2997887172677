import { useCallback, useState } from 'react';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { useRest, useTranslation } from '../../services';
import { T_KEYS } from '../../translations';

const isUserCancellation = (error: any) => {
  return (
    error?.message === 'User cancelled photos app' ||
    error?.message === 'User rejected permissions'
  );
};

export const useUploadImage = (
  imageUsage: number,
  target_id?: number,
  isBackoffice: boolean = false
) => {
  const { post } = useRest();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const uploadImage = useCallback(async () => {
    setLoading(true);
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        source: CameraSource.Photos,
        resultType: CameraResultType.Base64,
        promptLabelHeader: t(T_KEYS.PICTURE_TITLE) || undefined,
        promptLabelCancel: t(T_KEYS.PICTURE_CANCEL) || undefined,
        promptLabelPhoto: t(T_KEYS.PICTURE_FROM_PHOTOS) || undefined,
        promptLabelPicture: t(T_KEYS.PICTURE_TAKE_PICTURE) || undefined,
      });

      const response = await post('/uploads/images/', {
        image: image.base64String,
        usage: imageUsage,
        target_id,
      });

      setLoading(false);
      return response.image;
    } catch (error: any) {
      setLoading(false);
      if (isUserCancellation(error)) {
        return new Promise(() => {}); // Promesse qui ne se résout jamais
      }
      console.error('Error while uploading image:', error);
      throw error;
    }
  }, [post, t, imageUsage, target_id]);

  const captureImage = useCallback(async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        width: 800,
        height: 800,
        allowEditing: false,
        source: isBackoffice ? CameraSource.Photos : CameraSource.Prompt,
        resultType: CameraResultType.Base64,
        promptLabelHeader: t(T_KEYS.PICTURE_TITLE) || undefined,
        promptLabelCancel: t(T_KEYS.PICTURE_CANCEL) || undefined,
        promptLabelPhoto: t(T_KEYS.PICTURE_FROM_PHOTOS) || undefined,
        promptLabelPicture: t(T_KEYS.PICTURE_TAKE_PICTURE) || undefined,
        webUseInput: true,
      });

      return `data:image/png;base64,${image.base64String}`;
    } catch (error: any) {
      if (isUserCancellation(error)) {
        return new Promise(() => {}); // Promesse qui ne se résout jamais
      }
      console.error('Error while capturing image:', error);
      throw error;
    }
  }, [t, isBackoffice]);

  const uploadCapturedImage = useCallback(
    async (base64Image: string) => {
      setLoading(true);
      try {
        const response = await post('/uploads/images/', {
          image: base64Image,
          usage: imageUsage,
          target_id,
        });

        setLoading(false);
        return response.image;
      } catch (error) {
        setLoading(false);
        console.error('Error while uploading image:', error);
        throw error;
      }
    },
    [post, imageUsage, target_id]
  );

  return {
    uploadImage,
    captureImage,
    uploadCapturedImage,
    uploadLoading: loading,
  };
};
