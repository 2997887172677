import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { Verified, ArrowForwardIos } from '@mui/icons-material';
import { Typography, Button, Box, Paper, Stack } from '@mui/material';
import {
  ClubLogo,
  Image,
  SlideshowInfinite,
  useItems,
} from '@scorenco/components';
import { CrispService, useTranslation } from '@scorenco/core';
import { useCurrentClubContext } from 'layouts';
import { T_KEYS } from 'translations';

import { GetClubsPlannerDocument } from './_internal/GetClubs.query.generated';

export const PremiumFeatureConvocations = () => {
  const { t } = useTranslation();

  const { currentClub } = useCurrentClubContext();

  const openCrisp = () => {
    CrispService.show();
    CrispService.sendMessageToUser(
      `Bonjour ${currentClub?.name}, comment pouvons-nous vous renseigner sur le module Convocations ?
       Nous pouvons vous proposer une phase de test gratuite et sans engagement pour ce module. 😊`
    );
  };

  const getClubsPlanner = useQuery(GetClubsPlannerDocument, {
    context: {
      role: 'club',
    },
  });

  const clubsPlanner = useItems(getClubsPlanner.data?.clubs_club, 20);

  return (
    <Stack direction="column">
      <Paper variant="bordered">
        <Verified sx={{ color: 'premium.main', fontSize: '2.5rem', mb: 1 }} />
        <Typography
          variant="h4"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.PLANNER_PREMIUM_TITLE),
          }}
        />

        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
          dangerouslySetInnerHTML={{
            __html: t(T_KEYS.PLANNER_PREMIUM_TEXT),
          }}
        />

        <Button
          onClick={openCrisp}
          sx={{ mt: '1rem' }}
          variant="contained"
          color="premium"
          endIcon={<ArrowForwardIos />}
        >
          {t(T_KEYS.PLANNER_BUTTON_DEMO)}
        </Button>
      </Paper>

      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        <Typography
          variant="body1"
          component="p"
          align="center"
          sx={{ maxWidth: '40rem' }}
        >
          En lien avec notre stratégie de devenir un outil majeur dans la
          communication des clubs de sport amateur, nous avons développé un
          module de gestion des bénévoles automatisé pour faciliter votre
          organisation et faire gagner du temps.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '33%' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/convocations/convocations.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Un planning en ligne et partagé
            </Typography>
          </Box>
          <Box sx={{ width: '33%' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/convocations/resynchronisation.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Concentrez-vous sur les convocations
            </Typography>
          </Box>
          <Box sx={{ width: '33%' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/convocations/convoquer.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Définissez les responsabilités et convoquez automatiquement
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Paper variant="bordered" sx={{ marginTop: '2rem' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          <span className="highlight">Simplifier</span> la gestion de vos
          convocations !
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '33%' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/convocations/gestion.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Définissez les caractéristiques par défaut à appliquer
            </Typography>
          </Box>
          <Box sx={{ width: '33%' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/convocations/apercu.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Définissez différents groupes de bénévoles
            </Typography>
          </Box>
          <Box sx={{ width: '33%' }}>
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={`/images/convocations/groupes.png`}
            />
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ maxWidth: '40rem' }}
            >
              Aperçu rapide sur l'espace d'administration
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper variant="bordered" sx={{ marginTop: '2rem', overflow: 'hidden' }}>
        <Typography
          variant="h5"
          component="p"
          align="center"
          sx={{ fontStyle: 'unset', lineHeight: 1.3, mb: 2 }}
          css={(theme) => css`
            .highlight {
              background-color: ${theme.vars.palette.secondary.main};
              color: white;
              padding-right: 0.25rem;
              padding-left: 0.25rem;
            }
          `}
        >
          Ces clubs qui utilisent déjà l'outil
        </Typography>

        <Box
          sx={{
            width: '100%',
            height: '60px',
            position: 'relative',
          }}
        >
          <SlideshowInfinite
            length={clubsPlanner.length}
            elementRemSize={4}
            style={{ width: '100%', position: 'absolute', left: 0 }}
          >
            {clubsPlanner.map((club) => (
              <ClubLogo
                key={club?.id}
                logo={club?.logo}
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 3rem;
                  height: 3rem;
                  margin: 0 0.5rem;
                `}
              />
            ))}
          </SlideshowInfinite>
        </Box>
      </Paper>
    </Stack>
  );
};
