import { Paper } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import { useTranslation } from '@scorenco/core';

import { T_KEYS } from '@/translations';

import { useWidgetPreviewContext } from './WidgetPreviewContext';

export const WidgetPreview = ({ ...rest }: BoxProps) => {
  const { t } = useTranslation();
  const { widget } = useWidgetPreviewContext();

  if (!widget?.category || !widget?.id) {
    return (
      <Paper variant="bordered" sx={{ mb: 3, width: '100%' }}>
        {t(T_KEYS.WIDGETS_LOADING)}
      </Paper>
    );
  }

  return (
    <Box
      className="scorenco-widget"
      data-widget-type={widget.category}
      data-widget-id={widget.id}
      {...rest}
    >
      <Paper variant="bordered" sx={{ mb: 3, width: '100%' }}>
        {t(T_KEYS.WIDGETS_LOADING)}
      </Paper>
    </Box>
  );
};
