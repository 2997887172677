import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { Modal } from '@scorenco/components';
import { useTranslation } from '@scorenco/core';
import { useSnackbar } from 'notistack';

import { T_KEYS } from '@/translations';

export type DeleteModalProps = {
  closeModal: () => void;
  onValidate: () => Promise<void>;
  message: string;
  loading?: boolean;
  explanation?: string;
};

export const DeleteModal = ({
  closeModal,
  onValidate,
  message,
  loading,
  explanation,
}: DeleteModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Modal closeModal={closeModal} title={message}>
      {explanation && (
        <DialogContent>
          <Typography
            variant="body1"
            component="p"
            sx={{ textAlign: 'center' }}
          >
            {explanation}
          </Typography>
        </DialogContent>
      )}

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={async () => {
            try {
              await onValidate();

              enqueueSnackbar(t(T_KEYS.SUCCESS_DELETE), {
                variant: 'success',
              });
              closeModal();
            } catch (error) {
              console.error('error: ', error);
              enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
                variant: 'error',
              });
            }
          }}
        >
          {t(T_KEYS.DELETE)}
        </LoadingButton>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            closeModal();
          }}
        >
          {t(T_KEYS.CANCEL)}
        </Button>
      </DialogActions>
    </Modal>
  );
};
