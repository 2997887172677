import {
  PropsWithChildren,
  Children,
  cloneElement,
  ElementType,
  useCallback,
  useState,
  MouseEventHandler,
} from 'react';

import { BoxProps } from '@mui/material';

import { ModalContainer } from './ModalContainer';

type ModalLinkProps = Omit<BoxProps, 'open'> & {
  Modal: ElementType;
  modalProps?: any;
};

export const ModalLink = ({
  children,
  Modal,
  modalProps = {},
  onClick,
  ...rest
}: PropsWithChildren<ModalLinkProps>): JSX.Element => {
  const child = Children.only(children) as any as JSX.Element;
  const [open, setOpen] = useState(false);

  const openModal: MouseEventHandler = useCallback((event) => {
    if (onClick) {
      onClick(event as unknown as any);
    }

    event?.preventDefault();

    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  if (!child) {
    return child;
  }

  const clonedChild = cloneElement(child, {
    ...rest,
    onClick: openModal,
  }) as any as JSX.Element;

  return (
    <>
      {clonedChild}

      <ModalContainer
        closeModal={closeModal}
        open={open}
        modalProps={modalProps}
        Modal={Modal}
      />
    </>
  );
};
