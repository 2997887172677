import { css } from '@emotion/react';
import { Box, BoxProps } from '@mui/system';

const styles = {
  item: css`
    display: flex;
    align-items: center;
    width: 100%;

    &.is-vertical {
      text-align: center;
      flex-direction: column;
    }
  `,
  image: css`
    flex: 0 0 3rem;
    margin-right: 1rem;
    width: 3rem;
    height: 2rem;

    .is-vertical & {
      width: 100%;
      flex: 0 0 2rem;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  `,
  content: css`
    flex: 1;
  `,
  title: css`
    font-weight: 500;
    font-size: 1rem;
  `,
  desc: css`
    font-size: 0.75rem;
    margin-top: 1px;
  `,
};

export type ItemProps = BoxProps & {
  orientation?: 'horizontal' | 'vertical';
};

export const Item = ({
  children,
  orientation = 'horizontal',
  ...rest
}: ItemProps) => {
  return (
    <Box css={styles.item} className={`is-${orientation}`} {...rest}>
      {children}
    </Box>
  );
};

export type ItemImageProps = BoxProps;

export const ItemImage = ({ children, ...rest }: ItemImageProps) => {
  return (
    <Box css={styles.image} {...rest}>
      {children}
    </Box>
  );
};

export type ItemContentProps = BoxProps;

export const ItemContent = ({ children, ...rest }: ItemContentProps) => {
  return (
    <Box css={styles.content} {...rest}>
      {children}
    </Box>
  );
};

export type ItemTitleProps = BoxProps;

export const ItemTitle = ({ children, ...rest }: ItemTitleProps) => {
  return (
    <Box css={styles.title} {...rest}>
      {children}
    </Box>
  );
};

export type ItemDescProps = BoxProps;

export const ItemDesc = ({ children, ...rest }: ItemDescProps) => {
  return (
    <Box css={styles.desc} {...rest}>
      {children}
    </Box>
  );
};
