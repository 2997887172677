/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InsertTournamentMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  start_date: Types.Scalars['date']['input'];
  location?: Types.InputMaybe<Types.Scalars['String']['input']>;
  end_date: Types.Scalars['date']['input'];
  club_id: Types.Scalars['Int']['input'];
  sport_id: Types.Scalars['Int']['input'];
  logo?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type InsertTournamentMutation = {
  __typename: 'mutation_root';
  insert_tournaments_tournament?:
    | {
        __typename: 'tournaments_tournament_mutation_response';
        affected_rows?: number | undefined;
        returning?:
          | Array<{ __typename: 'tournaments_tournament'; id: number }>
          | undefined;
      }
    | undefined;
};

export type UpdateEngagmentTeamMutationVariables = Types.Exact<{
  engagmentId: Types.Scalars['Int']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type UpdateEngagmentTeamMutation = {
  __typename: 'mutation_root';
  update_tournaments_engagement_by_pk?:
    | {
        __typename: 'tournaments_engagement';
        id: number;
        team_id?: number | undefined;
        team?:
          | {
              __typename: 'tournaments_team';
              id: number;
              name?: string | undefined;
              logo?: string | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type UpdateGameInfosMutationVariables = Types.Exact<{
  updates:
    | Array<Types.Tournaments_Game_Updates>
    | Types.Tournaments_Game_Updates;
}>;

export type UpdateGameInfosMutation = {
  __typename: 'mutation_root';
  update_tournaments_game_many?:
    | Array<
        | {
            __typename: 'tournaments_game_mutation_response';
            returning?:
              | Array<{
                  __typename: 'tournaments_game';
                  id: number;
                  date?: any | undefined;
                  tournament_place_id?: number | undefined;
                }>
              | undefined;
          }
        | undefined
      >
    | undefined;
};

export type UpdateTournamentMutationVariables = Types.Exact<{
  tournamentId: Types.Scalars['Int']['input'];
  logo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  name: Types.Scalars['String']['input'];
  description: Types.Scalars['String']['input'];
  signup_link: Types.Scalars['String']['input'];
  location?: Types.InputMaybe<Types.Scalars['String']['input']>;
  start_date: Types.Scalars['date']['input'];
  end_date: Types.Scalars['date']['input'];
  email: Types.Scalars['String']['input'];
  phone: Types.Scalars['String']['input'];
  ad: Types.Scalars['String']['input'];
}>;

export type UpdateTournamentMutation = {
  __typename: 'mutation_root';
  update_tournaments_tournament_by_pk?:
    | { __typename: 'tournaments_tournament'; id: number }
    | undefined;
};

export type InsertChallengeByTournamentIdMutationVariables = Types.Exact<{
  tournamentId: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  gender: Types.Scalars['String']['input'];
  age_category: Types.Scalars['String']['input'];
}>;

export type InsertChallengeByTournamentIdMutation = {
  __typename: 'mutation_root';
  insert_tournaments_challenge_one?:
    | { __typename: 'tournaments_challenge'; id: number }
    | undefined;
};

export type InsertTeamByChallengeIdMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['Int']['input'];
  color_1?: Types.InputMaybe<Types.Scalars['String']['input']>;
  color_2?: Types.InputMaybe<Types.Scalars['String']['input']>;
  logo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  name: Types.Scalars['String']['input'];
  scorenco_team_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type InsertTeamByChallengeIdMutation = {
  __typename: 'mutation_root';
  insert_tournaments_team_one?:
    | { __typename: 'tournaments_team'; id: number }
    | undefined;
};

export type EditTeamByChallengeIdMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['Int']['input'];
  color_1?: Types.InputMaybe<Types.Scalars['String']['input']>;
  color_2?: Types.InputMaybe<Types.Scalars['String']['input']>;
  logo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  name: Types.Scalars['String']['input'];
}>;

export type EditTeamByChallengeIdMutation = {
  __typename: 'mutation_root';
  insert_tournaments_team_one?:
    | { __typename: 'tournaments_team'; id: number }
    | undefined;
};

export type EditTeamByTournamentsTeamIdMutationVariables = Types.Exact<{
  tournamentsTeamId: Types.Scalars['Int']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  logo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  color_2?: Types.InputMaybe<Types.Scalars['String']['input']>;
  color_1?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type EditTeamByTournamentsTeamIdMutation = {
  __typename: 'mutation_root';
  update_tournaments_team_by_pk?:
    | { __typename: 'tournaments_team'; id: number }
    | undefined;
};

export type DeleteTeamByTournamentsTeamIdMutationVariables = Types.Exact<{
  tournamentsTeamId: Types.Scalars['Int']['input'];
}>;

export type DeleteTeamByTournamentsTeamIdMutation = {
  __typename: 'mutation_root';
  delete_tournaments_team_by_pk?:
    | { __typename: 'tournaments_team'; id: number }
    | undefined;
};

export type UpdateChallengeRuleOneMutationVariables = Types.Exact<{
  ruleId: Types.Scalars['Int']['input'];
  rule?: Types.InputMaybe<Types.Tournaments_Challenge_Rule_Set_Input>;
  rankingPoints: Types.Tournaments_Ranking_Points_Set_Input;
  rankingRules:
    | Array<Types.Tournaments_Ranking_Rule_Insert_Input>
    | Types.Tournaments_Ranking_Rule_Insert_Input;
}>;

export type UpdateChallengeRuleOneMutation = {
  __typename: 'mutation_root';
  update_tournaments_challenge_rule_by_pk?:
    | {
        __typename: 'tournaments_challenge_rule';
        id: number;
        name?: string | undefined;
        rank?: any | undefined;
        nb_groups?: any | undefined;
        nb_teams?: any | undefined;
        nb_teams_in_final_phase?: any | undefined;
        period_duration?: any | undefined;
        rule_type?: any | undefined;
        time_between_periods?: any | undefined;
        period_nb?: any | undefined;
      }
    | undefined;
  update_tournaments_ranking_points?:
    | {
        __typename: 'tournaments_ranking_points_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
  delete_tournaments_ranking_rule?:
    | {
        __typename: 'tournaments_ranking_rule_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
  insert_tournaments_ranking_rule?:
    | {
        __typename: 'tournaments_ranking_rule_mutation_response';
        affected_rows?: number | undefined;
      }
    | undefined;
};

export type AddChallengePhaseMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['Int']['input'];
  phaseRank: Types.Scalars['Int']['input'];
}>;

export type AddChallengePhaseMutation = {
  __typename: 'mutation_root';
  tournaments_create_phase?:
    | { __typename: 'new_phase_response'; id: number }
    | undefined;
};

export type DeleteChallengePhaseMutationVariables = Types.Exact<{
  ruleId: Types.Scalars['Int']['input'];
}>;

export type DeleteChallengePhaseMutation = {
  __typename: 'mutation_root';
  tournaments_delete_phase?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type InvertGameTeamsMutationVariables = Types.Exact<{
  gameId: Types.Scalars['Int']['input'];
  newAwayId: Types.Scalars['Int']['input'];
  newHomeId: Types.Scalars['Int']['input'];
}>;

export type InvertGameTeamsMutation = {
  __typename: 'mutation_root';
  update_tournaments_game?:
    | {
        __typename: 'tournaments_game_mutation_response';
        affected_rows?: number | undefined;
        returning?:
          | Array<{
              __typename: 'tournaments_game';
              id: number;
              date?: any | undefined;
              name?: string | undefined;
              place?: string | undefined;
              url?: string | undefined;
              status?: string | undefined;
              home_score?: number | undefined;
              away_score?: number | undefined;
              home_id?: number | undefined;
              away_id?: number | undefined;
              home_engagement?:
                | {
                    __typename: 'tournaments_engagement';
                    id: number;
                    name?: string | undefined;
                    team?:
                      | {
                          __typename: 'tournaments_team';
                          id: number;
                          name?: string | undefined;
                          logo?: string | undefined;
                        }
                      | undefined;
                  }
                | undefined;
              away_engagement?:
                | {
                    __typename: 'tournaments_engagement';
                    id: number;
                    name?: string | undefined;
                    team?:
                      | {
                          __typename: 'tournaments_team';
                          id: number;
                          name?: string | undefined;
                          logo?: string | undefined;
                        }
                      | undefined;
                  }
                | undefined;
            }>
          | undefined;
      }
    | undefined;
};

export type UpdateTournamentPhaseMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  _set: Types.Tournaments_Phase_Set_Input;
}>;

export type UpdateTournamentPhaseMutation = {
  __typename: 'mutation_root';
  update_tournaments_phase_by_pk?:
    | { __typename: 'tournaments_phase'; name?: string | undefined }
    | undefined;
};

export type UpdateTournamentGameMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
}>;

export type UpdateTournamentGameMutation = {
  __typename: 'mutation_root';
  update_tournaments_game_by_pk?:
    | { __typename: 'tournaments_game'; name?: string | undefined }
    | undefined;
};

export type TournamentDeleteMutationVariables = Types.Exact<{
  tournamentId: Types.Scalars['Int']['input'];
}>;

export type TournamentDeleteMutation = {
  __typename: 'mutation_root';
  delete_tournaments_tournament_by_pk?:
    | { __typename: 'tournaments_tournament'; id: number }
    | undefined;
};

export type TournamentChallengeDeleteMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['Int']['input'];
}>;

export type TournamentChallengeDeleteMutation = {
  __typename: 'mutation_root';
  delete_tournaments_challenge_by_pk?:
    | { __typename: 'tournaments_challenge'; id: number }
    | undefined;
};

export type PublishTournamentMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  published: Types.Scalars['Boolean']['input'];
}>;

export type PublishTournamentMutation = {
  __typename: 'mutation_root';
  update_tournaments_tournament_by_pk?:
    | {
        __typename: 'tournaments_tournament';
        id: number;
        published?: boolean | undefined;
      }
    | undefined;
};

export type AddTournamentReporterMutationVariables = Types.Exact<{
  member_id: Types.Scalars['Int']['input'];
  tournament_id: Types.Scalars['Int']['input'];
}>;

export type AddTournamentReporterMutation = {
  __typename: 'mutation_root';
  insert_tournaments_official_reporter_v2_one?:
    | { __typename: 'tournaments_official_reporter_v2'; id: number }
    | undefined;
};

export type DeleteTournamentReporterMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;

export type DeleteTournamentReporterMutation = {
  __typename: 'mutation_root';
  delete_tournaments_official_reporter_v2_by_pk?:
    | { __typename: 'tournaments_official_reporter_v2'; id: number }
    | undefined;
};

export type UpdateTournamentEngagementManualRankMutationVariables =
  Types.Exact<{
    engagementId: Types.Scalars['Int']['input'];
    manualRank: Types.Scalars['smallint']['input'];
  }>;

export type UpdateTournamentEngagementManualRankMutation = {
  __typename: 'mutation_root';
  update_tournaments_engagement_by_pk?:
    | { __typename: 'tournaments_engagement'; id: number }
    | undefined;
};

export type AddChallengePoolMutationVariables = Types.Exact<{
  ruleId: Types.Scalars['Int']['input'];
  newRank: Types.Scalars['Int']['input'];
  nbTeams: Types.Scalars['Int']['input'];
  double: Types.Scalars['Boolean']['input'];
}>;

export type AddChallengePoolMutation = {
  __typename: 'mutation_root';
  tournaments_create_pool?:
    | { __typename: 'new_pool_or_cup_response'; id: number }
    | undefined;
};

export type AddChallengeCupMutationVariables = Types.Exact<{
  ruleId: Types.Scalars['Int']['input'];
  newRank: Types.Scalars['Int']['input'];
  nbTeams: Types.Scalars['Int']['input'];
  double: Types.Scalars['Boolean']['input'];
  rankingGames: Types.Scalars['Boolean']['input'];
}>;

export type AddChallengeCupMutation = {
  __typename: 'mutation_root';
  tournaments_create_cup?:
    | { __typename: 'new_pool_or_cup_response'; id: number }
    | undefined;
};

export type DeleteCupOrPoolMutationVariables = Types.Exact<{
  phaseId: Types.Scalars['Int']['input'];
}>;

export type DeleteCupOrPoolMutation = {
  __typename: 'mutation_root';
  tournaments_delete_cup_or_pool?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type UpdateEngagementRulesMutationVariables = Types.Exact<{
  engagementId: Types.Scalars['Int']['input'];
  _set: Types.Tournaments_Engagement_Set_Input;
}>;

export type UpdateEngagementRulesMutation = {
  __typename: 'mutation_root';
  update_tournaments_engagement_by_pk?:
    | { __typename: 'tournaments_engagement'; id: number }
    | undefined;
};

export type PhaseCopyTimesMutationVariables = Types.Exact<{
  fromPhaseId: Types.Scalars['Int']['input'];
  toPhasesIds:
    | Array<Types.Scalars['Int']['input']>
    | Types.Scalars['Int']['input'];
}>;

export type PhaseCopyTimesMutation = {
  __typename: 'mutation_root';
  tournaments_phase_copy_times?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type TournamentNewHitMutationVariables = Types.Exact<{
  source: Types.Scalars['String']['input'];
  tournament_id: Types.Scalars['Int']['input'];
  challenge_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type TournamentNewHitMutation = {
  __typename: 'mutation_root';
  tournaments_new_hit?: any | undefined;
};

export type InsertTournamentPlaceMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  tournament_id: Types.Scalars['Int']['input'];
}>;

export type InsertTournamentPlaceMutation = {
  __typename: 'mutation_root';
  insert_tournaments_place_one?:
    | { __typename: 'tournaments_place'; id: number }
    | undefined;
};

export type UpdateTournamentPlaceMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
}>;

export type UpdateTournamentPlaceMutation = {
  __typename: 'mutation_root';
  update_tournaments_place_by_pk?:
    | { __typename: 'tournaments_place'; id: number }
    | undefined;
};

export type DeleteTournamentPlaceMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;

export type DeleteTournamentPlaceMutation = {
  __typename: 'mutation_root';
  delete_tournaments_place_by_pk?:
    | { __typename: 'tournaments_place'; id: number }
    | undefined;
};

export type UpdateChallengeMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  _set: Types.Tournaments_Challenge_Set_Input;
}>;

export type UpdateChallengeMutation = {
  __typename: 'mutation_root';
  update_tournaments_challenge_by_pk?:
    | {
        __typename: 'tournaments_challenge';
        id: number;
        name?: string | undefined;
        color?: string | undefined;
      }
    | undefined;
};

export type DuplicateChallengeMutationVariables = Types.Exact<{
  challenge_id: Types.Scalars['Int']['input'];
}>;

export type DuplicateChallengeMutation = {
  __typename: 'mutation_root';
  tournaments_duplicate_challenge?:
    | { __typename: 'id_response'; id: number }
    | undefined;
};

export type DeleteChallengeMutationVariables = Types.Exact<{
  challenge_id: Types.Scalars['Int']['input'];
}>;

export type DeleteChallengeMutation = {
  __typename: 'mutation_root';
  tournaments_delete_challenge?:
    | { __typename: 'affected_rows_response'; affected_rows?: any | undefined }
    | undefined;
};

export type TournamentCreateTestMutationVariables = Types.Exact<{
  club_id: Types.Scalars['Int']['input'];
}>;

export type TournamentCreateTestMutation = {
  __typename: 'mutation_root';
  tournaments_create_test?:
    | { __typename: 'id_response'; id: number }
    | undefined;
};

export const InsertTournamentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertTournament' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'location' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'club_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sport_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tournaments_tournament' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'location' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'club_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'club_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sport_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sport_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'logo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'logo' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertTournamentMutation,
  InsertTournamentMutationVariables
>;
export const UpdateEngagmentTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEngagmentTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'engagmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_tournaments_engagement_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'engagmentId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'team_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'teamId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'team_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEngagmentTeamMutation,
  UpdateEngagmentTeamMutationVariables
>;
export const UpdateGameInfosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGameInfos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'tournaments_game_updates' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_game_many' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tournament_place_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGameInfosMutation,
  UpdateGameInfosMutationVariables
>;
export const UpdateTournamentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTournament' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournamentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'signup_link' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'location' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ad' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_tournaments_tournament_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tournamentId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'logo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'logo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'signup_link' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'signup_link' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'location' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ad' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ad' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
>;
export const InsertChallengeByTournamentIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertChallengeByTournamentID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournamentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gender' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'age_category' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tournaments_challenge_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tournament_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tournamentId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'gender' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gender' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'age_category' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'age_category' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertChallengeByTournamentIdMutation,
  InsertChallengeByTournamentIdMutationVariables
>;
export const InsertTeamByChallengeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertTeamByChallengeID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'challengeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color_1' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color_2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'scorenco_team_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tournaments_team_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'challenge_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'challengeId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color_1' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color_1' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color_2' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color_2' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'logo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'logo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'scorenco_team_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'scorenco_team_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertTeamByChallengeIdMutation,
  InsertTeamByChallengeIdMutationVariables
>;
export const EditTeamByChallengeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditTeamByChallengeID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'challengeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color_1' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color_2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tournaments_team_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'challenge_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'challengeId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color_1' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color_1' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color_2' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color_2' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'logo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'logo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditTeamByChallengeIdMutation,
  EditTeamByChallengeIdMutationVariables
>;
export const EditTeamByTournamentsTeamIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditTeamByTournamentsTeamID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournamentsTeamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'logo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color_2' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'color_1' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_team_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tournamentsTeamId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'logo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'logo' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color_2' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color_2' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'color_1' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'color_1' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditTeamByTournamentsTeamIdMutation,
  EditTeamByTournamentsTeamIdMutationVariables
>;
export const DeleteTeamByTournamentsTeamIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTeamByTournamentsTeamID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournamentsTeamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_tournaments_team_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tournamentsTeamId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTeamByTournamentsTeamIdMutation,
  DeleteTeamByTournamentsTeamIdMutationVariables
>;
export const UpdateChallengeRuleOneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChallengeRuleOne' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ruleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rule' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'tournaments_challenge_rule_set_input',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rankingPoints' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'tournaments_ranking_points_set_input',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rankingRules' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'tournaments_ranking_rule_insert_input',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_tournaments_challenge_rule_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ruleId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rule' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nb_groups' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nb_teams' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nb_teams_in_final_phase' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'period_duration' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rule_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'time_between_periods' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'period_nb' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_ranking_points' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'challenge_rule_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ruleId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rankingPoints' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_tournaments_ranking_rule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'challenge_rule_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ruleId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tournaments_ranking_rule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rankingRules' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChallengeRuleOneMutation,
  UpdateChallengeRuleOneMutationVariables
>;
export const AddChallengePhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddChallengePhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'challengeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phaseRank' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_create_phase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'challenge_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'challengeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phase_rank' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phaseRank' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddChallengePhaseMutation,
  AddChallengePhaseMutationVariables
>;
export const DeleteChallengePhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteChallengePhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ruleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_delete_phase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rule_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ruleId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChallengePhaseMutation,
  DeleteChallengePhaseMutationVariables
>;
export const InvertGameTeamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InvertGameTeams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'gameId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newAwayId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newHomeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_game' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'gameId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'away_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newAwayId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'home_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newHomeId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'place' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_score' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'away_score' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'away_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'home_engagement' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'away_engagement' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InvertGameTeamsMutation,
  InvertGameTeamsMutationVariables
>;
export const UpdateTournamentPhaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTournamentPhase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'tournaments_phase_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_phase_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTournamentPhaseMutation,
  UpdateTournamentPhaseMutationVariables
>;
export const UpdateTournamentGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTournamentGame' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_game_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTournamentGameMutation,
  UpdateTournamentGameMutationVariables
>;
export const TournamentDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TournamentDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournamentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_tournaments_tournament_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tournamentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TournamentDeleteMutation,
  TournamentDeleteMutationVariables
>;
export const TournamentChallengeDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TournamentChallengeDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'challengeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_tournaments_challenge_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'challengeId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TournamentChallengeDeleteMutation,
  TournamentChallengeDeleteMutationVariables
>;
export const PublishTournamentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishTournament' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'published' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_tournaments_tournament_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'published' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'published' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishTournamentMutation,
  PublishTournamentMutationVariables
>;
export const AddTournamentReporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddTournamentReporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'member_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournament_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_tournaments_official_reporter_v2_one',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'member_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'member_id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tournament_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tournament_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTournamentReporterMutation,
  AddTournamentReporterMutationVariables
>;
export const DeleteTournamentReporterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTournamentReporter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'delete_tournaments_official_reporter_v2_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTournamentReporterMutation,
  DeleteTournamentReporterMutationVariables
>;
export const UpdateTournamentEngagementManualRankDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTournamentEngagementManualRank' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'engagementId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manualRank' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'smallint' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_tournaments_engagement_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'engagementId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'manual_rank' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'manualRank' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTournamentEngagementManualRankMutation,
  UpdateTournamentEngagementManualRankMutationVariables
>;
export const AddChallengePoolDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddChallengePool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ruleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newRank' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nbTeams' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'double' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_create_pool' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rule_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ruleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pool_rank' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newRank' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nb_teams' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nbTeams' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'double' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'double' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddChallengePoolMutation,
  AddChallengePoolMutationVariables
>;
export const AddChallengeCupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddChallengeCup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ruleId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newRank' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nbTeams' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'double' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rankingGames' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_create_cup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rule_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ruleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pool_rank' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newRank' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nb_teams' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nbTeams' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'double' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'double' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ranking_games' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rankingGames' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddChallengeCupMutation,
  AddChallengeCupMutationVariables
>;
export const DeleteCupOrPoolDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCupOrPool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phaseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_delete_cup_or_pool' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phase_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phaseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCupOrPoolMutation,
  DeleteCupOrPoolMutationVariables
>;
export const UpdateEngagementRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEngagementRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'engagementId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'tournaments_engagement_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_tournaments_engagement_by_pk',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'engagementId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateEngagementRulesMutation,
  UpdateEngagementRulesMutationVariables
>;
export const PhaseCopyTimesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PhaseCopyTimes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromPhaseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toPhasesIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_phase_copy_times' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from_phase_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromPhaseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to_phases_ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'toPhasesIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhaseCopyTimesMutation,
  PhaseCopyTimesMutationVariables
>;
export const TournamentNewHitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TournamentNewHit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'source' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournament_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'challenge_id' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_new_hit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'source' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tournament_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tournament_id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'challenge_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'challenge_id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TournamentNewHitMutation,
  TournamentNewHitMutationVariables
>;
export const InsertTournamentPlaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InsertTournamentPlace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tournament_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_tournaments_place_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tournament_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tournament_id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsertTournamentPlaceMutation,
  InsertTournamentPlaceMutationVariables
>;
export const UpdateTournamentPlaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTournamentPlace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_place_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTournamentPlaceMutation,
  UpdateTournamentPlaceMutationVariables
>;
export const DeleteTournamentPlaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTournamentPlace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_tournaments_place_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTournamentPlaceMutation,
  DeleteTournamentPlaceMutationVariables
>;
export const UpdateChallengeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateChallenge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: '_set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'tournaments_challenge_set_input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_tournaments_challenge_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: '_set' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateChallengeMutation,
  UpdateChallengeMutationVariables
>;
export const DuplicateChallengeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DuplicateChallenge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'challenge_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_duplicate_challenge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'challenge_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'challenge_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DuplicateChallengeMutation,
  DuplicateChallengeMutationVariables
>;
export const DeleteChallengeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteChallenge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'challenge_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_delete_challenge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'challenge_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'challenge_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'affected_rows' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteChallengeMutation,
  DeleteChallengeMutationVariables
>;
export const TournamentCreateTestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TournamentCreateTest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'club_id' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tournaments_create_test' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'club_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'club_id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TournamentCreateTestMutation,
  TournamentCreateTestMutationVariables
>;
