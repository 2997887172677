import {
  Contacts,
  Dashboard,
  Help,
  Shield,
  Group,
  ConnectedTv,
  EventAvailable,
  EmojiPeople,
  Wysiwyg,
  Tour,
  Search,
  Error,
  InsertInvitation,
  Mouse,
  Engineering,
  Newspaper,
  SendToMobile,
} from '@mui/icons-material';
import { List, Divider } from '@mui/material';
import { useAmplifyServiceContext, useTranslation } from '@scorenco/core';

import { T_KEYS } from '@/translations';

import { useCurrentClubContext } from '../CurrentClubContext';

import { ClubSelector } from './ClubSelector';
import {
  CollapseNavigationItem,
  NavigationItem,
  SubNavigationItem,
} from './NavigationItem';

export const Navigation = () => {
  const { t } = useTranslation();
  const { currentClub } = useCurrentClubContext();
  const { user } = useAmplifyServiceContext();

  return (
    <>
      <ClubSelector />
      <Divider />
      <List component="nav">
        <NavigationItem
          label={t(T_KEYS.DASHBOARD_TITLE)}
          path={`/${currentClub.id}`}
          Icon={Dashboard}
        />

        <CollapseNavigationItem
          label={
            currentClub?.is_organization ? 'Organisation' : t(T_KEYS.CLUB_TITLE)
          }
          Icon={Shield}
          refPath="club"
        >
          <SubNavigationItem
            label={t(T_KEYS.CLUB_NAVIGATION_INFORMATIONS)}
            path={`/${currentClub.id}/club`}
          />

          <SubNavigationItem
            label={t(T_KEYS.CLUB_NAVIGATION_EQUIPMENTS)}
            path={`/${currentClub.id}/club/equipments`}
          />

          <SubNavigationItem
            label="Facturation"
            path={`/${currentClub.id}/club/billing`}
          />
        </CollapseNavigationItem>

        {!currentClub?.is_organization && (
          <NavigationItem
            label={t(T_KEYS.TEAMS_TITLE)}
            path={`/${currentClub.id}/teams`}
            Icon={Group}
          />
        )}

        {!currentClub?.is_organization && (
          <NavigationItem
            label={t(T_KEYS.MATCHS_TITLE)}
            path={`/${currentClub.id}/matchs`}
            Icon={EventAvailable}
          />
        )}

        {!currentClub?.is_organization &&
          (currentClub.customer_planner_start ? (
            <CollapseNavigationItem
              label={t(T_KEYS.PLANNER_TITLE)}
              Icon={InsertInvitation}
            >
              <SubNavigationItem
                label={t(T_KEYS.PLANNER_SUMMARY)}
                path={`/${currentClub.id}/convocations`}
              />
              <SubNavigationItem
                label={t(T_KEYS.PLANNER_LAUNCH)}
                path={`/${currentClub.id}/matchs/planner`}
              />
              <SubNavigationItem
                label={t(T_KEYS.CLUB_NAVIGATION_TASKS)}
                path={`/${currentClub.id}/convocations/tasks`}
              />
              <SubNavigationItem
                label={t(T_KEYS.CLUB_NAVIGATION_INFORMATIONS)}
                path={`/${currentClub.id}/convocations/informations`}
              />
            </CollapseNavigationItem>
          ) : (
            <NavigationItem
              label={t(T_KEYS.PLANNER_TITLE)}
              path={`/${currentClub.id}/convocations`}
              Icon={InsertInvitation}
            />
          ))}

        <CollapseNavigationItem
          label={t(T_KEYS.COMMUNITY_TITLE)}
          Icon={Contacts}
          refPath="community"
        >
          <SubNavigationItem
            label={t(T_KEYS.CLUB_NAVIGATION_MEMBERS)}
            path={`/${currentClub.id}/community`}
          />

          <SubNavigationItem
            label={t(T_KEYS.CLUB_NAVIGATION_ADMINISTRATORS)}
            path={`/${currentClub.id}/community/administrators`}
          />

          <SubNavigationItem
            label={t(T_KEYS.CLUB_NAVIGATION_REPORTERS)}
            path={`/${currentClub.id}/community/reporters`}
          />

          <SubNavigationItem
            label={t(T_KEYS.CLUB_NAVIGATION_GROUPS)}
            path={`/${currentClub.id}/community/groups`}
          />

          <SubNavigationItem
            label={t(T_KEYS.COMMUNITY_EXPORT_SEE)}
            path={`/${currentClub.id}/community/exports`}
          />
        </CollapseNavigationItem>

        {!currentClub?.is_organization && (
          <NavigationItem
            label={t(T_KEYS.NEWS_TITLE)}
            path={`/${currentClub.id}/news`}
            Icon={Newspaper}
          />
        )}

        <CollapseNavigationItem
          label="Widgets"
          Icon={Wysiwyg}
          refPath="widgets"
        >
          <SubNavigationItem
            label={t(T_KEYS.WIDGETS_YOURS)}
            path={`/${currentClub.id}/widgets`}
          />
          <SubNavigationItem
            label={t(T_KEYS.WIDGETS_APPEARANCE)}
            path={`/${currentClub.id}/widgets/appearance`}
          />
        </CollapseNavigationItem>

        {/*
        <NavigationItem
          label="Communication"
          path={`/${currentClub.id}/communication`}
          Icon={Chat}
        />
        */}

        <NavigationItem
          label="Clubhouse"
          path={`/${currentClub.id}/club-house`}
          Icon={ConnectedTv}
        />

        {currentClub.customer_premium_start ||
        currentClub.customer_clubhouse_start ||
        currentClub.customer_tournament_start ? (
          <CollapseNavigationItem
            label={t(T_KEYS.SPONSORS_TITLE)}
            refPath="sponsors"
            Icon={EmojiPeople}
          >
            <SubNavigationItem
              label={t(T_KEYS.SPONSORS_LIST)}
              path={`/${currentClub.id}/sponsors`}
            />
            <SubNavigationItem
              label={t(T_KEYS.SPONSORS_CATEGORIES)}
              path={`/${currentClub.id}/sponsors/categories`}
            />
          </CollapseNavigationItem>
        ) : (
          <NavigationItem
            label={t(T_KEYS.SPONSORS_TITLE)}
            path={`/${currentClub.id}/sponsors`}
            Icon={EmojiPeople}
          />
        )}

        <NavigationItem
          label={t(T_KEYS.TOURNAMENTS_TITLE)}
          path={`/${currentClub.id}/tournaments`}
          Icon={Tour}
        />
        <NavigationItem
          label={t(T_KEYS.MONSITECLUB_TITLE)}
          path={`/${currentClub.id}/monsiteclub`}
          Icon={Mouse}
        />

        <NavigationItem
          label={t(T_KEYS.SEARCH_TITLE)}
          path={`/${currentClub.id}/search`}
          Icon={Search}
        />

        {!currentClub?.is_organization && (
          <NavigationItem
            label={t(T_KEYS.FEDERATION_LINK_PAGE_TITLE)}
            path={`/${currentClub.id}/lien-federation`}
            Icon={Error}
          />
        )}

        <NavigationItem
          label={t(T_KEYS.HELPCENTER_TITLE)}
          path={t(T_KEYS.HELPCENTER_LINK)}
          Icon={Help}
          target="_blank"
        />

        <NavigationItem
          label={currentClub.short_name + " sur l'app"}
          path={`${process.env.NEXT_PUBLIC_MOBILE_URL}/clubs/${currentClub.id}`}
          Icon={SendToMobile}
          target="_blank"
        />

        {user?.info?.is_superuser && (
          <>
            <NavigationItem
              label={t(T_KEYS.ADMIN_DJANGO)}
              path={`https://back.scorenco.com/clubs/admin/clubs/club/?id=${currentClub.id}`}
              Icon={Engineering}
              target="_blank"
            />
          </>
        )}
      </List>
    </>
  );
};
