import {
  createContext,
  useMemo,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';

import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { Types, useTranslation, useValidateEventForTeam } from '@scorenco/core';
import { addMinutes } from 'date-fns';
import { useFormik, FormikContextType } from 'formik';
import { useSnackbar } from 'notistack';

import { T_KEYS } from '@/translations';

import { useCurrentClubContext } from '../../../../../../layouts';

import {
  GetEventPlayingTeamInfosDocument,
  GetTeamRulesPlannerDocument,
} from './MatchRowForm.query.generated';
import { usePlacesCalculateRouteTime } from './usePlacesCalculateRouteTime';
import { validateEvent } from './validateEvent';

type MatchRowFormContextProps = {
  event: Types.Competitions_Event_Detail;
  isHome: boolean;
  formik: FormikContextType<ReturnType<typeof useFormikInitialValues>>;
  teamInfo: Types.Competitions_Playing_Team_Info;
  isLoading: boolean;
  calculateRouteTime: () => void;
  calculateRouteTimeData: any;
};

const MatchRowFormContext = createContext<MatchRowFormContextProps | undefined>(
  undefined
);

export const useMatchRowFormContext = (): MatchRowFormContextProps => {
  const context = useContext(MatchRowFormContext);
  if (!context) {
    throw new Error(
      'useMatchRowFormContext must be inside an MatchRowFormProvider'
    );
  }
  return context;
};

type MatchRowFormProviderProps = PropsWithChildren<{
  event?: Types.Competitions_Event_Detail;
  tasks?: Types.Tasks_Task[];
}>;

const calculateDefaultMeetingTime = (
  event?: Types.Competitions_Event_Detail,
  teamInfo?: Types.Competitions_Playing_Team_Info,
  teamRules?: Types.Teams_Team
): Date | Record<string, never> => {
  if (teamInfo?.meeting_time) {
    return new Date(teamInfo.meeting_time);
  }

  if (!event?.time) {
    return {};
  }

  const minutesBefore =
    teamRules?.meeting_before_event ??
    teamInfo?.playing_team?.team_in_season?.team?.meeting_before_event ??
    30;

  return addMinutes(new Date(event.time), -1 * minutesBefore);
};

const PERSISTENT_SNACK_BAR_KEY = 'unsaved-snackbar';

const useFormikInitialValues = ({
  event,
  teamInfo,
  playingTeamId,
  teamRules,
  tasks,
  teamId,
}: {
  event?: Types.Competitions_Event_Detail;
  teamInfo?: Types.Competitions_Playing_Team_Info;
  playingTeamId?: number;
  teamRules?: Types.Teams_Team;
  tasks?: Types.Tasks_Task[];
  teamId?: number;
}) => {
  const initialValues = useMemo(() => {
    return {
      team_id: teamId || null,
      equipment:
        teamInfo?.equipment?.id ??
        teamRules?.default_equipment_id ??
        teamInfo?.playing_team?.team_in_season?.team?.default_equipment_id,
      playingTeamId,
      eventDate: teamInfo?.date ? new Date(teamInfo.date) : null,
      eventTime: teamInfo?.time ? new Date(teamInfo.time) : null,
      meetingTime: calculateDefaultMeetingTime(event, teamInfo, teamRules),
      status: teamInfo?.status ? 'ok' : 'waiting',
      players:
        (teamInfo
          ? teamInfo.playing_team.team_in_season.members
          : teamRules?.seasons?.[0]?.members) || [],
      jobs:
        tasks?.map((task) => {
          return {
            task_id: task?.id,
            members:
              teamInfo?.jobs
                ?.find((t) => t?.task?.id === task?.id)
                ?.volunteers?.map((v) => v.member) || [],
          };
        }) || [],
      jobsComplete:
        tasks?.map((task) => {
          return {
            task_id: task?.id,
            volunteers: teamInfo?.jobs.find((t) => t.task?.id === task?.id)
              ?.volunteers,
          };
        }) || [],
      jobsOfficial: teamInfo
        ? teamInfo?.playing_team.team_in_season.team?.team_tasks
        : teamRules?.team_tasks,
      privateComment: teamInfo?.private_comment || '',
      publicComment: teamInfo?.public_comment || '',
      travelDuration: teamInfo?.travel_duration || 0,
    };
  }, [event, teamInfo, playingTeamId, teamRules]);

  return initialValues;
};

export const MatchRowFormProvider = ({
  event,
  children,
  tasks,
}: MatchRowFormProviderProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const validateEventForTeamMutation = useValidateEventForTeam();
  const { currentClub } = useCurrentClubContext();

  const isHome = event?.teams?.[0]?.is_team_of_club === 1;
  const playingTeamId = event?.teams?.[isHome ? 0 : 1]?.id;
  const teamInfoId = event?.playing_teams?.[0]?.info?.id;
  const teamId = event?.teams?.[isHome ? 0 : 1]?.team_id;

  const [calculateRouteTime, calculateRouteTimeData] =
    usePlacesCalculateRouteTime({
      place_id_from: currentClub?.equipments?.[0]?.place_id ?? 0,
      place_id_to: event?.place?.id ?? 0,
    });

  const handleCalculateRouteTime = async () => {
    const result = await calculateRouteTime();
    const routeDurationMinutes =
      result?.data?.places_calculate_route_time?.duration_minutes;

    if (
      !routeDurationMinutes ||
      (formik.values?.travelDuration && formik.values?.travelDuration !== 0)
    ) {
      return;
    }

    const minutesBefore =
      teamRules?.meeting_before_event ??
      teamInfo?.playing_team?.team_in_season?.team?.meeting_before_event ??
      30;

    const defaultMeetingTime = addMinutes(
      new Date(event.time),
      -1 * minutesBefore
    );

    if (defaultMeetingTime instanceof Date) {
      const roundedDuration = Math.ceil(routeDurationMinutes / 5) * 5;
      const newMeetingTime = new Date(
        defaultMeetingTime.getTime() - roundedDuration * 60 * 1000
      );

      formik.setFieldValue('meetingTime', newMeetingTime);
      formik.setFieldValue('travelDuration', roundedDuration);
      formik.setStatus('unsaved');

      const formattedNewMeetingTime = newMeetingTime.toLocaleTimeString(
        'fr-FR',
        { hour: '2-digit', minute: '2-digit' }
      );

      enqueueSnackbar(
        `Temps de convocation : ${
          teamRules?.meeting_before_event ??
          teamInfo?.playing_team?.team_in_season?.team?.meeting_before_event ??
          30
        } minutes,
         Temps de trajet : ${Math.floor(roundedDuration / 60) > 0 ? `${Math.floor(roundedDuration / 60)}h` : ''}${roundedDuration % 60 > 0 ? `${roundedDuration % 60}min` : ''},
         Nouvelle heure de rendez-vous : ${formattedNewMeetingTime}`,
        {
          variant: 'info',
          autoHideDuration: 6000,
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        }
      );
    }
  };

  const eventInfoQuery = useQuery(GetEventPlayingTeamInfosDocument, {
    variables: {
      teamInfoId: teamInfoId ?? 0,
      isHome,
    },
    fetchPolicy: 'no-cache',
    skip: !teamInfoId,
    context: {
      role: 'club',
    },
  });

  const teamInfo = eventInfoQuery.data?.competitions_playing_team_info_by_pk;

  const teamRulesQuery = useQuery(GetTeamRulesPlannerDocument, {
    variables: {
      teamId: teamId ?? 0,
    },
    skip: !teamId || !!teamInfoId,
    context: {
      role: 'club',
    },
  });

  const teamRules = teamRulesQuery.data?.teams_team_by_pk;

  const initialValues = useFormikInitialValues({
    event,
    teamInfo,
    playingTeamId,
    teamRules,
    tasks,
    teamId,
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      closeSnackbar(PERSISTENT_SNACK_BAR_KEY + event?.id);

      const allMembers: number[] = [];
      values.jobs.forEach((job) => {
        if (job.members && job.members.length > 0) {
          job.members.forEach((member) => {
            if (member?.id) {
              allMembers.push(member.id);
            }
          });
        }
      });

      if (new Set(allMembers).size !== allMembers.length) {
        enqueueSnackbar(
          'Membre convoqué plusieurs fois sur un même match, voulez-vous vraiment confirmer ? (' +
            event?.teams?.[isHome ? 0 : 1]?.name_in_club +
            ')',
          {
            variant: 'warning',
            persist: true,
            key: 'doublon_snackbar' + event?.id,
            action: (key) => (
              <>
                <Button
                  onClick={async () => {
                    try {
                      await validateEvent(
                        values,
                        event,
                        formik,
                        validateEventForTeamMutation
                      );
                      formik.setStatus(undefined);
                      enqueueSnackbar(t(T_KEYS.SUCCESS_EDIT), {
                        variant: 'success',
                      });
                      closeSnackbar(key);
                    } catch (error) {
                      console.error('error: ', error);
                      enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
                        variant: 'error',
                      });
                    }
                  }}
                  disabled={validateEventForTeamMutation.status.loading}
                >
                  Confirmer
                </Button>
                <Button
                  color="error"
                  onClick={async () => {
                    closeSnackbar(key);
                  }}
                  disabled={validateEventForTeamMutation.status.loading}
                >
                  Annuler
                </Button>
              </>
            ),
          }
        );
      } else {
        try {
          await validateEvent(
            values,
            event,
            formik,
            validateEventForTeamMutation
          );
          formik.setStatus(undefined);
          enqueueSnackbar(t(T_KEYS.SUCCESS_EDIT), {
            variant: 'success',
          });
        } catch (error) {
          console.error('error: ', error);
          enqueueSnackbar(t(T_KEYS.ERROR_MESSAGE), {
            variant: 'error',
          });
        }
      }
    },
  });

  useEffect(() => {
    if (formik.status === 'unsaved') {
      enqueueSnackbar(
        t(T_KEYS.EDIT_NOT_FINISHED) +
          ' (' +
          event?.teams?.[isHome ? 0 : 1]?.name_in_club +
          ')',
        {
          key: PERSISTENT_SNACK_BAR_KEY + event?.id,
          variant: 'warning',
          persist: true,
          preventDuplicate: false,
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          action: (key) => (
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Masquer
            </Button>
          ),
        }
      );
    }
  }, [formik.status]);

  return (
    <MatchRowFormContext.Provider
      value={{
        formik,
        isHome,
        event: event as Types.Competitions_Event_Detail,
        teamInfo: teamInfo as Types.Competitions_Playing_Team_Info,
        isLoading:
          !event ||
          eventInfoQuery.loading ||
          validateEventForTeamMutation.status.loading,
        calculateRouteTime: handleCalculateRouteTime,
        calculateRouteTimeData,
      }}
    >
      {children}
    </MatchRowFormContext.Provider>
  );
};
