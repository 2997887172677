import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  GroupDeleteDocument,
  GroupDeleteMutationVariables,
} from '../../Community.mutation.generated';

export const useGroupDelete = () => {
  const [runMutation, status] = useMutation(GroupDeleteDocument, {
    context: { role: 'club' },
  });

  const execute = useCallback(
    (variables: GroupDeleteMutationVariables) => {
      return runMutation({
        variables,
        refetchQueries: ['GetGroups'],
      });
    },
    [runMutation]
  );

  return { execute, status };
};
